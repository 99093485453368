import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import StepEdit from './StepEdit'
import { Droppable } from "react-beautiful-dnd";

const ArrayAccordionRender = ({ arrayData,
    localRecipeJson = [],
    getItemId = item => item.id,
    getItemTitle = item => item?.title,
    onChange = () => { },
    onAddArrayElement = () => { },// This is a callback function after an element is added
    defaultAddJson//THis is the JSON that will become the default when adding a new element, if this is not defined then don't put add button
}) => {
    const onDeleteElement = index => {
        onChange(arrayData.filter((element, i) => i !== index))
    }
    const onMoveUp = (e, index) => {
        e.stopPropagation()
        let ret = Array.from(arrayData)
        const temp = ret[index]
        ret[index] = ret[index - 1]
        ret[index - 1] = temp
        onChange(ret)
    }
    const onMoveDown = (e, index) => {
        e.stopPropagation()
        let ret = Array.from(arrayData)
        const temp = ret[index]
        ret[index] = ret[index + 1]
        ret[index + 1] = temp
        onChange(ret)
    }
    const onArrayElementChange = (change, index) => {
        onChange(arrayData.map((element, i) => i === index ? change : element))
    }

    if (!arrayData) return null
    return (
        <Grid container direction="column"
        >
            {arrayData.map((arrayItem, index) =>
                <Droppable droppableId={arrayItem.id} index={index} key={arrayItem.id}>
                    {provided => (
                        <Grid item key={getItemId(arrayItem)}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            <Accordion style={{ width: "100%" }}>
                                <AccordionSummary sx={{ backgroundColor: "lightblue" }}>
                                    <Button variant="contained" color="secondary" onClick={() => onDeleteElement(index)}><DeleteForeverIcon /></Button>
                                    <Button disabled={index === 0} variant="contained" color="primary" onClick={e => onMoveUp(e, index)}><ArrowUpwardIcon /></Button>
                                    <Button disabled={index === arrayData.length - 1} variant="contained" color="primary" onClick={e => onMoveDown(e, index)}><ArrowDownwardIcon /></Button>
                                    <Button variant="outlined" color="secondary" onClick={(e) => onAddArrayElement(e, defaultAddJson, index)}>ADD STEP</Button>
                                    <Typography align="justify" variant="h6" display="flex" style={{ margin: "0 10px" }}>{`{${index + 1}}`}</Typography>
                                    <Typography variant="h6" style={{ marginLeft: "10px" }}>
                                        {getItemTitle(arrayItem)}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ backgroundColor: "lightskyblue" }}>
                                    <StepEdit
                                        subStepData={arrayItem}
                                        localRecipeJson={localRecipeJson}
                                        onStepPropertyChange={newValue => onArrayElementChange(newValue, index)}
                                    />
                                </AccordionDetails>
                            </Accordion>
                            {provided.placeholder}
                        </Grid>
                    )}
                </Droppable>
            )}
        </Grid>
    )
}

ArrayAccordionRender.propTypes = {
    arrayData: PropTypes.array.isRequired,
    getItemId: PropTypes.func,
    onChange: PropTypes.func,
    onAddArrayElement: PropTypes.func,
    arrayItemComponent: PropTypes.func
}

export default ArrayAccordionRender