import React, { useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Accordion, AccordionSummary, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

const ScaleModel = ({scaleModel={}, setScaleModel}) => {
  const [ expanded, setExpanded ] = useState(false)
  const { type, parameters } = scaleModel 
  const settings = parameters?.settings || [{time: 10, temperature: "10", speed: 10, servingSize: 1}]
  const setScaleModelType = (newType) =>  setScaleModel({...scaleModel, type: newType})
  const setTimeSettings = (newTime, index) => {
    const newTimeSettings = settings[index]?.hasOwnProperty("time") ? 
      settings.map((val, i) => i === index ? { ...settings[index], time: newTime, servingSize: index+1} : val)
      : [...settings, {time: newTime, temperature: '60', speed: 1, servingSize: index+1}]
    setScaleModel({...scaleModel, parameters: {
      settings: newTimeSettings}})
  }
  const setTemperatureSettings = (newTemperature, index) => {
    const newTemperatureSettings = settings[index]?.hasOwnProperty("temperature") ? 
      settings.map((val, i) => i === index ? { ...settings[index], temperature: newTemperature, servingSize: index+1} : val)
      : [...settings, {time: 20, temperature: newTemperature, speed: 1, servingSize: index+1}]
    setScaleModel({...scaleModel, parameters: {
      settings:  newTemperatureSettings
    }})
  }
  const setSpeedSettings = (newSpeed, index) => {
    const newSpeedSettings = settings[index]?.hasOwnProperty("speed") ? 
      settings.map((val, i) => i === index ? { ...settings[index], speed: newSpeed, servingSize: index+1} : val)
      : [...settings, {time: 20, temperature: '60', speed: newSpeed, servingSize: index+1}]
    setScaleModel({...scaleModel, parameters: {
      settings: newSpeedSettings
    }})
  }

  const setScaleFactor = (newScaleFactor) => {
    setScaleModel({...scaleModel, type: type, parameters: {
      scaleFactor: newScaleFactor
    }})
  }



  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} sx={{backgroundColor: "lightcyan"}}>
      <AccordionSummary>
        <Typography variable="h3">SCALE MODEL</Typography> 
      </AccordionSummary>
      <FormControl fullWidth>
        <InputLabel >Scale Model Type</InputLabel>
        <Select
            value={type}
            label="Scale Model Type"
            onChange={(e) => setScaleModelType(e.target.value)}
        >
        <MenuItem value={"linear"}>Linear</MenuItem>
        <MenuItem value={"hardcode"}>HardCode</MenuItem>
        </Select>
      </FormControl>
      <TableContainer >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Serving Size</TableCell>
              <TableCell>ScaleFactor</TableCell>
              <TableCell>1</TableCell>
              <TableCell>2</TableCell>
              <TableCell>3</TableCell>
              <TableCell>4</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow> 
              <TableCell>
                <Typography>Time</Typography>  
              </TableCell>
              <TableCell>
                <TextField 
                  type="number"
                  disabled={type!=="linear"}
                  value={parameters?.scaleFactor} 
                  onChange={(e) => setScaleFactor(e.target.valueAsNumber)}
                />  
              </TableCell>                                          
              {[...Array(4).keys()].map((i, index) => (
                <TableCell key={index}>
                  <TextField
                    type="number"
                    disabled={type!=="hardcode"}
                    value={parameters?.settings && parameters?.settings[index]?.time} 
                    onChange={(e) => setTimeSettings(e.target.valueAsNumber, index)}
                  />
                </TableCell>
              ))}                                          
            </TableRow>
            <TableRow> 
              <TableCell>
                <Typography>Temperature</Typography>  
              </TableCell> 
              <TableCell>
              <TextField 
                type="number"
                disabled={type!=="linear"}
                value={parameters?.scaleFactor} 
                onChange={(e) => setScaleFactor(e.target.valueAsNumber)}
              /> 
              </TableCell>                                         
              {[...Array(4).keys()].map((i, index) => (
                <TableCell key={index}>
                  <TextField
                    disabled={type!=="hardcode"}
                    value={parameters?.settings && parameters?.settings[index]?.temperature} 
                    onChange={(e) => setTemperatureSettings(e.target.value, index)}
                  />
                </TableCell>
              ))}                                          
            </TableRow>
            <TableRow> 
              <TableCell>
                <Typography>Speed</Typography>  
              </TableCell>
              <TableCell>
                <TextField 
                  type="number"
                  disabled={type!=="linear"}
                  value={parameters?.scaleFactor} 
                  onChange={(e) => setScaleFactor(e.target.valueAsNumber)}
                />  
              </TableCell>                                          
              {[...Array(4).keys()].map((i, index) => (
                <TableCell key={index}>
                  <TextField
                    type="number"
                    disabled={type!=="hardcode"}
                    value={parameters?.settings && parameters?.settings[index]?.speed} 
                    onChange={(e) => setSpeedSettings(e.target.valueAsNumber, index)}
                  />
                </TableCell>
              ))}                                          
            </TableRow>
            
          </TableBody>
        </Table>
      </TableContainer>
    </Accordion>
  )
}

export default ScaleModel