import React from "react"
import { Box, Rating } from "@mui/material"
import PropTypes from "prop-types"
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const HeartRating = ({ maxValue, onSelectRating, subStepId }) => {
    return (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <Rating
                key={subStepId}
                name={subStepId}
                defaultValue={0}
                max={maxValue}
                icon={<FavoriteIcon sx={{ fontSize: 100, maxWidth: "100%", color: "#ff3d47" }} />}
                emptyIcon={<FavoriteBorderIcon sx={{ fontSize: 100, maxWidth: "100%" }} />}
                onChange={onSelectRating}
            />
        </Box>
    )
}

HeartRating.propTypes = {
    maxValue: PropTypes.number,
    onSelectRating: PropTypes.func,
    subStepId: PropTypes.string
}

export default HeartRating