import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import { StateOfHardwareContext, TimeContext } from "../hardwareBar/HardwareTasks";
import PropTypes from "prop-types";

const getTimeString = time => {
  const timeSec = time % 60
  const timeSecString = timeSec < 10 ? "0" + timeSec.toString() : timeSec.toString()
  const timer = time < 600 ? `0${Math.floor(time / 60)}:${timeSecString}` : `${Math.floor(time / 60)}:${timeSecString}`
  return timer
}

const RecipeTimer = ({ fontSize = 30, hardwarebar = false }) => {
  const stateOfHardware = useContext(StateOfHardwareContext)
  const time = useContext(TimeContext)

  const status = stateOfHardware
  return (
    <Box >
      {hardwarebar ?
        <Box style={{border: "1px solid #000", borderRadius: "5px", margin: "10px",  backgroundColor: status === "operation" ? "#00704A" : "transparent"}}>
          <Typography style={{ textAlign: "center", fontSize, color: status === "operation" ? "#FFF" : "#000" }}>{status === "operation" ? getTimeString(time) : getTimeString(0)}</Typography>
        </Box>
        :
        <Typography style={{ fontSize, color: "#00704A", textAlign: "right" }}>{status === "operation" ? getTimeString(time) : getTimeString(0)}</Typography>
      }
    </Box>
  )
}

RecipeTimer.propTypes = {
  size: PropTypes.number,
  active: PropTypes.bool,
  initialTime: PropTypes.number
}

export default RecipeTimer