import React, { useContext } from "react"
import { Box, LinearProgress } from "@mui/material"
import PropTypes from "prop-types"
import { TimeContext } from "../hardwareBar/HardwareTasks";

const TimerProgressBar = ({ totalTime }) => {
    const time = useContext(TimeContext)
    const timerProgress = Math.sign(time) && 100 - Math.abs((time / totalTime) * 100)

    return (
        <Box style={{ width: "100%" }}>
            <LinearProgress sx={{ height: "20px", borderRadius: "10px", backgroundColor: "#D3D3D3" }} color="success" variant="determinate" value={timerProgress} />
        </Box>
    )
}

TimerProgressBar.propTypes = {
    totalTime: PropTypes.number
}

export default TimerProgressBar