import { Button, Typography, SvgIcon } from '@mui/material'
import React, { useContext, useState, useEffect } from 'react'
import { errorUncontrolledIssueUrl } from '../../audioUrls/audioUrl'
import { ReactComponent as Pulse } from "../../svg/pulse.svg"
import useCookClubAudio from '../../utils/audio/cookClubAudio'
import { HardwareContext, StateOfHardwareContext } from '../hardwareBar/HardwareTasks'
import PopUpModal from '../PopUpModal'
import R1BtnPopUp from '../recipeSteps/settingsComponents/R1BtnPopUp'
import ImageUtilityBox from '../recipeSteps/subSteps/ImageUtilityBox'

const buttonStylesConnected = { color: "#043F98", border: "3px solid #043F98", borderRadius: "10px", padding: "0 15px" }
const buttonStylesNotConnected = { color: "#D3D3D3", border: "3px solid #D3D3D3", borderRadius: "10px", padding: "0 15px" }

export const R1Button = ({ settings, changeDialogBoxState, setStateOfOperation, instructionCount }) => {
  const { connected, triggerR1, pauseR1, resumeR1, forceStop } = useContext(HardwareContext)
  const stateOfHardware = useContext(StateOfHardwareContext)
  const [openDialogBox, setOpenDialogBox] = useState(false)
  const [showGifDialog, setShowGifDialog] = useState(false)
  const [pauseSound] = useCookClubAudio(errorUncontrolledIssueUrl)

  useEffect(() => {
    if (stateOfHardware === "operation") {
      setStateOfOperation("operation")
    }
    if (stateOfHardware === "idle") {
      setStateOfOperation("idle")
    }
    if (stateOfHardware === "Paused") {
      setStateOfOperation("Paused")
      pauseSound()
    }
  }, [stateOfHardware])

  const setSettings = () => {
    setShowGifDialog(true)
    setTimeout(() => {
      setShowGifDialog(false)
      triggerR1({ data: settings, type: "serial" })
      changeDialogBoxState()
    }, 3500);
  }

  const handlePause = () => {
    pauseR1()
    setOpenDialogBox(true)
  }

  function onClickResume() {
    resumeR1()
    setOpenDialogBox(false)
  }

  function onClickStop() {
    forceStop()
    pauseSound()
    setOpenDialogBox(false)
  }

  return (
    <>
      {["operation", "cooldown", "starting", "Paused"].includes(stateOfHardware) ? <Button
        className="r1-btn pause-btn"
        onClick={() => handlePause()}
      >
        <Typography variant="h5">Pause</Typography>
      </Button> : <Button
        className={!connected || ["operation", "cooldown"].includes(stateOfHardware) ? "start-btn r1-btn disabled-r1" : "start-btn r1-btn"}
        disabled={!connected || ["operation", "cooldown"].includes(stateOfHardware)}
        onClick={setSettings}
      >
        <Typography variant="h5">Start R1 {instructionCount > 1 ? `(${instructionCount})` : ""}</Typography>
      </Button>}
      <PopUpModal open={showGifDialog} onClose={() => setShowGifDialog(false)} >
        <ImageUtilityBox src={'https://res.cloudinary.com/swateaaa/image/upload/v1657538008/ezgif.com-gif-maker_4_xlxqhr.gif'} />
      </PopUpModal>
      <R1BtnPopUp
        open={openDialogBox}
        title={"Paused"}
        btnTitle="Stop"
        btnStyle="stop-btn"
        description={<>
          <Typography variant="body2">
            The R1 has been paused.
          </Typography>
          <Typography variant="body2">
            Tap on Resume to continue cooking.
          </Typography></>}
        btn={<Button
          className={!connected ? "r1-btn dialog-btn-dimension disable-r1" : "r1-btn dialog-btn-dimension"}
          variant="contained"
          onClick={onClickResume}
          style={{ boxShadow: "none" }}
          disabled={!connected}
        >
          <Typography variant="button">Resume</Typography>
        </Button>}
        onClickStop={() => onClickStop()}
        closeOnClickOutside={() => setOpenDialogBox(true)}
        disableBtn={!connected}
      />
    </>
  )
}

export const PulseButton = ({ settings }) => {
  const { triggerR1 } = useContext(HardwareContext)
  const settings1 = [{ time: 10, temperature: "90", speed: 12 }]

  const handlePulseSettings = () => {
    triggerR1({ data: settings1, type: "serial" })
  }

  return (
    <Button
      className="r1-btn"
      sx={buttonStylesNotConnected}
      onClick={handlePulseSettings}
      disabled={true}
    >
      <SvgIcon component={Pulse} viewBox="0 0 31 20" style={{ fontSize: 40 }} />
      <Typography variant="h5">Pulse</Typography>
    </Button>
  )
}