import { useState, useEffect } from "react"


const useBluetoothChar = (service, characteristicUUID, subscribe = true, delay = 0) => {
    const [value, setValue] = useState(null)
    const [, setCharacteristic] = useState(null)

    useEffect(() => {
        async function getCharacteristic() {
            if (service && characteristicUUID && subscribe) {
                function readValue({ target }) {
                    const utf8decoder = new TextDecoder()
                    const decodedValue = utf8decoder.decode(target.value)
                    setValue(decodedValue)
                }
                try {
                    const char = await service.getCharacteristic(characteristicUUID)
                    char.startNotifications().then(() => {
                        char.addEventListener('characteristicvaluechanged', readValue)
                    })
                    setCharacteristic(char)
                } catch (err) {
                    console.warn("CookClub BT getCharateristic has thrown an error", err)
                }
            }
        }
        setTimeout(() => getCharacteristic(), delay)
    }, [service, characteristicUUID, subscribe])
    return value
}

export default useBluetoothChar