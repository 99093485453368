import React from "react";
import { DragDropContext } from "react-beautiful-dnd";
import ArrayAccordionRender from "./ArrayAccordionRender";
import { onAddArrayElement } from "./utils";
import { defaultStep } from "./DropdownOptions"
import PropTypes from 'prop-types'

const StepsEdit = ({ stepsData, onStepsArrayChange, localRecipeJson = [] }) => {
  const handleStepsArray = (e, defaultAddJson, index) => {
    e.stopPropagation()
    onAddArrayElement(onStepsArrayChange, stepsData, defaultAddJson, index, "step")
  }


  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const items = Array.from(stepsData)
    const reorderedItems = items.map((item, index) => {
      if (item.id === source.droppableId) {
        return item.subSteps.splice(source.index, 1)
      }
      return []
    })?.find(i => i.length)
    items.forEach((item, index) => {
      if (item.id === destination.droppableId) {
        item.subSteps.splice(destination.index, 0, ...reorderedItems)
      }
    })
    onStepsArrayChange(items)
  }

  return (
    <DragDropContext
      onDragEnd={onDragEnd}
    >
      <ArrayAccordionRender
        localRecipeJson={localRecipeJson}
        arrayData={stepsData}
        onChange={onStepsArrayChange}
        getItemTitle={(value) => value.name}
        defaultAddJson={defaultStep}
        onAddArrayElement={(e, defaultAddJson, index) => handleStepsArray(e, defaultAddJson, index)}
      />
    </DragDropContext>
  );
}

StepsEdit.propTypes = {
  stepsData: PropTypes.arrayOf(PropTypes.object).isRequired,
  onStepsArrayChange: PropTypes.func.isRequired
}

export default StepsEdit;
