import React, { createContext, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { SnackbarProvider } from 'notistack'

const defaultSettings = {
    deviceId: uuid(),
    userCode: "Soup Nazi",
    device: "any"
}

export const GlobalSettingsContext = createContext()

export const GlobalSettingsProvider = ({ children }) => {
    const [globalSettings, setGlobalSettings] = useState(JSON.parse(localStorage.getItem("globalSettings")) || defaultSettings)

    function changeGlobalSettings(newGlobalSettings) {
        localStorage.setItem("globalSettings", JSON.stringify(newGlobalSettings))
        setGlobalSettings(newGlobalSettings)
    }

    function resetSettings() {
        changeGlobalSettings(defaultSettings)
    }

    return <GlobalSettingsContext.Provider value={{ globalSettings, resetSettings, setGlobalSettings: changeGlobalSettings }}>
        <SnackbarProvider>
            {children}
        </SnackbarProvider>
    </GlobalSettingsContext.Provider>
}