import { Stack } from '@mui/material'
import React from 'react'
import { KETTLE_SETTINGS, MINCE_SETTINGS, PREHEAT_SETTINGS, SELF_CLEAN_SETTINGS, SLOW_COOK_SETTINGS, STEAMER_SETTINGS, TADKA_SETTINGS } from './InitialSettings'
import PresetCard from './PresetCard'
import { ReactComponent as SelfClean } from "../../svg/preset/self-clean.svg"
import { ReactComponent as Steamer } from "../../svg/preset/steamer.svg"
import { ReactComponent as SlowCook } from "../../svg/preset/slow-cook.svg"
import { ReactComponent as Tadka } from "../../svg/preset/tadka.svg"
import { ReactComponent as Mince } from "../../svg/preset/mince.svg"
import { ReactComponent as Kettle } from "../../svg/preset/kettle.svg"
import { ReactComponent as Preheat } from "../../svg/preset/preheat.svg"
import Dialog from '@mui/material/Dialog'
import { useState } from 'react'
import AlertDialogBox from './AlertDialogBox'
import { containsPresetInstructions } from './utils'
import { errorUncontrolledIssueUrl } from '../../audioUrls/audioUrl';
import useCookClubAudio from '../../utils/audio/cookClubAudio'

const PresetDrawer = ({open, onClose, settings, onChangeSettings, stateOfOperation}) => {
  const [alertOpen, setAlertOpen] = useState(false)
  const [presetMode, setPresetMode] = useState('')
  const [removePresetSound] = useCookClubAudio(errorUncontrolledIssueUrl)

  const handlePreset = (e, type) => {
    setAlertOpen(false)
    switch(type) {
      case "selfClean": {
        onChangeSettings(SELF_CLEAN_SETTINGS)
        break
      }
      case "steamer": {
        onChangeSettings(STEAMER_SETTINGS)
        break
      }
      case "slowCook": {
        onChangeSettings(SLOW_COOK_SETTINGS)
        break
      }
      case "tadka": {
        onChangeSettings(TADKA_SETTINGS)
        break
      }
      case "kettle": {
        onChangeSettings(KETTLE_SETTINGS)
        break
      }
      case "mince": {
        onChangeSettings(MINCE_SETTINGS)
        break
      }
      case "preheat": {
        onChangeSettings(PREHEAT_SETTINGS)
        break
      }
      default: return null
    }
  }

  const handlePresetMode = (e, value) => {
    if (!containsPresetInstructions(settings)) {
      setPresetMode(value)
      setAlertOpen(true)
      removePresetSound()
    } else {
      handlePreset(e, value)
    }
  }

  return (
    <>
    <Dialog
      open={stateOfOperation!=="operation" && open}
      onClose={onClose}
      fullWidth
      PaperProps={{
        style: {
          borderRadius: "10px",
          maxWidth: "70%"
        },
      }}
    >
      <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" sx={{padding: "40px", borderRadius: "10px", backgroundColor: "#E0DDDD"}}>
        <Stack direction="row" spacing={2}>
          <PresetCard onClick={(e) => handlePresetMode(e, "selfClean")}  title="Self Clean" icon={<SelfClean/>}  />
          <PresetCard onClick={(e) => handlePresetMode(e, "steamer")} title="Steamer" icon={<Steamer/>}  />
        </Stack>
        <Stack direction="row" spacing={2}>
          <PresetCard onClick={(e) => handlePresetMode(e, "slowCook")} title="Slow Cook" icon={<SlowCook/>} open={alertOpen} onClose={() => setAlertOpen(false)} />
          <PresetCard onClick={(e) => handlePresetMode(e, "tadka")}   title="Tadka" icon={<Tadka/>} open={alertOpen} onClose={() => setAlertOpen(false)} />
        </Stack>
        <Stack direction="row" spacing={2}>
          <PresetCard onClick={(e) => handlePresetMode(e, "kettle")} title="Kettle" icon={<Kettle/>} open={alertOpen} onClose={() => setAlertOpen(false)} />
          <PresetCard onClick={(e) => handlePresetMode(e, "mince")} title="Mince"icon={<Mince/>} open={alertOpen} onClose={() => setAlertOpen(false)} />
        </Stack>
        <Stack direction="row"  alignSelf="flex-start">
          <PresetCard marginRight={"20%"} onClick={(e) => handlePresetMode(e, "preheat")}  title="Preheat" icon={<Preheat/>} open={alertOpen} onClose={() => setAlertOpen(false)} />
        </Stack>
      </Stack>
    </Dialog>
    <AlertDialogBox open={alertOpen} onClose={() => {
      onClose()
      setAlertOpen(false)
    }} 
    onClick={(e) => handlePreset(e, presetMode)} 
    title="This action will delete serial instructions" 
    bodyText1="If you add preset now, all your previously set serial" 
    bodyText2="instructions will be deleted." 
    leftBtn="Proceed"
    />
    </>
  )
}

export default PresetDrawer