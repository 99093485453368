import { useState, useCallback, useContext } from 'react'
import {
    defaultServiceUUID,
    defaultCharacteristicUUIDMain,
    defaultCharacteristicUUIDWrite
} from './bleServiceAndCharUUIDs'
import { ErrorManagementContext } from "../../utils/errorManagement/ErrorManagementProvider"



const useBluetoothForHardware = (
    settings = { autoConnect: "Device Not Set" },
    serviceUUID = defaultServiceUUID,
    characteristicUUIDWrite = defaultCharacteristicUUIDWrite,
    characteristicUUID1 = defaultCharacteristicUUIDMain,
    characteristicUUID2,
    characteristicUUID3,
    characteristicUUID4
) => {
    const [value1, setValue1] = useState(null)
    const [value2, setValue2] = useState(null)
    const [value3, setValue3] = useState(null)
    const [value4, setValue4] = useState(null)
    const [connectStatus, setConnectStatus] = useState("disconnected")
    const { cookClubLog } = useContext(ErrorManagementContext)
    const deviceName = settings.autoConnect

    const disconnect = useCallback(() => {
        window.Ble.stopBleScan()
        window.Ble.disconnectGatt()
        window.onCharacteristicChange = () => { }
        setConnectStatus("disconnected")
    }, [setConnectStatus])

    function onScanComplete(name, address) {
        cookClubLog(`Connected to ${name}`, "BLE_CONNECT_START", { name, address }, { ui: { type: "success" } })
    }

    function onScanFailed(errorCode) {
        cookClubLog(`Failed to connect ${errorCode}`, "BLE_CONNECT_FAILED", { errorCode }, { ui: { type: "error" } })
    }

    const connect = useCallback(() => {
        cookClubLog(`Connecting to ${deviceName}`, "BLE_CONNECT_START", { deviceName }, { ui: { type: "info" } })
        function onCharUpdate(charUUID, value) {
            switch (charUUID) {
                case characteristicUUID1:
                    setValue1(value)
                    break
                case characteristicUUID2:
                    setValue2(value)
                    break
                case characteristicUUID3:
                    setValue3(value)
                    break;
                case characteristicUUID4:
                    setValue4(value)
                    break;
                default:
                    break
            }
        }
        function onConnectBLE(name, address, services) {
            console.log(`OnConnectBLE ${name} ${address} ${services}`)

            //consider timeout
            setTimeout(() => {
                window.Ble.enableNotificationsForCharacteristic(serviceUUID, characteristicUUID1)
            })

            setTimeout(() => {
                window.Ble.enableNotificationsForCharacteristic(serviceUUID, characteristicUUID2)
            }, 250)

            setTimeout(() => {
                window.Ble.enableNotificationsForCharacteristic(serviceUUID, characteristicUUID3)
            }, 500)

            setTimeout(() => {
                window.Ble.enableNotificationsForCharacteristic(serviceUUID, characteristicUUID4)
            }, 750)

            window.Ble.stopBleScan()
        }
        window.onScanResult = onScanComplete
        window.onCharacteristicChange = onCharUpdate
        window.onServiceDiscovery = onConnectBLE
        window.onScanFailed = onScanFailed
        window.onGattStateChange = (name, address, status) => {
            console.log(`onGattStateChange ${name} ${address} ${status}`)
            if (status.toLowerCase().startsWith("error")) {
                console.log(`Gatt state has error ${status} reconnecting`)
                window.Ble.disconnectGatt();
                setConnectStatus("disconnected")
                window.Ble.startBleScan(deviceName)
            } else {
                setConnectStatus(status.toLowerCase())
            }
        }

        window.onWriteDescriptor = (uuid, status) => {
            console.log("onWriteDescriptor ", uuid, status);
        }

        window.onCharacteristicWriteComplete = (uuid, status) => console.log("Characteristic write complete ", uuid, status)
        setConnectStatus("connecting")
        window.Ble.startBleScan(deviceName)
    }, [deviceName, setConnectStatus])

    const writeToBluetooth = useCallback((text = "React") => {
        async function writeValueAsync() {
            try {
                if (connectStatus === "connected") {
                    window.Ble.writeCharacteristic(serviceUUID, characteristicUUIDWrite, text)
                } else {
                    console.error("Attempt to write before connecting to ble device")
                }
            } catch (err) {
                console.error("BlueToothWrite Failed:", err)
            }
        }
        writeValueAsync()
    }, [connectStatus, serviceUUID, characteristicUUIDWrite])

    const connected = connectStatus === "connected"

    return [connect, writeToBluetooth, value1, connected, disconnect, value2, value3, value4]
}

export default useBluetoothForHardware
