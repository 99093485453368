import { Box, Button, AppBar, Toolbar, IconButton, Typography, Drawer, CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import SecureArea from "../components/authentication/SecureArea";
import IngredientsPropertiesEdit from "../components/ingredientseditor/IngredientPropertiesEdit";
import IngredientsNameDisplay from "../components/ingredientseditor/IngredientsNameDisplay";
import { schema } from "../components/ingredientseditor/ingredientsSchema";
import { checkData, fetchEdamamdata } from "../components/ingredientseditor/utils";
import useIngredientsDB from "../utils/hardware/ingredientsDB"
import MenuIcon from '@mui/icons-material/Menu'
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";

const addNutrients = async (data) => {
    const nutrientKey = Object.keys(data?.nutrientInfo)
    const checkForNutrients = nutrientKey.find(i => !data.nutrientInfo[i])
    if (checkForNutrients && data.displayName !== "new ingredient") {
        const nutrientData = await fetchEdamamdata(data.displayName)
        if (nutrientData)
            return { ...data, nutrientInfo: nutrientData }
        else
            return data
    }
    return data

}

const IngredientsEditor = () => {
    const [data, setData] = useState(null)
    const { id } = useParams()
    const history = useHistory()
    const { ingredients, saveNewIngredient, updateIngredient, deleteIngredient, saving, loading } = useIngredientsDB()
    const [ingredientListDrawerOpen, setIngredientListDrawerOpen] = useState(true)

    const onSave = () => {
        updateIngredient(data, id)
        history.push(`/ingredientsadmin/${id}`)
    }

    const fetchData = async () => {
        const dataWithAddedNutrients = await addNutrients(data)
        setData(dataWithAddedNutrients)
    }

    const onDelete = () => {
        deleteIngredient(id)
        setData(null)
        history.push(`/ingredientsadmin`)
    }

    useEffect(() => {
        const selectedIngredient = ingredients.find(i => i.id === id)
        if (selectedIngredient) {
            const displayedData = Object.keys(selectedIngredient).reduce((old, next) => (next !== "id") ? { ...old, [next]: selectedIngredient[next] } : old, {})
            setData(checkData(schema, displayedData))
            setIngredientListDrawerOpen(false)
        }
        else
            setIngredientListDrawerOpen(true)
    }, [id, ingredients])

    if (loading) return <CircularProgress />
    return (
        <SecureArea adminOnly>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => setIngredientListDrawerOpen(old => !old)}
                        size="large">
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6">
                        Ingredients Admin
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                anchor="left"
                open={ingredientListDrawerOpen}
                onClose={() => setIngredientListDrawerOpen(false)}
            >
                {!loading ? <IngredientsNameDisplay
                    data={ingredients}
                    onClick={id => {
                        history.push(`/ingredientsadmin/${id}`)
                        setIngredientListDrawerOpen(false)
                    }}
                    onAdd={(data) => {
                        saveNewIngredient({ ...data, displayName: "new ingredient" }, (id) => history.push(`/ingredientsadmin/${id}`))
                    }}
                    schema={schema} /> : null}
            </Drawer>
            {data ? <Box
                style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "90vh" }}
            >
                {data ? <Box width="50%" height="100%" margin="10vh" display="flex" flexDirection="column" style={{ gap: 15, overflowY: "scroll" }}>
                    <IngredientsPropertiesEdit data={data} setData={setData} schema={schema} />
                </Box> : <Box width="50%" margin="10vh" />}
                <Box
                    display="flex"
                    flexDirection="column"
                >
                    <Button onClick={fetchData} disabled={saving || loading} sx={{ textTransform: "none" }}>Fetch nutrient data</Button>
                    <Button onClick={onSave} disabled={saving || loading} sx={{ textTransform: "none" }}>Save</Button>
                    <Button onClick={onDelete} disabled={saving || loading} sx={{ textTransform: "none" }}>Delete</Button>
                </Box>
            </Box> : null}
        </SecureArea>
    );
}

export default IngredientsEditor