import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Card, CircularProgress, Grid, Button, IconButton, Stack, Typography, Checkbox } from '@mui/material'
import IngredientsSelector, { PrepSelector, QtySelector, ScaleModel } from './../customInputs/IngredientsSelector'
import useIngredientsDB from '../../../utils/hardware/ingredientsDB'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { LegendToggleRounded } from '@mui/icons-material'

const DeleteButtonWithDisableOnPress = ({ deleteItemAtIndex, index }) => {
    const [deleteDisable, setDeleteDisable] = useState(false)
    const handleDelete = () => {
        setDeleteDisable(true)
        deleteItemAtIndex(index)
    }
    return <Button disabled={deleteDisable} onClick={handleDelete}><DeleteIcon color="secondary" /></Button>

}

const AddIngredients = ({ parameters, setParameters }) => {
    const items = parameters?.items ? parameters.items : []
    const { ingredients, loading } = useIngredientsDB()

    const setItemAtIndex = (newItem, index) => {
        const newItems = items.map((item, i) => i === index ? newItem : item)
        setParameters({ items: newItems })
    }
    const setPrepIdAtIndex = (newPrepId, index) => {
        const newItemWithNewPrep = { ...items[index], prep: { prepId: newPrepId } }
        setItemAtIndex(newItemWithNewPrep, index)
    }
    const setIngredientId = (newingredientId, newAmount, index) => {
        const newItem = { ...items[index], ingredientId: newingredientId, amount: newAmount, prep: { prepId: null } }
        setItemAtIndex(newItem, index)
    }
    const setNewAmount = (newAmount, index) => {
        const newItem = { ...items[index], amount: newAmount }
        setItemAtIndex(newItem, index)
    }
    const addItem = () => {
        const newItem = { ingredientId: "Jvrv1829TmalCfkvRg7z", amount: { qty: 10, unit: "cloves" } }
        setParameters({ items: [...items, newItem] })
    }
    const setNewScaleModel = (newScaleModel, index) => {
        const newItem = { ...items[index], scaleModel: newScaleModel }
        setItemAtIndex(newItem, index)
    }

    const deleteItemAtIndex = index => {
        setParameters({ items: items.filter((element, i) => i !== index) })
    }

    const onMoveUp = (e, index) => {
        e.stopPropagation()
        let ret = Array.from(items)
        const temp = ret[index]
        ret[index] = ret[index - 1]
        ret[index - 1] = temp
        setParameters({ items: ret })
    }

    const onMoveDown = (e, index) => {
        e.stopPropagation()
        let ret = Array.from(items)
        const temp = ret[index]
        ret[index] = ret[index + 1]
        ret[index + 1] = temp
        setParameters({ items: ret })
    }

    const setDefaultIngredient = (val, index) => {
        const newItem = { ...items[index], default: val }
        setItemAtIndex(newItem, index)
    }

    if (loading) return <CircularProgress />
    return (
        <>
            {items.map((item, index) => (
                <Card key={item.ingredientId} sx={{ marginBottom: 2 }}>
                    <Grid container columnSpacing={2} sx={{ backgroundColor: "#e1f5fe" }}>
                        <Grid item xs={3}>
                            <IngredientsSelector
                                ingredientId={item.ingredientId}
                                ingredients={ingredients}
                                setIngredientId={(newValue, newAmount) => setIngredientId(newValue, newAmount, index)}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Stack direction="row">
                                <IconButton
                                    disabled={index === 0}
                                    color="success"
                                    onClick={(e) => onMoveUp(e, index)}
                                >
                                    <ArrowUpwardIcon />
                                </IconButton>
                                <IconButton
                                    disabled={index === items.length - 1}
                                    color="error"
                                    onClick={(e) => onMoveDown(e, index)}
                                >
                                    <ArrowDownwardIcon />
                                </IconButton>
                            </Stack>
                        </Grid>
                        <Grid item xs={3}>
                            <PrepSelector
                                ingredient={ingredients.find(ing => ing.id === item.ingredientId)}
                                selectedPrepId={item?.prep?.prepId}
                                setPrepId={newPrepId => setPrepIdAtIndex(newPrepId, index)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <QtySelector
                                ingredient={ingredients.find(ing => ing.id === item.ingredientId)}
                                setAmount={newAmount => setNewAmount(newAmount, index)}
                                amount={item?.amount}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <DeleteButtonWithDisableOnPress deleteItemAtIndex={deleteItemAtIndex} index={index} />
                        </Grid>
                        <Grid item xs={12}>
                            <ScaleModel
                                scaleModel={item?.scaleModel}
                                setScaleModel={newScaleModel => setNewScaleModel(newScaleModel, index)}
                            />
                        </Grid>
                        <Grid item xs={12} display="flex" alignItems="center">
                            <Typography>Default ingredient</Typography>
                            <Checkbox
                                checked={item?.default}
                                onChange={e => setDefaultIngredient(e.target.checked, index)}
                            />
                        </Grid>
                    </Grid>
                </Card>
            ))}
            <Button onClick={addItem}><AddIcon /></Button>
        </>
    )
}

AddIngredients.propTypes = {
    parameters: PropTypes.object,
    setParameters: PropTypes.func
}

export default AddIngredients