import React, { useState } from "react";
import PropTypes from 'prop-types';
import PrepModal from "../ingredients2/PrepModal";
import { Button, Typography, Box, SvgIcon } from "@mui/material";
import { ReactComponent as HowIcon } from "../../svg/how-button.svg"

const InstructionBox = ({ text, url }) => {
    const [open, setOpen] = useState(false)

    return (<Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{ width: "100%" }}
    >
        <Typography variant="h5">{text ? text : ""}</Typography>
        {url ? <Button style={{
            textTransform: "none",
            borderRadius: 10
        }}
            variant=""
            onClick={() => setOpen(old => !old)}
        >
            <SvgIcon component={HowIcon} viewBox="0 0 34 34" style={{ fontSize: 50 }} />
        </Button> : null}
        <PrepModal
            open={open}
            onClose={() => setOpen(old => !old)}
            prep={{
                howMedia: url,
                loop: true,
                duration: 4,
                startTime: 1
            }}
        />
    </Box>)
}

InstructionBox.propTypes = {
    text: PropTypes.string,
    url: PropTypes.string
}

export default InstructionBox