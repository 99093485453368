import React, { createContext, useEffect, useState, useContext, useMemo } from "react"
import useHardware from "../../utils/hardware/hardwareState"
import { GlobalSettingsContext } from "../../utils/globalSettings/globalSettingsContext"
import { jarOnUrl, lidOnUrl, containerOffUrl, startSoundUrl, endSoundUrl, bluetoothConnectUrl } from "../../audioUrls/audioUrl";
import useCookClubAudio from "../../utils/audio/cookClubAudio"

const HardwareContext = createContext(null)
const WeightContext = createContext(0)
const TimeContext = createContext(0)
const StateOfHardwareContext = createContext("disconnected")

const HardwareTask = ({ children }) => {

    const { globalSettings } = useContext(GlobalSettingsContext)

    const { connectToHardware,
        stateOfHardware,
        triggerR1,
        connected,
        forceStop,
        weight,
        time,
        jarOn,
        lidOn,
        temperature,
        heating,
        dutyCycle,
        resumeR1,
        pauseR1,
        disconnect,
    } = useHardware("2.0", globalSettings.device)

    const [stepStartTime, setStepStartTime] = useState(undefined)

    const [playLidSoundWithBeep] = useCookClubAudio(lidOnUrl)
    const [playJarSoundWithBeep] = useCookClubAudio(jarOnUrl)
    const [containerOffSound] = useCookClubAudio(containerOffUrl)
    const [playStartSound] = useCookClubAudio(startSoundUrl)
    const [playEndSound] = useCookClubAudio(endSoundUrl)
    const [bluetoothConnectSound] = useCookClubAudio(bluetoothConnectUrl)

    useEffect(() => {
        if (connected) {
            if (lidOn === "on")
                playLidSoundWithBeep()
            else if (lidOn === "off")
                containerOffSound()
        }
    }, [connected, lidOn])

    useEffect(() => {
        if (connected) {
            if (jarOn === "on")
                playJarSoundWithBeep()
            else if (jarOn === "off")
                containerOffSound()
        }
    }, [connected, jarOn])

    useEffect(() => {
        if (connected) {
            bluetoothConnectSound()
        }
    }, [connected])

    useEffect(() => {
        if (stateOfHardware === "operation") {
            setStepStartTime(Date.now())
            playStartSound()
        }
        if (stateOfHardware === "idle") {
            playEndSound()
        }
    }, [stateOfHardware, playStartSound])

    return (
        <HardwareContext.Provider value={{
            connectToHardware,
            triggerR1,
            connected,
            forceStop,
            stepStartTime,
            jarOn,
            lidOn,
            temperature,
            dutyCycle,
            heating,
            pauseR1,
            resumeR1,
            disconnect,
        }}>
            <StateOfHardwareContext.Provider value={stateOfHardware}>
                <WeightContext.Provider value={weight}>
                    <TimeContext.Provider value={time}>
                        {children}
                    </TimeContext.Provider>
                </WeightContext.Provider>
            </StateOfHardwareContext.Provider>
        </HardwareContext.Provider >
    )
}

export { HardwareContext, HardwareTask, WeightContext, TimeContext, StateOfHardwareContext }
