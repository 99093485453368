import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import PopUpModal from '../PopUpModal'

const AlertDialogBox = ({open, onClose, onClick, title, bodyText1, bodyText2, leftBtn}) => {
  return (
    <PopUpModal
      open={open}
      onClose={onClose}
      size="small"
    >
      <Box
        style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-around",
            alignItems: "center"
        }}
      >
        <Typography variant="h5">{title}</Typography>
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
        >
          <Typography variant="body2">
              {bodyText1}
          </Typography>
          <Typography variant="body2">
              {bodyText2}
          </Typography>
        </Box>
        <Box
          display="flex"
          gap="20px"
          justifyContent="center"
        >
          <Button
            className={"r1-btn dialog-btn-dimension"}
            variant="contained"
            onClick={onClick}
            style={{ boxShadow: "none" }}
          >
            <Typography variant="button">{leftBtn}</Typography>
          </Button>
          <Button
            className="r1-btn pause-btn dialog-btn-dimension"
            onClick={onClose}
          >
            <Typography variant="button">Cancel</Typography>
          </Button>
        </Box>
      </Box>
    </PopUpModal>
  )
}

export default AlertDialogBox 