import { useCallback } from "react"
import useSound from "use-sound"

const useCookClubAudio = (audioUrl) => {
    const [playSound] = useSound(audioUrl)
    const playAudioFunc = useCallback(() => window.Audio.playAudio(audioUrl), [audioUrl])
    const play = navigator.userAgent === "Reactlabs" ? playAudioFunc : playSound
    return [play]
}

export default useCookClubAudio
