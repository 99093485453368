import { INSTRUCTION_TYPES, INTERVAL_SETTINGS } from "./InitialSettings"

export const checkRange = (num, interval) => {
  const [min, max] = interval
  return (num - min) * (max - num) >= 0
}

export const getNames = (setting) => {
  const { time, temperature, speed } = setting
  const matchSettings = INTERVAL_SETTINGS.find((v, index) => {
    return (checkRange(time, v.time) && checkRange(Number(temperature), v.temperature) && checkRange(speed, v.speed)) 
  })
  return matchSettings?.name
}

export const handleInstructionNames = (settings) => {
  const instructionNames = settings.map((v, i) => {
    return {...v, instructionName: getNames(v) || `Instruction ${i+1}`}
  })
  return instructionNames
}

export const containsPresetInstructions = (settings) => {
  return settings.find(i => INSTRUCTION_TYPES.includes(i.instructionName))
}