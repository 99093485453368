import {
  AppBar, Toolbar, IconButton, Typography, Drawer, Dialog, Container
} from "@mui/material"
import MenuIcon from '@mui/icons-material/Menu'
import SecureArea from "../components/authentication/SecureArea"
import React, { useState, useContext } from "react"
import RecipeJsonDisplay from "../components/newRecipeJson/RecipeJsonDisplay"
import { initialObject } from "../components/newRecipeJson/DropdownOptions"
import DuplicateRecipe from "../components/newRecipeJson/DuplicateRecipe"
import RecipeEditor2 from "../components/newRecipeJson/RecipeEditor2"
import { v4 as uuid } from "uuid"
import { useParams } from "react-router-dom"
import { AuthContext } from "../firebase/context"
import useNewRecipe from "../utils/RecipeBackEnd/saveNewRecipe"

const NewRecipeJson = () => {
  const { recipeId: urlRecipeId } = useParams()
  const [selectedRecipeId, setSelectedRecipeIdReal] = useState(urlRecipeId)
  const userObject = useContext(AuthContext)
  const { metadata, steps } = initialObject
  const defaultRecipe = {
    ...initialObject, metadata: {
      ...metadata,
      author: userObject?.user?.displayName
    },
    steps: [{ ...steps[0], id: uuid() }]
  }
  const { saveNew, saving } = useNewRecipe()
  const [recipeListDrawerOpen, setRecipeListDrawerOpen] = useState(urlRecipeId ? false : true)
  const [duplicateOpen, setDuplicateOpen] = useState(false)
  const [duplicateRecipeDrawerOpen, setDuplicateRecipeDrawerOpen] = useState(false)
  function setSelectedRecipeId(newVal) {
    if (newVal)
      window.history.replaceState(null, "", "/newjson/" + newVal)
    setSelectedRecipeIdReal(newVal)
  }
  function onSelect(id) {
    setRecipeListDrawerOpen(false)
    setSelectedRecipeId(id)
  }
  function onDelete() {
    setSelectedRecipeId(null)
    setRecipeListDrawerOpen(true)
  }

  function handleDuplicateRecipe() {
    setDuplicateRecipeDrawerOpen(true)
  }

  return (
    <SecureArea adminOnly>
      <AppBar position="static">
        <Container>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setRecipeListDrawerOpen(old => !old)}
              size="large">
              <MenuIcon />
            </IconButton>
            <Typography variant="h6">
              Recipe Admin Experiance 2.0
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
      <Container>
        <RecipeEditor2
          recipeId={selectedRecipeId}
          onDeleteCallback={onDelete}
        />
      </Container>
      <Drawer
        anchor="left"
        open={recipeListDrawerOpen}
        onClose={() => setRecipeListDrawerOpen(false)}
      >
        <RecipeJsonDisplay
          onClickRecipeName={id => onSelect(id)}
          saving={saving}
          onAddNewRecipe={() => saveNew(defaultRecipe, newId => setSelectedRecipeId(newId))}
          onDuplicateNewRecipe={handleDuplicateRecipe}
          type="add"
        />
      </Drawer>
      <Drawer
        anchor="left"
        open={duplicateRecipeDrawerOpen}
        onClose={() => {
          setDuplicateRecipeDrawerOpen(false)
          setDuplicateOpen(false)
        }}
      >
        <RecipeJsonDisplay
          onClickRecipeName={(id) => {
            setSelectedRecipeId(id)
            setDuplicateOpen(true)
          }}
          saving={saving}
          type="duplicate"
        />
      </Drawer>
      <Dialog style={{ height: 900 }} open={duplicateOpen} onClose={() => {
        setDuplicateOpen(false)
      }}>
        <DuplicateRecipe recipeId={selectedRecipeId} setDuplicateOpen={setDuplicateOpen} />
      </Dialog>
    </SecureArea>
  );

};

export default NewRecipeJson;
