import React, { useState, useContext } from "react"
import TitleSlide from "../components/recipeSteps/titleSlide/TitleSlide";
import { getAllAllergens, getTotalMacros, getAllRecipeIngredients } from "../utils/experience2/ingredientsUtil"
import { IngredientsContext } from "../components/ingredients2/IngredientsFromDB";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { Keyboard } from "swiper";
import IngredientsList from "../components/ingredients2/IngredientsList";
import Steps from "../components/recipeSteps/Steps"
import Loader from "./Loader";
import PageTitle from "./PageTitle";
import { Box } from "@mui/material";
import PropTypes from 'prop-types'
import { ErrorManagementContext } from "../utils/errorManagement/ErrorManagementProvider"
import { changeRecipeOnServing } from "../utils/experience2/stepsUtils";

const Experience2 = ({ recipe, hardwareStateMemo, recipeLoading, recipeDispatch }) => {
    const [swiperInstance, setSwiperInstance] = useState(null)
    const { ingredients, loading: ingredientsLoading } = useContext(IngredientsContext)
    const { cookClubLog } = useContext(ErrorManagementContext)

    if (!recipe || recipeLoading || ingredientsLoading)
        return <Box style={{ width: "100%" }}>
            <Loader />
        </Box>

    function setServing(newServing) {
        recipeDispatch({ type: "CHANGE_SERVING_SIZE", data: { newServingSize: newServing } })
    }

    const { serving } = recipe
    const { maxServing, minServing } = recipe.metadata 

    const allIngredientsInRecipe = getAllRecipeIngredients(recipe.steps, ingredients)
    const ingredientsPerServing = getAllRecipeIngredients(changeRecipeOnServing(recipe, 1).steps, ingredients) 
    const totalMacros = getTotalMacros(ingredientsPerServing)
    const { calorie, fat, protein, carbs } = getTotalMacros(allIngredientsInRecipe)
    const macrosPerServing = maxServing===minServing ?  {calorie: Math.round(calorie / serving), fat: Math.round(fat / serving), protein: Math.round(protein / serving), carbs: Math.round(carbs / serving)} : totalMacros

    return <Swiper
        keyboard={{ enabled: true }}
        modules={[Keyboard]}
        onTransitionEnd={() => {
            cookClubLog("Hey, slide", "RECIPE_SLID", { slideIndex: swiperInstance.activeIndex }, { firebase: "on" })
        }}
        onSwiper={swiper => setSwiperInstance(swiper)}
        allowTouchMove={false}
    >

        <SwiperSlide>
            <TitleSlide
                {...recipe?.metadata}
                setServing={setServing}
                serving={serving}
                onStartCooking={() => swiperInstance.slideNext()}
                allergens={getAllAllergens(allIngredientsInRecipe)}
                totalMacros={macrosPerServing}
            />
        </SwiperSlide>
        <SwiperSlide>
            <PageTitle title="Ingredients"
                onClickBack={() => swiperInstance.slidePrev()}
                onClickNext={() => swiperInstance.slideNext()}
            >
                <IngredientsList items={allIngredientsInRecipe} recipeDispatch={recipeDispatch} />
            </PageTitle>
        </SwiperSlide>
        <SwiperSlide>
            <PageTitle
                title={recipe.metadata?.name}
                onClickBack={() => swiperInstance.slidePrev()}
                displayNextBtn={false}
            >
                <Steps
                    recipe={recipe}
                    recipeDispatch={recipeDispatch}
                    hardwareStateMemo={hardwareStateMemo}
                />
            </PageTitle>
        </SwiperSlide>
    </Swiper>
}

Experience2.propTypes = {
    recipe: PropTypes.instanceOf(Object),
    hardwareStateMemo: PropTypes.instanceOf(Object),
    recipeLoading: PropTypes.bool.isRequired,
    recipeDispatch: PropTypes.func.isRequired
}

export default Experience2