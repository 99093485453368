import { Box } from '@mui/material';
import React from 'react';
import "../css/loader.css";
import { ReactComponent as Logo } from "./logo.svg"

const Loader = () => {
    return (
        <Box
            className="loader-container"
        >
            <Box
                className="logo-container"
            >
                <Logo width="200" height="200" />
            </Box>
            <svg width="410" height="410" xmlns="http://www.w3.org/2000/svg">
                <circle cx="300" cy="330" r="20" fill="#007042"></circle>
                <g className="loader-parent">
                    <circle className="loader" cx="205" cy="205" r="160" stroke="#007042" fill="transparent" />
                </g>
            </svg>
        </Box>
    )
}

export default Loader