import React from "react";
import { Box } from "@mui/material"
import DottedLine from "./DottedLine";
import PropTypes from "prop-types";

const SpicesDisplay = ({ display, children, displayDottedLine = true }) => {
    if (display) {
        return (<>
            <Box padding="3% 0">
                {children}
            </Box>
            {displayDottedLine ? <DottedLine /> : null}
        </>)
    }
    return null
}

SpicesDisplay.propTypes = {
    display: PropTypes.bool,
    children: PropTypes.element,
    displayDottedLine: PropTypes.bool,
}

export default SpicesDisplay