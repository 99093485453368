import React, { useContext } from "react"
import { Box, Typography } from "@mui/material"
import { ReactComponent as BladeIdle } from '../../svg/hardwareBar/bladeIdle.svg'
import { ReactComponent as BladeRunning } from '../../svg/hardwareBar/bladeIcon.svg'
import { StateOfHardwareContext } from "./HardwareTasks"

const BladeIcon = () => {
    const stateOfHardware = useContext(StateOfHardwareContext)

    return (<Box
        style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "10px"
        }}
    >
        {
            stateOfHardware.status === "operation" ?
            <BladeRunning/>
            :
            <BladeIdle/>
        }
        <Typography sx={{fontSize: "18px"}} >0</Typography>
    </Box>)
}

export default BladeIcon