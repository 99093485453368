/* */
import React from 'react'
import SecureArea from '../components/authentication/SecureArea'
import { AppBar, Container, Typography, Toolbar, Link, Card, CardContent, Stack } from '@mui/material'
// import { useHistory } from 'react-router-dom'

const AdminPage = () => {
    // const history = useHistory()
    // function onClickAdminLink(path) { history.push(path) }
    return (
        <SecureArea adminOnly>
            <AppBar position="static">
                <Container>
                    <Toolbar>
                        <Typography>Admin Page</Typography>
                    </Toolbar>
                </Container>
            </AppBar>
            <Container>
                <Stack spacing={3}>
                    <Card>
                        <CardContent>
                            <Stack>
                                <Link underline="hover" >Allow WebBLE permissions</Link>
                                <Link href="chrome://flags/#enable-experimental-web-platform-features">Allow BLE GetDevices</Link>
                            </Stack>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardContent>
                            <Link href="/ingredientsadmin">IngredientsAdmin</Link>
                            <Link href="/newjson">Recipe Admin</Link>
                        </CardContent>
                    </Card>
                </Stack>
                <Link></Link>
            </Container>
        </SecureArea>
    )
}

AdminPage.propTypes = {}

export default AdminPage