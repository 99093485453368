import { Typography, Box, Button } from "@mui/material";
import React from "react";
import "../../../css/experience2.css"
import ServingBtn from "./ServingBtn";
import "../../../css/titlePage.css"
import PropTypes from "prop-types"
import { ReactComponent as ServingIcon } from "../../../svg/cardIcons/servingSize.svg"
import { resizeImageIfCloudinary } from "../../../utils/images/cloudinaryUtils";

const TitleSlide = ({
    url = "",
    name,
    prepTime,
    cookTime,
    description,
    minServing = 1,
    maxServing = 4,
    serving = 2,
    setServing,
    categories = [],
    tags = [],
    onStartCooking = () => { },
    allergens,
    totalMacros
}) => {

    const changeServing = (value) => {
        setServing(value)
    }
    const compactUrl = resizeImageIfCloudinary(url, 600)

    return (
        <Box
            sx={{ position: "relative", height: "100vh", display: "flex" }}
        >
            < Box
                sx={{
                    backgroundImage: `url(${compactUrl})`,
                    backgroundPosition: "right",
                    backgroundSize: "50% 100%",
                    backgroundRepeat: "no-repeat",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    padding: "1% 1%",
                    boxSizing: "border-box",
                }}>
                <Box
                    sx={{
                        height: "80%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between"
                    }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column"
                        }}>
                        <Box sx={{ display: "flex", gap: 2 }}>
                            {categories.map((i, index) => <Typography key={`${index}${i}`} variant="body2">{i}</Typography>)}|
                            {tags.map((i, index) => <Typography key={`${index}${i}`} variant="body2">{i}</Typography>)}
                        </Box>
                        <Typography variant="h2">{name}</Typography>
                        <Box width="50%">
                            <Typography variant="body2" >{description}</Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Typography variant="body1">Estimated Time</Typography>
                        <Typography variant="h3">{`${prepTime + cookTime} mins`}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "10px 30px 0 0" }}>
                            <ServingIcon/>
                            <Typography variant="body1">Serves</Typography>
                        </Box>
                        <Box className="serving-info">
                            <ServingBtn
                                changeServing={changeServing}
                                serving={serving}
                                maxServing={maxServing}
                                minServing={minServing}
                                disableIncrease={serving === maxServing}
                                disableDecrease={serving === minServing}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            bottom: 20,
                            padding: "0 1%",
                            width: "100%",
                            position: "absolute",
                            alignItems: "flex-end",
                            justifyContent: "space-between",
                            boxSizing: "border-box",
                            right: 0
                        }}>
                        <Typography sx={{ width: "60%", display: "inline-block" }}>
                        {allergens.length!==0 &&  "Contains "}
                        <Typography variant="caption">{allergens.join(", ")}</Typography>
                        </Typography>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    color: "white",
                                    backgroundColor: "black",
                                    opacity: "0.6",
                                    borderRadius: 5,
                                    padding: "17px 0",
                                }}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        borderRight: "3px solid white",
                                        padding: "0 20px",
                                        alignItems: "center"
                                    }}
                                >
                                    <Typography variant="body1">Calories</Typography>
                                    <Typography variant="caption">{totalMacros?.calorie}</Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        borderRight: "3px solid white",
                                        padding: "0 20px",
                                        alignItems: "center"
                                    }}>
                                    <Typography variant="body1">Carbs</Typography>
                                    <Typography variant="caption">{`${totalMacros?.carbs}g`}</Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        borderRight: "3px solid white",
                                        padding: "0 20px",
                                        alignItems: "center"
                                    }}>
                                    <Typography variant="body1">Fats</Typography>
                                    <Typography variant="caption">{`${totalMacros?.fat}g`}</Typography>
                                </Box>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    sx={{ padding: "0 20px", alignItems: "center" }}>
                                    <Typography variant="body1">Proteins</Typography>
                                    <Typography variant="caption">{`${totalMacros?.protein}g`}</Typography>
                                </Box>
                            </Box>
                            <Button
                                onClick={onStartCooking}
                                color="primary"
                                variant="contained"
                                className="start-cooking-btn"
                            >
                                <Typography variant="button">Get Started</Typography>
                            </Button>
                        </Box>
                    </Box>
                </Box >
            </Box>
        </Box>)
}

TitleSlide.propTypes = {
    url: PropTypes.string,
    name: PropTypes.string,
    prepTime: PropTypes.number,
    cookTime: PropTypes.number,
    description: PropTypes.string,
    minServing: PropTypes.number,
    maxServing: PropTypes.number,
    serving: PropTypes.number,
    setServing: PropTypes.func,
    categories: PropTypes.instanceOf(Array),
    tags: PropTypes.instanceOf(Array),
    onStartCooking: PropTypes.func,
    allergens: PropTypes.instanceOf(Array),
    totalMacros: PropTypes.instanceOf(Object)
}

export default TitleSlide