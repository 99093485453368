function checkIfClouldinaryUrl(url) {
    return url ? url.search("res.cloudinary.com") > 0 : false
}

export function resizeImageIfCloudinary(url, crop_width, crop_height = -1, width = -1, height = -1) {
    if (checkIfClouldinaryUrl(url)) {
        const parts = url.split("/")
        const addstring = `w_${crop_width}${crop_height > 0 ? ",h_" + crop_height : ""},c_fill`
        const addString2 = addstring + (width > 0 ? `/w_${width},h_${height},c_fit` : "")
        const outputParts = parts.reduce((cumulate, part) => part === "upload" ? [...cumulate, part, addString2] : [...cumulate, part], [])
        return outputParts.join("/")
    }
    return url

}