import React from 'react'
import { Box } from '@mui/material'

const EmptyCard = () => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            width="45vh"
        >
            <Box
                style={{ height: "35vh", backgroundColor: "#d6d6d6" }}
            />
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
            >
                <Box style={{ height: "2.96vh", width: "15.84vh", backgroundColor: "#e4e4e4", margin: "2vh" }} />
                <Box style={{ height: "2.96vh", width: "10.56vh", backgroundColor: "#e4e4e4", margin: "2vh"  }} />
            </Box>
        </Box>
    )
}
const EmptyCards = () => {
    const numberOfCards = 14
    return (
        <>
            {[...Array(numberOfCards)].map((i, index) => <EmptyCard key={index}/>)}
        </>
    )
}

export default EmptyCards