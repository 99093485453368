import { Accordion, AccordionDetails, AccordionSummary, Typography, Box } from "@mui/material";
import React from "react";
import PropTypes from 'prop-types'

const AccordionDisplay = ({ title, details, button = null, openAccordion, setExpanded, status }) => {
    const color = status === "completed" ? "#B5D1C5" : "#ffffff"

    return (
        <Accordion expanded={openAccordion} onChange={setExpanded} style={{ borderRadius: "15px", backgroundColor: color, boxShadow: "none", margin: 0 }} >
            <AccordionSummary sx={{ padding: "0 10px" }}>
                <Box className="instruction-accordion left-align">
                    <Typography variant="h5">{title}</Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails >
                <Box className="instruction-accordion center-align" >
                    {details}
                    {button}
                </Box>
            </AccordionDetails>
        </Accordion >
    )
}

AccordionDisplay.propTypes = {
    title: PropTypes.string,
    details: PropTypes.element,
    button: PropTypes.element
}

export default AccordionDisplay