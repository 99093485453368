import { Box, Card, CardMedia, Skeleton, Typography } from "@mui/material"
import React from "react"
import PropTypes from "prop-types"
import { resizeImageIfCloudinary } from "../images/cloudinaryUtils";

const styles = {
  locked: {
    position: "relative",
    filter: "brightness(70%)",
    borderRadius: 0,
    boxShadow: "none"
  },
  unlocked: {
    position: "relative",
    borderRadius: 0,
    boxShadow: "none"
  }
}

const ListImage = ({ image = "", time, recipeLocked, loading }) => {
  const newUrl = resizeImageIfCloudinary(image, 1000, 800, 375, 300)
  return (
    <Card style={recipeLocked ? styles.locked : styles.unlocked}>
      {loading ? <Skeleton variant="rectangular" height={200} animation="wave" /> :
        <>
          <CardMedia
            component="img"
            height={250}
            src={newUrl}
            alt="recipe"
          />
          <Box style={{ position: "absolute", top: "88%", left: "72%", borderRadius: "5px", color: "black", width: "70px", height: "22px", backgroundColor: "#FFFFFF", textAlign: "center" }}>
            <Typography variant="card" style={{ textAlign: "center", fontSize: "18px" }}>{time} mins</Typography>
          </Box>
        </>
      }
    </Card>
  );
};

ListImage.propTypes = {
  image: PropTypes.string
}

export default ListImage;
//
