import React from "react"
import { Button } from "@mui/material"

const DelBtn = ({ onClick = () => { } }) => {
    return (
        <Button onClick={onClick}>
            Delete
        </Button>
    )
}

export default DelBtn