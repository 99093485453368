import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import { CircularProgress, Grid, Typography } from "@mui/material";
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from '../../firebase/context';
import { ErrorManagementContext } from '../../utils/errorManagement/ErrorManagementProvider';
import useRecipeFromDB from "../../utils/RecipeBackEnd/loadRecipe";
import DeleteRecipe from "./DeleteRecipe";
import MetadataEdit from "./MetadataEdit";
import StepsEdit from "./StepsEdit"
import PropTypes from 'prop-types'

const RecipeEditor2 = ({ recipeId, onDeleteCallback }) => {
  const [localRecipeJson, setLocalRecipeJson] = useState(null);
  const [openDeleteMessage, setOpenDeleteMessage] = useState(false)
  const [, loading, save, saving, deleteRecipe] = useRecipeFromDB(recipeId, result => setLocalRecipeJson(result))
  const { user } = useContext(AuthContext)
  const { cookClubLog } = useContext(ErrorManagementContext)

  const onSave = () => {
    cookClubLog("Saving Recipe", "RECIPE_SAVING", { localRecipeJson }, { ui: { type: "info" } })
    const recipeToSave = {
      ...localRecipeJson, metadata: {
        ...localRecipeJson.metadata,
        editor: user.displayName,
        lastEditedAt: Date()
      }
    }
    save(recipeToSave, () => cookClubLog("Recipe Saved", "RECIPE_SAVED", { localRecipeJson }, { ui: { type: "success" } }))
  }

  const onDelete = () => {
    deleteRecipe()
    handleCloseDeleteMessage(false)
    onDeleteCallback(recipeId)
  }

  const handleOpenDeleteMessage = () => {
    setOpenDeleteMessage(true)
  }

  const handleCloseDeleteMessage = () => {
    setOpenDeleteMessage(false)
  }

  if (!recipeId) return <Typography>No Recipe Selected</Typography>
  if (loading || !localRecipeJson) return <CircularProgress />
  const { metadata = null, steps = null } = localRecipeJson
  function onJsonPropertyChange(newJson, propId) {
    setLocalRecipeJson({
      ...localRecipeJson,
      [propId]: newJson
    })

  }
  return (<Grid container rowSpacing={2}>
    <Grid item>
      <Typography variant="h6">
        {metadata?.name ? metadata.name : "No Name Defined in Metadata"}
      </Typography>
    </Grid>
    <Grid item>
      <MetadataEdit
        objectData={metadata}
        onMetadataChange={newValue => onJsonPropertyChange(newValue, "metadata")}
      />
    </Grid>
    <Grid item>
      <StepsEdit
        localRecipeJson={localRecipeJson}
        stepsData={steps}
        onStepsArrayChange={newValue => onJsonPropertyChange(newValue, "steps")}
      />
    </Grid>
    <SpeedDial
      ariaLabel="SpeedDial basic example"
      sx={{ position: "fixed", left: "auto", right: "20px", top: "auto", bottom: "20px" }}
      icon={<SpeedDialIcon />}
    >
      <SpeedDialAction
        key={"save"}
        icon={saving ? <CircularProgress color="secondary"><SaveIcon /></CircularProgress> : <SaveIcon onClick={onSave} />}
        tooltipTitle={"save"}
      />
      <SpeedDialAction
        key={"display"}
        icon={saving ? <SlideshowIcon /> :
          <Link to={`/experience2/${recipeId}`} target="_blank" style={{ "text-decoration": "none" }}>
            <SlideshowIcon />
          </Link>
        }
        tooltipTitle={"display"}
      />
      <SpeedDialAction
        key={"delete"}
        icon={saving ? <DeleteIcon /> : <DeleteIcon onClick={handleOpenDeleteMessage} />}
        tooltipTitle={"delete"}
      />
    </SpeedDial>
    <DeleteRecipe
      onDelete={onDelete}
      handleCloseDeleteMessage={handleCloseDeleteMessage}
      openDeleteMessage={openDeleteMessage}
    />
  </Grid>
  );
}

RecipeEditor2.propTypes = {
  recipeId: PropTypes.string.isRequired,
  onDeleteCallback: PropTypes.func.isRequired
}

export default RecipeEditor2;
