import { Box } from '@mui/material'
import { ReactComponent as Dial } from "../../svg/dial-main.svg"
import Roundy from "../manualMode/Roundy/Roundy"
import React, {useState, useEffect} from 'react'

const SettingsEditor = ({icon, value, minValue, maxValue, onChange, onClick, stepSize, onlocalChange, setScroll, instructionCount}) => {
  const [localValue, setLocalValue] = useState(value)

  useEffect(() => {
    setLocalValue(value)
    if (instructionCount > 1) {
      setScroll("scroll")
    }
  }, [value])
  const arrowDegValue = (290/(maxValue-minValue))*(localValue-minValue)
  const handleLocalChange = (newValue) => { 
    setScroll("hidden")
    setLocalValue(newValue)
    onlocalChange(newValue)
  } 

  const handleChangeSettings = (value) => {
    onChange(value)
  }

  return (
    <Box onClick={onClick} sx={{position: "relative"}}>
      <Box sx={{transform: `rotate(${arrowDegValue}deg)`, transformOrigin: "50% 50%", position: "absolute", top: "4%", left: "4%"}}>
        <Dial />
      </Box>
      <Roundy
          value={value}
          min={minValue}                              
          max={maxValue}
          sliced={false}
          stepSize={stepSize}
          color="#FD8D3D"
          bgColor="#D9D9D9"
          onChange={value => handleLocalChange(value)}
          onAfterChange={({value, angle}) => handleChangeSettings(value)}
          arcSize={290}
          rotationOffset={-55}
          radius={105}
          strokeWidth={10}
          allowClick
      />
      <Box sx={{position: "absolute", top: "40%", left: "40%"}}>
        {icon}
      </Box>
    </Box>
  )
}

export default SettingsEditor