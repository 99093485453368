import { Checkbox, Grid, Stack, TextField, Typography } from "@mui/material";
import React from "react";

const UrlUtilityRender = ({ parameters, setParameters }) => {
  const { url, muted, duration, startTime, loop } = parameters;

  const onInputChange = (e, props, fieldType = "string") => {
    if (fieldType === "number") {
      setParameters({ ...parameters, [props]: e.target.valueAsNumber });
    } else if (fieldType === "boolean") {
      setParameters({ ...parameters, [props]: e.target.checked });
    } else {
      setParameters({ ...parameters, [props]: e.target.value });
    }
  };

  return (
    <Grid container>
      <Grid item xs={4}>
        <TextField
          label="Url Link"
          value={url}
          fullWidth
          onChange={(e) => onInputChange(e, "url", "string")}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          label={"DURATION"}
          value={duration}
          type="number"
          fullWidth
          onChange={(e) => onInputChange(e, "duration", "number")}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          label={"START TIME"}
          value={startTime}
          type="number"
          fullWidth
          onChange={(e) => onInputChange(e, "startTime", "number")}
        />
      </Grid>
      <Grid item xs={4}>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography>MUTED</Typography>
          <Checkbox checked={muted} onChange={(e) => onInputChange(e, "muted", "boolean")}/>
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography>LOOP</Typography>
          <Checkbox checked={loop} onChange={(e) => onInputChange(e, "loop", "boolean")}/>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default UrlUtilityRender;
