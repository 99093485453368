import { useEffect, useState } from 'react'
import { gql, useQuery } from '@apollo/client'

const GET_RECIPES = gql`
  query GetRecipes($active: Boolean = true) {
    Recipe_DB(where: {Active:{_eq:$active}},order_by:{Recipe_Name: asc}) {
      Recipe_ID
      Recipe_Name
      image_url
      Description
      Cooking_Time
      prep_time
      maxServing
      minServing
      hiddenTags
      cuisine
      Diet
      Active
    }
  }
`

const useRecipeListFromGQL = (active = true) => {
  const { loading, error, data, refetch } = useQuery(GET_RECIPES, { variables: { active } })
  const [recipeList, setRecipeList] = useState([])

  const pinRecipeTransformer = recipes => {
    const pinnedRecipe = recipes.filter(item => item?.metadata?.pin)
    const unpinnedRecipes = recipes.filter(item => !item?.metadata?.pin)
    return [...pinnedRecipe, ...unpinnedRecipes]
  }

  useEffect(() => {
    if (!loading) {
      setRecipeList(pinRecipeTransformer(data?.Recipe_DB ? data.Recipe_DB.map(recipeQL => ({
        id: recipeQL.Recipe_ID,
        metadata: {
          name: recipeQL.Recipe_Name,
          cookTime: recipeQL.Cooking_Time,
          url: recipeQL.image_url,
          prepTime: recipeQL.prep_time,
          active: recipeQL.Active,
          tags: recipeQL.cuisine,
          maxServing: recipeQL.maxServing,
          minServing: recipeQL.minServing,
          categories: recipeQL.Diet,
          hiddenTags: recipeQL.hiddenTags
        }
      })) : []))
    }
  }, [loading, data])

  return { recipeList, error, loading, refresh: refetch }
}

export default useRecipeListFromGQL