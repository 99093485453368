import React, { createContext, useState } from 'react'

export const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const [options, setOptions] = useState({
    categories: [],
    tags: [],
  })

  return (
    <FilterContext.Provider value={[options, setOptions]}>
      {children}
    </FilterContext.Provider>
  )
}
