import React from "react"
import { Checkbox } from "@mui/material"
import PropTypes from "prop-types"

const BooleanField = ({ name, onChange, required = false, data, delBtn = null }) => {
    return (<Checkbox
        key={name}
        name={name}
        checked={data}
        onChange={onChange}
        inputProps={{ 'aria-label': 'primary checkbox' }}
    />)
}

BooleanField.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    data: PropTypes.bool,
    delBtn: PropTypes.element
}

export default BooleanField