import React from "react"
import { Box, IconButton } from "@mui/material"
import PropTypes from "prop-types"
import RecommendRoundedIcon from '@mui/icons-material/RecommendRounded';

const ThumbsRating = ({ onSelectRating, subStepId }) => {
    return (<Box
        key={subStepId}
    >
        <IconButton value={1} onClick={onSelectRating}>
            <RecommendRoundedIcon style={{ color: "#00FF00", fontSize: 100 }} />
        </IconButton>
        <IconButton style={{ transform: "rotate(180deg)" }} value={0} onClick={onSelectRating}>
            <RecommendRoundedIcon style={{ color: "#FF0000", fontSize: 100 }} />
        </IconButton>
    </Box >)
}

ThumbsRating.propTypes = {
    onSelectRating: PropTypes.func,
    subStepId: PropTypes.string
}

export default ThumbsRating