import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { ReactComponent as Time } from "../../svg/Time1.svg"
import { ReactComponent as Temperature } from "../../svg/Temperature1.svg"
import { ReactComponent as Speed } from '../../svg/BladeSpeed1.svg';
import { ReactComponent as DeleteIcon } from "../../svg/delete.svg"
import { getTime } from '../../utils/experience2/stepsUtils';


const SettingDetails = ({ setting, onDelete, expanded, index, instructionCount }) => {
  const { time, temperature, speed, instructionName } = setting
  const timeformat = getTime(time)
  const speedFormat = speed === 13 ? "Stir" : speed
  const settings = [
    {value: temperature, icon:<Temperature style={{height: "40px", width: "40px"}}/>},
    {value: timeformat, icon:<Time style={{height: "40px", width: "40px"}}/>},
    {value: speedFormat, icon:<Speed style={{height: "40px", width: "40px"}}/>}
  ]

  return (
    <Grid container>
      <Grid item xs={5} display="flex" alignItems="center">
        <Box display="flex" alignItems="center">
          <Typography variant="h5" sx={{marginLeft: "20px"}}>{instructionName}</Typography>
        </Box>
      </Grid>
      {!(expanded===index) && <><Grid item xs={6} sx={{backgroundColor: "#FFE8D8", borderRadius: "10px", padding: "7px"}}>
        <Stack spacing={1} direction="row" justifyContent="space-between">
          {settings.map(({value, icon}) => (
            <Stack key={value} direction="row" spacing={1}>   
              {icon}
              <Typography variant='h5'>{value}</Typography>
            </Stack>
          ))}
        </Stack>
      </Grid>
      <Grid item xs display="flex" justifyContent="center" alignItems="center">
        {instructionCount!==1 && <DeleteIcon sx={{margingLeft: 5}} onClick={onDelete}/>}
      </Grid></>}
  </Grid>
  )
}

export default SettingDetails