import React from "react";
import { Button, Typography } from "@mui/material";
import PropTypes from "prop-types"

const IngredientsClass = ({ value, text, onClickBtn, btnClass, display }) => {
    return (
        <>
            {display ? <Button
                value={value}
                onClick={onClickBtn}
                className={btnClass}
            >
                <Typography variant="body2">{text}</Typography>
            </Button> : null}
        </>
    )
}

IngredientsClass.propTypes = {
    value: PropTypes.string,
    text: PropTypes.string,
    onClickBtn: PropTypes.func,
    btnClass: PropTypes.string,
    display: PropTypes.bool
}

export default IngredientsClass