import useBluetoothForHardwareAndroid from './bluetoothInterfaceAndroid'
import useBluetoothForHardwareChrome from './bluetoothInterface'

import { useState, useEffect, useCallback } from 'react'
import { defaultServiceUUID, defaultCharacteristicUUIDMain, defaultCharacteristicUUIDWeight, defaultCharacteristicUUIDWrite, defaultCharacteristicUUIDJar, defaultCharacteristicUUIDTemp } from './bleServiceAndCharUUIDs'

import { deviceShadowStream } from '../../bytebeamClient'

const initBufferValue = []

const interpretValue = x => {
    if (!x)
        return ({
            status: "disconnected",
            bladeSpeed: 99,
            temperature: 0,
            weight: 0,
            time: 0,
            raw: "No Communication"
        })
    const statusMap = {
        "9": "idle",
        "8": "pause",
        "7": "bladeset",
        "6": "operation",
        "5": "bladeDone",
        "4": "heatingDone",
        "1": "cooldown"
    }
    const statusString = x.charAt(1)
    const bladeSpeedString = x.substr(5, 2)
    const temperatureString = x.substr(2, 3)
    const weightString = x.substr(11, 4)
    const timeString = x.substr(7, 4)
    return ({
        status: statusMap[statusString],
        bladeSpeed: Number(bladeSpeedString),
        temperature: Number(temperatureString),
        weight: 5000 - Number(weightString),
        time: Number(timeString),
        raw: x
    })
}

const checkJarFunctionality = (value) => {
    if (value === "0")
        return "on"
    else if (value === "1")
        return "off"
    else
        return "loading"
}

const useHardware = (hwVersion = "2.0", autoConnectDevice = "any") => {

    const useBluetooth = navigator.userAgent === "Reactlabs" ? useBluetoothForHardwareAndroid : useBluetoothForHardwareChrome;

    const [connect, write, hardwareString, connected, disconnect, weightString, tempString, jarString] = useBluetooth(
        { autoConnect: autoConnectDevice }, defaultServiceUUID, defaultCharacteristicUUIDWrite,
        defaultCharacteristicUUIDMain, defaultCharacteristicUUIDWeight, defaultCharacteristicUUIDTemp, defaultCharacteristicUUIDJar
    )
    const hardwareState = interpretValue(hardwareString)
    const [hardwareStateVirtual, setHardwareStateVirtual] = useState({ status: hardwareState.status })
    const [instructionBuffer, setInstructionBuffer] = useState(initBufferValue)
    const weight = 5000 - Number(weightString)
    const jarOn = checkJarFunctionality(jarString && jarString.charAt(1))
    const lidOn = checkJarFunctionality(jarString && jarString.charAt(0))
    const temperature = tempString ? Number(tempString.substr(0, 3)) : 0
    const resistanceAtSensor = tempString ? Number(tempString.substr(3, 3)) : 0
    const dutyCycle = tempString ? Number(tempString.substr(6, 3)) : 0
    const heating = tempString ? Number(tempString.substr(9, 1)) : 0

    const sendSeriesInstruction = useCallback(instructionSeries => {
        const sendBasicInstruction = (time, temp, speed, futureInstructions = initBufferValue) => {
            const tempStr = temp <= 20 ? "000" : temp.toString().padStart(3, "0")
            const timeStr = time.toString().padStart(4, "0")
            const bladeStr = speed.toString().padStart(2, "0")
            const sendString = `S11${tempStr}${timeStr}${bladeStr}${timeStr}$E`
            const totalTimeLeft = futureInstructions.reduce((totTime, { time: stepTime }) => totTime + stepTime, time)
            write(sendString)
            setHardwareStateVirtual(old => ({ ...old, status: "starting", lastTime: totalTimeLeft }))
        }
        const [firstInstruction, ...rest] = instructionSeries
        setInstructionBuffer(rest)
        sendBasicInstruction(firstInstruction.time, firstInstruction.temperature, firstInstruction.speed, rest)
    }, [write])

    const triggerR1 = useCallback(r1Settings => {
        const { type, data } = r1Settings
        if (type === "serial") {
            const checkedData = data.filter(i => i.time !== 0)
            sendSeriesInstruction(checkedData)
        }
        else {
            sendSeriesInstruction([data])
        }
    }, [sendSeriesInstruction])

    useEffect(() => {
        if (hardwareState.status === "operation") {
            setHardwareStateVirtual(old => ({ ...old, status: "operation" }))
            return
        }
        else if (hardwareState.status === "idle") {
            if (instructionBuffer.length > 0)
                sendSeriesInstruction(instructionBuffer)
            else
                setHardwareStateVirtual(old => ({ ...old, status: "idle" }))
            return
        }
        else if (hardwareStateVirtual.status === "operation")
            setHardwareStateVirtual(old => ({ ...old, status: "cooldown" }))
    }, [hardwareState.status, sendSeriesInstruction])

    const forceStop = useCallback(() => {
        write("T")
        setHardwareStateVirtual(old => ({ ...old, status: "Force Stop" }))
        setInstructionBuffer(initBufferValue)
    }, [write])

    const pauseR1 = useCallback(() => {
        const pauseString = "P"
        write(pauseString)
        setHardwareStateVirtual(old => ({ ...old, status: "Paused" }))
    }, [write])

    const resumeR1 = useCallback(() => {
        const resumeString = "R"
        write(resumeString)
        setHardwareStateVirtual(old => ({ ...old, status: "operation" }))
    }, [write])


    const [lastPushTime, setLastPushTime] = useState(new Date().valueOf());
    const currentTime = new Date().valueOf();

    if (currentTime - lastPushTime > 100) {
        deviceShadowStream.publish({
            Status: hardwareStateVirtual.status,
            weight: weight,
            temperature: temperature,
        })

        setLastPushTime(currentTime)
    }

    return ({
        connectToHardware: connect,
        triggerR1,
        stateOfHardware: hardwareStateVirtual.status,
        connected,
        forceStop,
        disconnect,
        weight,
        jarOn,
        lidOn,
        temperature,
        resistanceAtSensor,
        heating,
        dutyCycle,
        time: hardwareStateVirtual.lastTime - hardwareState.time,
        pauseR1,
        resumeR1
    })
}

export default useHardware
