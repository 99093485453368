import { Typography, Grid, Box, Button } from "@mui/material"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import React, { useContext, useState } from "react"
import { ReactComponent as ManualModeIcon } from "../../svg/cardIcons/manualmode.svg"
import { ReactComponent as SelfCleanIcon } from "../../svg/cardIcons/selfClean.svg"
import { ReactComponent as SearchIcon } from "../../svg/cardIcons/searchIcon.svg"
import Search from "../../utils/homepage/page/Search"
import GlobalSettingsButton from "../../utils/globalSettings/GlobalSettingsButton"
import useRecipeListFromGQL from "../../utils/RecipeBackEnd/loadRecipeListGQL"
import { FilterContext } from "../../utils/homepage/FilterContext"
import Filter from "../../utils/homepage/page/Filter";
import { useHistory } from "react-router-dom"
const iconPath = process.env.PUBLIC_URL + '/icons/'

const styles = {
  width: "100%",
  backgroundColor: "#fefefe",
  color: "#000",
  padding: "2px"
}

const boxStyle = {
  display: "flex", 
  flexDirection: "row", 
  alignItems: "center", 
  justifyContent: "center",
  border: "1px solid #6D6D6D", 
  width: "80px",
  padding: "5px 10px",
  borderRadius: "5px",
}


const buttonStyles = {
  textTransform: "none",
  padding: "2px",
  color: "white",
  backgroundColor: "black",
  cursor: "pointer"
}

const Navbar = ({onFilterChange}) => {
  const [openSearch, setOpenSearch] = useState(false);
  const [settingsDrawerOpen, setSettingsDrawerOpen] = useState(false)
  const [secretMessage, setSecretMessage] = useState(null);
  const { recipeList: activeRecipes, loading } = useRecipeListFromGQL()
  const [filterOptions, setFilterOptions] = useContext(FilterContext);
  const [openFilterPage, setOpenFilterPage] = useState(false);
  const [showClearFilter, setShowClearFilter] = useState("none");
  // const classes = useStyles({ showClearFilter });
  const history = useHistory()

  const handleClearFilter = () => {
    onFilterChange(activeRecipes)
    resetFilter()
    setShowClearFilter("none")
  }

  const resetFilter = () => {
    setFilterOptions({ ...filterOptions, categories: [], tags: [] })
  }

  const getTags = (field) => {
    const uniqueTags = []
    const temp = activeRecipes.map(recipe => recipe?.metadata[field]).filter(i => i !== undefined)
    temp.forEach(i => {
      i.forEach(v => {
        if (!uniqueTags.includes(v)) {
          uniqueTags.push(v)
        }
      })
    })
    return uniqueTags
  }

  const handleFilter = () => {
    const filteredData = activeRecipes.filter((list) => {
      const tags = list?.metadata?.tags || []
      const categories = list?.metadata?.categories || []
      const filterCategories = categories.find(i => filterOptions?.categories.includes(i))
      const filterTags = tags.find(i => filterOptions?.tags.includes(i))
      return tags.includes(filterTags) || categories.includes(filterCategories)
    })
    onFilterChange(filteredData)
    setOpenFilterPage(false)
    setShowClearFilter("block")
  }

  // if (loading) <CircularProgress />

  return (
    <AppBar position="sticky" style={styles}>
      <Toolbar>
        <Grid container alignItems="center">
          <Grid item xs={5}>
            <Box onClick={() => setOpenSearch(true)} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", border: "1px solid #828282", borderRadius: "18px", padding: "5px 40px 7px 20px", margin: "auto"}}>
                <SearchIcon/>
                <Typography variant="cardLight" sx={{fontSize: "20px", marginLeft: "10px"}} >Search from recipes...</Typography>
            </Box>
          </Grid>
          <Grid item xs={3}/>
          <Grid item xs={2} >
            <Box onClick={() => history.push('/driveHardware2/')} style={boxStyle}>
              <Box sx={{marginTop: "3px"}}>
                <ManualModeIcon />
              </Box>
              <Typography style={{textAlign: "start", marginLeft: "10px", fontSize: "14px"}} variant="card">Manual Mode</Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box onClick={() => history.push('/experience2/oqAfLQMJ8z8hk9uikOlX')} style={{...boxStyle, width: "76px"}}>
              <Box sx={{marginTop: "2px"}} >
                <SelfCleanIcon />
              </Box>
              <Typography sx={{textAlign: "start", marginLeft: "10px", fontSize: "14px"}} variant="card">Self Clean</Typography>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
      <GlobalSettingsButton drawerOpen={settingsDrawerOpen} setDrawerOpen={setSettingsDrawerOpen} />
      <Search open={openSearch} onClose={() => setOpenSearch(false)} setDrawerOpen={setSettingsDrawerOpen} secretMessage={secretMessage} setSecretMessage={setSecretMessage} />
      <Filter open={openFilterPage} getSelectOptions={getTags} onClose={() => setOpenFilterPage(false)} resetFilter={resetFilter} handleFilter={handleFilter} />
      <Box
        width="100%"
        position="fixed"
        style={{ display: "flex", justifyContent: "center", left: '40%', bottom: 30, backgroundColor: "black", borderRadius: "5px", width: "30vw" }}
      >
        <Button sx={{...buttonStyles,display: showClearFilter}} onClick={handleClearFilter}>CLEAR FILTERS</Button>
      </Box>
    </AppBar>
  );
};

export default Navbar;
