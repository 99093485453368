import React, { useContext, useEffect, useRef, useState } from 'react'
import { ThemeProvider } from "@mui/material";
import ccTheme from '../theme/Experience2Theme';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import HardwareBar from "../components/hardwareBar/HardwareBar"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Global } from '@emotion/react';
import Divider from '@mui/material/Divider';
import { StateOfHardwareContext, TimeContext } from "../components/hardwareBar/HardwareTasks";
import { DEFAULT_SETTINGS } from '../components/manualMode/InitialSettings';
import SettingDetails from "../components/manualMode/SettingDetails"
import SettingDisplay from '../components/manualMode/SettingDisplay';
import PresetDrawer from '../components/manualMode/PresetDrawer';
import { getTime } from '../utils/experience2/stepsUtils'
import { handleInstructionNames, containsPresetInstructions } from '../components/manualMode/utils';
import { R1Button } from '../components/manualMode/ManualButtons';
import AlertDialogBox from '../components/manualMode/AlertDialogBox';
import { errorUncontrolledIssueUrl, generalTapUrl } from '../audioUrls/audioUrl';
import useCookClubAudio from '../utils/audio/cookClubAudio';

const TimerProgress = ({ totalTime, setStartProgressBar }) => {
  const stateOfHardware = useContext(StateOfHardwareContext)
  const time = useContext(TimeContext)
  const timeElapsed = Math.sign(time) === 1 && 100 - (time / totalTime) * 100
  useEffect(() => {
    if (stateOfHardware === "idle") {
      setStartProgressBar(false)
    }
  }, [stateOfHardware])

  return (
    <Box sx={{ width: "80%", position: "absolute", bottom: "25%", left: "13%" }}>
      {["operation", "cooldown", "starting"].includes(stateOfHardware) &&
        <>
          <LinearProgress variant='determinate' value={timeElapsed} color="success" sx={{ height: "20px", borderRadius: "10px", backgroundColor: "#D3D3D3" }} />
          <Typography variant="h5" color="secondary" textAlign="right">{Math.sign(time) === 1 ? getTime(time) : getTime(totalTime)}</Typography>
        </>
      }
    </Box>
  )
}

const presetBtnStyle = {
  enabled: { backgroundColor: "#E0DDDD", color: "#000", borderRadius: "8px", width: "20%" },
  disabled: { border: "#DCDADA", backgroundColor: "#DCDADA", color: "#ADA9A9", borderRadius: "8px", width: "20%" }
}

const HardwareDrive3 = () => {
  const [settings, setSettings] = useState(DEFAULT_SETTINGS)
  const [presetDrawerOpen, setPresetDrawerOpen] = useState(false)
  const [alertDialog, setAlertDialog] = useState(false)
  const [expandAccordion, setExpandAccordion] = useState(0)
  const [startProgressBar, setStartProgressBar] = useState(false)
  const [stateOfOperation, setStateOfOperation] = useState('')
  const [scroll, setScroll] = useState("none")
  const [removePresetSound] = useCookClubAudio(errorUncontrolledIssueUrl)
  const [generalTapSound] = useCookClubAudio(generalTapUrl)
  const instructionCount = settings.length
  const scrollRef = useRef(null)

  const totalTime = settings.reduce((prev, next) => prev + next.time, 0)

  const handleChangeSettings = (newSetting, index) => {
    const newSettings = settings.map((v, i) => index === i ? newSetting : v)
    const setInstructionName = handleInstructionNames(newSettings)
    const finalSettings = containsPresetInstructions(newSettings) ? newSettings : setInstructionName

    setSettings(finalSettings)
  }

  const changePresetSettings = (newSettings) => {
    setSettings(newSettings)
    if (newSettings.length > 1) {
      setScroll("scroll")
    }
    setPresetDrawerOpen(false)
  }

  const onScroll = (e, index, scrollX) => {
    scrollRef.current.scrollTo({ top: scrollX * index, behaviour: "smooth" })
  }

  const onAdd = (e, index) => {
    if (stateOfOperation !== "operation") {
      setExpandAccordion(index)
      onScroll(scrollRef, index, 70)
      setScroll("scroll")
      const newSettings = [...settings, { time: 5, temperature: "20", speed: 0, instructionName: `Instruction ${settings.length + 1}` }]
      const setInstructionName = handleInstructionNames(newSettings)
      const finalSettings = containsPresetInstructions(newSettings) ? newSettings : setInstructionName
      setSettings(finalSettings)
    }
  }

  const onDelete = (e, editIndex) => {
    e.stopPropagation()
    if (instructionCount === 2) {
      setExpandAccordion(0)
      setScroll("none")
    }
    if (stateOfOperation !== "operation") {
      const newSettings = settings.filter((i, index) => index !== editIndex)
      setSettings(newSettings)
    }
  }

  const changeDialogBoxState = () => {
    setExpandAccordion(false)
    setStartProgressBar(true)
  }

  const handleExpanded = (index) => (e, isExpanded) => {
    if (stateOfOperation === "operation") {
      setExpandAccordion(false)
    } else {
      setExpandAccordion(isExpanded ? index : false)
    }
  }

  return (
    <ThemeProvider theme={ccTheme}>
      <Grid onClick={() => generalTapSound()} container height="100vh" sx={{ overflowX: "none", zIndex: 2 }}>
        <Grid item xs={1.3}>
          <HardwareBar />
        </Grid>
        <Grid item xs={10.7}>
          {/* Navbar Section (remains fixed for all settings) */}
          <Box
            display="flex"
            sx={{
              padding: "0 1%",
              display: "flex",
              backgroundColor: "#ffffff",
              zIndex: 2,
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              boxSizing: "border-box",
              height: "10vh"
            }}
          >
            {/* Opens the preset settings drawer */}
            <Button
              style={stateOfOperation === "operation" ? presetBtnStyle.disabled : presetBtnStyle.enabled}
              onClick={() => { stateOfOperation !== "operation" && setPresetDrawerOpen(true) }}
              disabled={stateOfOperation === "operation"}
            >
              <Typography variant="h5">Presets</Typography>
            </Button>
            <Typography variant="h5" marginRight="10%">Manual Mode</Typography>
            <Box marginLeft={10}></Box>
          </Box>
          {/* Shows the Dials Settings */}
          <Box ref={scrollRef} sx={{ overflowY: scroll, height: stateOfOperation === "operation" ? "50vh" : "65vh" }}>
            {settings.map((setting, index) => (
              <Accordion key={index} defaultExpanded={true} expanded={expandAccordion === index} onChange={handleExpanded(index)} >
                <AccordionSummary sx={{ margin: 0 }}>
                  <SettingDetails index={index} instructionCount={instructionCount} expanded={expandAccordion} setting={setting} onDelete={(e) => onDelete(e, index)} />
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <SettingDisplay setting={setting} onChangeSettings={(newSetting) => handleChangeSettings(newSetting, index)} setScroll={setScroll} instructionCount={instructionCount} />
                </AccordionDetails>
              </Accordion>))}
            {startProgressBar && <TimerProgress totalTime={totalTime} setStartProgressBar={setStartProgressBar} />}
          </Box>
          {/* Renders the add settings button */}
          {stateOfOperation !== "operation" && <Box sx={{ position: "fixed", bottom: 60, left: 0 }}>
            <Divider variant="inset" textAlign="right" sx={{ position: "fixed", bottom: "17%", right: 0, width: "90vw" }}>
              <IconButton onClick={(e) => onAdd(e, settings.length)}>
                <AddCircleOutlineIcon sx={{ fontSize: '40px', fontWeight: 200, color: "#043F98" }} />
              </IconButton>
            </Divider>
          </Box>}
          {/* Renders the R1Buttons */}
          <Box display="flex" sx={{ gap: "20px", justifyContent: "center", width: "70%", position: "fixed", bottom: "9%", left: "15%" }}>
            <R1Button instructionCount={instructionCount} setStateOfOperation={setStateOfOperation} settings={settings} changeDialogBoxState={changeDialogBoxState} />
            {containsPresetInstructions(settings) && stateOfOperation !== "operation" &&
              <Button
                className="pause-btn r1-btn"
                onClick={() => { setAlertDialog(true); removePresetSound() }}>
                <Typography variant="h5">Clear Presets</Typography>
              </Button>}
          </Box>
          <Global
            styles={{
              '.MuiDrawer-root > .MuiPaper-root': {
                overflow: 'visible',
              },
            }}
          />
          {/* PresetDrawer to select the manualmode settings */}
          <PresetDrawer
            open={presetDrawerOpen}
            stateOfOperation={stateOfOperation}
            settings={settings}
            onChangeSettings={(e) => changePresetSettings(e, settings)}
            onClose={() => setPresetDrawerOpen(false)}
          />
          <AlertDialogBox open={alertDialog} onClose={() => setAlertDialog(false)}
            onClick={() => {
              setSettings(DEFAULT_SETTINGS)
              setAlertDialog(false)
            }}
            title="Presets will be removed"
            bodyText1="Are you sure you want to clear all presets?"
            bodyText2=""
            leftBtn="Proceed"
          />
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}

export default HardwareDrive3