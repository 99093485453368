import React from "react"
import { Button } from "@mui/material"
import PropTypes from "prop-types"

const ButtonItem = ({ name, onClick }) => {
    return (<Button
        onClick={onClick}
        style={{ width: "100%" }}
    >
        {name}
    </Button>)
}

ButtonItem.propTypes = {
    name: PropTypes.string,
    onClick: PropTypes.func
}

export default ButtonItem