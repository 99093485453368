export const startUpSoundUrl = "https://res.cloudinary.com/richeek/video/upload/v1658214663/startup_cgstza.mp3"
export const startSoundUrl = "https://res.cloudinary.com/prodjour/video/upload/v1657946270/sounds/Start-r1_fzu9hz.mp3";
export const endSoundUrl = "https://res.cloudinary.com/prodjour/video/upload/v1657946385/sounds/R1-timer-ends_niranz.mp3"
export const lidOnVideoUrl = "https://res.cloudinary.com/richeek/video/upload/v1652961551/Lid-how_bgixxp.mp4"
export const timerEndUrl = "https://res.cloudinary.com/richeek/video/upload/v1658236174/R1-timer-ends_cfnsus.mp3"
export const resumeSoundUrl = "https://res.cloudinary.com/richeek/video/upload/v1658234830/resume-is-tapped_ceaaqd.wav"
export const jarOnUrl = "https://res.cloudinary.com/richeek/video/upload/v1658312592/container-in_gl0rna.mp3"
export const lidOnUrl = "https://res.cloudinary.com/richeek/video/upload/v1658217954/lid-on_b38hnj.mp3"
export const containerOffUrl = "https://res.cloudinary.com/richeek/video/upload/v1658217869/lid-off-and-or-container-off_fmz68i.mp3";
export const weighingScaleUrl = "https://res.cloudinary.com/richeek/video/upload/v1658220503/ding-weighing-scale_bvxocx.mp3"
export const errorUncontrolledIssueUrl = "https://res.cloudinary.com/richeek/video/upload/v1658235944/error-controlled-issue_qrpwts.wav"
export const generalTapUrl = "https://res.cloudinary.com/richeek/video/upload/v1658322769/general-tap_zgfehm.mp3"
export const fiveStarRatingSoundUrl = "https://res.cloudinary.com/richeek/video/upload/v1658322840/5-star_lowxks.wav"
export const bluetoothConnectUrl  = "https://res.cloudinary.com/richeek/video/upload/v1659164848/bluetooth-alternate-1_zppubu.mp3"