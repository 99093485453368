import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useParams, useHistory } from "react-router-dom"
import Loader from './Loader'
import HomeIcon from '@mui/icons-material/Home';
import { AppBar, Toolbar, Typography } from '@mui/material'
import IngredientsList from '../components/ingredients2/IngredientsList'
import { getAllRecipeIngredients } from '../utils/experience2/ingredientsUtil'
import SecureArea from '../components/authentication/SecureArea'
import { IngredientsFromDB, IngredientsContext } from '../components/ingredients2/IngredientsFromDB'
import useRecipeFromDB from '../utils/RecipeBackEnd/loadRecipe';

const RecipeDispWrapper = ({ recipe, ingredientsOnly = false }) => {
    const { ingredients } = useContext(IngredientsContext)
    return (<>
        <PageTitle
            recipeName={recipe?.metadata?.name}
            description={recipe?.metadata?.description}
            url={recipe?.metadata?.url}
        />
        <IngredientsList
            ingredientsOnlyStyle={ingredientsOnly && "ingredients-only-margin"}
            items={getAllRecipeIngredients(recipe.steps, ingredients)}
            ingredients={ingredients}
            ingredientsOnly
            height={50}
        />
    </>)
}

const PageTitle = ({ recipeName, description, url }) => {
    const history = useHistory()

    return (
        <AppBar sx={{
            width: "100%",
            backgroundColor: "#fefefe",
            color: "#000",
            position: "static",
        }}>
            <HomeIcon sx={{ margin: "15px" }} onClick={() => history.push('/recipelist')} />
            <Toolbar sx={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                <Typography textAlign="left">{recipeName} </Typography>
                <img style={{ objectFit: "cover" }} src={url} alt="" height={"100vh"} width={"100%"} />
                <Typography>{description}</Typography>
            </Toolbar>
        </AppBar>
    )
}

const RecipeDisp = ({ ingredientsOnly = false }) => {
    const { recipeId: urlRecipeId } = useParams()
    const [recipe, loading] = useRecipeFromDB(urlRecipeId)

    if (loading)
        return <Loader />
    if (ingredientsOnly)
        return (
            <SecureArea>
                <IngredientsFromDB>
                    <RecipeDispWrapper recipe={recipe} ingredientsOnly={ingredientsOnly} />
                </IngredientsFromDB>
            </SecureArea >
        )


}

RecipeDisp.propTypes = {
    adminOnly: PropTypes.bool,
    ingredientsOnly: PropTypes.bool,
}

export default RecipeDisp
