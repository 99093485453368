import React from "react"
import { Typography, Button } from "@mui/material"
import PropTypes from "prop-types";

const RecenterBtn = ({ onClick, view }) => {
    return (
        <Button
            onClick={onClick}
            style={{
                border: " 2px solid #043F98",
                backgroundColor: "#ffffff",
                borderRadius: 50,
                padding: "21px 70px"
            }}>
            {view === "big" ?
                <Typography variant="caption" style={{ color: "#043F98" }}>Recenter</Typography> :
                <Typography variant="caption" style={{ color: "#043F98" }}>R</Typography>}
        </Button>
    )
}

RecenterBtn.propTypes = {
    onClick: PropTypes.func,
    view: PropTypes.string
}

export default RecenterBtn