import React, { useState } from "react";
import { Typography } from "@mui/material";
import AccordionDisplay from "../AccordianDisplay";
import RatingsDisplay from "./RatingDisplay";
import PropTypes from "prop-types"

const ratingTextType = {
    text1: "hated it",
    text2: "not nice",
    text3: "was okay",
    text4: "liked it",
    text5: "loved it",
    default: null
}

const RatingCollectorType = ({ parameters, title, subStepId }) => {
    const [open, setOpen] = useState(true)
    const [displayRatingText, setDisplayRatingText] = useState(null)

    const changeRatingDisplayText = (rating) => {
        const { iconType } = parameters
        if (iconType === "star5" || iconType === "heart5" || iconType === "satisFaction") {
            setDisplayRatingText(ratingTextType[`text${rating}`])
        }
        else if (iconType === "star10" || iconType === "heart10") {
            setDisplayRatingText(ratingTextType[`text${Math.ceil(rating / 2)}`])
        }
        else if (iconType === "thumbs") {
            rating === 0 ? setDisplayRatingText(ratingTextType["text1"]) : setDisplayRatingText(ratingTextType["text5"])
        }
        else
            setDisplayRatingText(ratingTextType.default)
    }

    return (<AccordionDisplay
        title={title}
        openAccordion={open}
        setExpanded={() => setOpen(old => !old)}
        details={<>
            {parameters?.displayText && <Typography variant="h5">{parameters.displayText}</Typography>}
            {displayRatingText && <Typography>{displayRatingText}</Typography>}
            <RatingsDisplay
                iconType={parameters.iconType}
                ratingType={parameters?.ratingType}
                setDisplayRatingText={rating => changeRatingDisplayText(rating)}
                displayText={parameters?.displayText}
                subStepId={subStepId} />
        </>}
    />)
}

RatingCollectorType.propTypes = {
    parameters: PropTypes.instanceOf(Object),
    title: PropTypes.string,
    subStepId: PropTypes.string
}

export default RatingCollectorType