import { v4 as uuid } from "uuid"

export const onAddArrayElement = (onStepsChange, stepsData, defaultAddJson, index, type) => {
  const arr1 = stepsData.splice(0, index+1)
  const arr2 = stepsData.splice(0, stepsData.length)
  if (type === "step") {
    onStepsChange([...arr1, {
      ...defaultAddJson, id: uuid(),
      subSteps: [...defaultAddJson.subSteps.map(i => {return { ...i, id: uuid()}})] 
    }, ...arr2]);
  } else if (type === "substep") {
    onStepsChange([...arr1, {
      ...defaultAddJson, id: uuid(),
    }, ...arr2]);
  } else if (type === "setting") {
    onStepsChange([...arr1, defaultAddJson, ...arr2])
  }
}
