import React, { useState, useContext, useEffect } from "react"
import { Button, Typography, Box } from "@mui/material"
import TimeTemperaturePlotting from "./TimeTemperaturePlotting"
import { ReactComponent as Temperature20 } from "../../svg/hardwareBar/temperaturedials/temperature20.svg"
import { ReactComponent as Temperature45 } from "../../svg/hardwareBar/temperaturedials/temperature45.svg"
import { ReactComponent as Temperature70 } from "../../svg/hardwareBar/temperaturedials/temperature70.svg"
import { ReactComponent as Temperature90 } from "../../svg/hardwareBar/temperaturedials/temperature90.svg"
import { ReactComponent as Temperature105 } from "../../svg/hardwareBar/temperaturedials/temperature105.svg"
import { ReactComponent as Temperature20active } from "../../svg/hardwareBar/temperaturedials/temperature20active.svg"
import { ReactComponent as Temperature45active } from "../../svg/hardwareBar/temperaturedials/temperature45active.svg"
import { ReactComponent as Temperature70active } from "../../svg/hardwareBar/temperaturedials/temperature70active.svg"
import { ReactComponent as Temperature90active } from "../../svg/hardwareBar/temperaturedials/temperature90active.svg"
import { ReactComponent as Temperature105active } from "../../svg/hardwareBar/temperaturedials/temperature105active.svg"
import { HardwareContext, StateOfHardwareContext, TimeContext } from "./HardwareTasks"

const TemperatureSvgIcon = ({ temperature, active }) => {
    if (active) {
        if ((temperature > 24 && temperature < 48) || temperature === 0) {
            return <Temperature20active />
        } else if (temperature >= 48 && temperature < 72) {
            return <Temperature45active />
        } else if (temperature >= 72 && temperature < 96) {
            return <Temperature70active />
        } else if (temperature >= 96 && temperature < 120) {
            return <Temperature90active />
        } else if (temperature >= 120 && temperature < 144) {
            return <Temperature105active />
        } else {
            return <Temperature20active />
        }
    } else {
        if ((temperature > 24 && temperature < 48) || temperature === 0) {
            return <Temperature20 />
        } else if (temperature >= 48 && temperature < 72) {
            return <Temperature45 />
        } else if (temperature >= 72 && temperature < 96) {
            return <Temperature70 />
        } else if (temperature >= 96 && temperature < 120) {
            return <Temperature90 />
        } else if (temperature >= 120 && temperature < 144) {
            return <Temperature105 />
        } else {
            return <Temperature20 />
        }
    }

}


const temperatureASCII = "\u02DA"

const TemperatureIcon = () => {
    const { temperature, dutyCycle, heating } = useContext(HardwareContext)
    const stateOfHardware = useContext(StateOfHardwareContext)
    const time = useContext(TimeContext)
    const [timerDataList, setTimerDataList] = useState([])
    const [dialogOpen, setDialogOpen] = useState(false)

    useEffect(() => {
        /* To Clear the previously stored array */
        if (stateOfHardware === "starting") {
            setTimerDataList([])
        } else {
            setTimerDataList(old => [...old, { time, currentTime: new Date(), temperature, heating, dutyCycle }])
        }
        /* To record the temperature change */
        // if (stateOfHardware === "operation") {
        // }
    }, [stateOfHardware, time, temperature, heating, dutyCycle])

    return (
        <>
            <Button onClick={() => setDialogOpen(true)} sx={{ color: "black" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <TemperatureSvgIcon temperature={temperature} active={stateOfHardware === "operation"} />
                    <Typography sx={{fontSize: "18px"}} >{`${temperature || 0}${temperatureASCII}C`}</Typography>
                </Box>
            </Button>
            <TimeTemperaturePlotting timerDataList={timerDataList} dialogOpen={dialogOpen} onClose={() => setDialogOpen(false)} />
        </>
    )
}

export default TemperatureIcon