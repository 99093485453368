export const DEFAULT_SETTINGS = [{ time:5, temperature:"20", speed: 0, instructionName: "Instruction 1" }]
export const INSTRUCTION_TYPES = ["PreHeat", "Steamer", "Slow Cook", "Kettle", "Tadka", "PreHeat", "Mince", "Self Clean 1", "Self Clean 2"]
export const STEAMER_SETTINGS = [{time: 5, temperature: "100", speed: 0, instructionName: "Steamer"}]
export const SLOW_COOK_SETTINGS = [{time: 3600, temperature: "50",speed: 0, instructionName: "Slow Cook"}]
export const KETTLE_SETTINGS = [{time: 120, temperature: "100", speed: 0, instructionName: "Kettle"}]
export const TADKA_SETTINGS = [{time: 120, temperature: "90", speed: 13, instructionName: "Tadka"}]
export const PREHEAT_SETTINGS = [{time: 90, temperature: "100", speed: 0, instructionName: "PreHeat"}]
export const MINCE_SETTINGS = [{time: 10, temperature: "20", speed: 10, instructionName: "Mince"}]
export const SELF_CLEAN_SETTINGS = [{time: 180, temperature: "50", speed: 1, instructionName: "Self Clean 1"},
                           {time: 15, temperature: "20", speed: 10, instructionName: "Self Clean 2"}
                          ]
// export const DOUGH_SETTINGS = [...new Array(30)].reduce((prev, next) => {
//   const IDENTICAL_SETTINGS = [{time: 3, temperature: 20, speed: 1, instructionName: "Dough"},{time: 3, temperature: "20", speed: 0, instructionName: "Dough"}]
//   return [...prev, ...IDENTICAL_SETTINGS]
// },[]) 

export const PRESET_SETTINGS = [ ...STEAMER_SETTINGS, ...SLOW_COOK_SETTINGS, ...KETTLE_SETTINGS, ...TADKA_SETTINGS, ...PREHEAT_SETTINGS, ...MINCE_SETTINGS, ...SELF_CLEAN_SETTINGS]

export const INTERVAL_SETTINGS = [
  {time: [10, 12], temperature: [20, 25], speed: [6,8], name: "Fine Cuts"},
  {time: [10, 12], temperature: [20, 25], speed: [10,12], name: "Mince"},
  {time: [8, 3600], temperature: [20, 25], speed: [3,4], name: "Chunky Cuts"},
  {time: [1, 12], temperature: [20, 25], speed: [10,12], name: "Grind"},
  {time: [0, 3600], temperature: [20, 25], speed: [13,13], name: "Stir"},
  {time: [120, 230], temperature: [90, 90], speed: [1,13], name: "Saute"},
  {time: [8, 8], temperature: [20, 25], speed: [5,5], name: "Fine Cut Onions"},
  {time: [900, 3600], temperature: [90, 120], speed: [6,8], name: "Cook"},
  {time: [180, 180], temperature: [50, 50], speed: [1,1], name: "Self Clean 1"},
  {time: [15, 15], temperature: [20, 20], speed: [10,10], name: "Self Clean 2"},
  {time: [30, 30], temperature: [20, 25], speed: [1,13], name: "Mixing"},
]