import React from "react"
import { Box, Rating } from "@mui/material"
import PropTypes from "prop-types"
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

const customIconStyle = { fontSize: 100, maxWidth: "100%" }

const customIcons = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon sx={customIconStyle} />,
    },
    2: {
        icon: <SentimentDissatisfiedIcon sx={customIconStyle} />,
    },
    3: {
        icon: <SentimentSatisfiedIcon sx={customIconStyle} />,
    },
    4: {
        icon: <SentimentSatisfiedAltIcon sx={customIconStyle} />,
    },
    5: {
        icon: <SentimentVerySatisfiedIcon sx={customIconStyle} />,
    },
};

const SentimentRating = ({ onSelectRating, subStepId }) => {
    return (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center", boxSizing: "border-box" }}>
            <Rating
                key={subStepId}
                name={subStepId}
                defaultValue={0}
                IconContainerComponent={({ value, ...rest }) => {
                    return (<Box {...rest}>
                        {customIcons[value].icon}
                    </Box>)
                }}
                onChange={onSelectRating}
                highlightSelectedOnly
            />
        </Box >)
}

SentimentRating.propTypes = {
    onSelectRating: PropTypes.func,
    subStepId: PropTypes.string
}

export default SentimentRating