import {
    DISPLAY_DESCRIPTION,
    DISPLAY_EXTRA_PREP,
    DISPLAY_TIMER_INSTRUCTION,
    DISPLAY_NON_TIMER_INSTRUCTION,
    DISPLAY_UTILITIES,
    URL_UTILITY,
    CRITICAL_STEP,
    RATING_COLLECTOR
} from "./displayTypes"
import {
    timerInstructions,
    description,
    imgUtility,
    extraPrep,
    nonTimerInstruction,
    urlUtility,
    criticalStep,
    ratingCollector,
    inProgress,
    paused
} from "./typesVariable"

export const getTime = (time) => {
    const secs = time % 60
    const strSecs = secs < 10 ? "0" + secs.toString() : secs.toString()
    const mins = Math.floor(time / 60)
    return `${mins.toString()}:${strSecs}`
}

export const getDuration = (status, initialTime, operationStartTime) => {
    const currTime = Math.floor((Date.now() - operationStartTime) / 1000);
    return (["operation", "cooldown"].includes(status)) ? Math.max(initialTime - currTime, 0) : initialTime || 0
}

export const createSettings = (r1Settings) => {
    if (r1Settings) {
        const checkValidity = r1Settings.find(i => i?.temperature === "" || i?.speed === "" || i?.time === "")
        if (!checkValidity) {
            const timeSum = r1Settings.reduce((prev, { time: next }) => prev + Number(next), 0)
            return {
                settings: r1Settings,
                totalTime: timeSum
            }
        }
        return undefined
    }
}

export const getAllRecipeIngredients = (data) => {
    if (data) {
        const filteredData = data.reduce((prev, next) => {
            if (next?.subSteps) {
                const subStepIngredients = next.subSteps.reduce((p, n) => description.includes(n.type) ? [...p, ...n?.parameters?.items] : p, [])
                return [...prev, ...subStepIngredients]
            }
            return prev
        }, [])
        return filteredData
    }
    return []
}

export const addDataForExtraPrep = (recipeData) => {
    const extraPrepSteps = recipeData.reduce((prev, next) => {
        const filteredData = next.subSteps.filter(i => extraPrep.includes(i.type))
        if (filteredData) {
            return [...prev, ...filteredData]
        }
        return prev
    }, [])
    const newData = recipeData.map(i => {
        const newSubs = i.subSteps.map(j => {
            if (description.includes(j.type)) {
                const newItems = j.parameters.items.map(k => {
                    const filteredSetAside = extraPrepSteps.find(l => l?.parameters?.item?.ingredientId === k.ingredientId) // change l?.parameters?.item?.prepedIngredientId  
                    if (filteredSetAside) {
                        return { ...k, prevPrep: { redirectId: filteredSetAside.id, name: filteredSetAside?.parameters?.item?.prepedIngredientName } }
                    }
                    return k
                })
                return { ...j, parameters: { ...j.parameters, items: newItems } }
            }
            return j
        })
        return { ...i, subSteps: newSubs }
    })
    return newData
}

export const getDisplayType = (type) => {
    if (description.includes(type)) return DISPLAY_DESCRIPTION
    else if (nonTimerInstruction.includes(type)) return DISPLAY_NON_TIMER_INSTRUCTION
    else if (timerInstructions.includes(type)) return DISPLAY_TIMER_INSTRUCTION
    else if (imgUtility.includes(type)) return DISPLAY_UTILITIES
    else if (extraPrep.includes(type)) return DISPLAY_EXTRA_PREP
    else if (urlUtility === type) return URL_UTILITY
    else if (criticalStep === type) return CRITICAL_STEP
    else if (ratingCollector === type) return RATING_COLLECTOR
    else return undefined
}

export const addActiveToSubSteps = (recipeData) => {
    if (recipeData) {
        const [firstStep, ...rest] = recipeData
        const allSteps = [{ ...firstStep, active: true, priority: "immediate" }, ...rest]
        return allSteps
    }
    return null
}

const getScaledVal = (baseValue, parameters, scaleType, servingSize) => {
    switch (scaleType) {
        case "linear": {
            const checkedScaleFactor = parameters?.scaleFactor || 0
            const checkedServingSize = servingSize || 0
            return Math.max(Number(baseValue) + (checkedServingSize - 2) * checkedScaleFactor, 0)
        }
        case "hardcode": {
            const { settings } = parameters
            return Array.isArray(settings) ? (settings.find((i) => i.servingSize === servingSize)?.time || 0) : 0
        }
        default: {
            return baseValue
        }
    }
}

export const changeRecipeOnServing = (recipe, servingSize) => {
    if (recipe) {
        const { steps } = recipe
        const newSteps = steps.map(i => {
            const { subSteps } = i
            const newSubSteps = subSteps.map(j => {
                if (j.type === "addIngredients" || j.type === criticalStep) {
                    const { items } = j.parameters
                    const newItems = items.map(k => {
                        const { scaleModel } = k
                        const usedQty = getScaledVal(k.amount.qty, scaleModel?.parameters, scaleModel?.type, servingSize)
                        return { ...k, overruledAmount: { ...k.amount, qty: usedQty } }
                    })
                    return { ...j, parameters: { ...j.parameters, items: newItems } }
                }
                else if (j.type === "addIngredientsWithAlternate") {
                    const { items } = j.parameters
                    const newItems = items.map(k => {
                        const { ingredients } = k
                        const newIngredients = ingredients.map(l => {
                            const { scaleModel } = l
                            const usedQty = getScaledVal(l.amount.qty, scaleModel?.parameters, scaleModel?.type, servingSize)
                            return { ...l, overruledAmount: { ...l.amount, qty: usedQty } }
                        })
                        return { ingredients: newIngredients }
                    })
                    return { ...j, parameters: { ...j.parameters, items: newItems } }
                }
                else if (timerInstructions.includes(j.type) || imgUtility.includes(j.type)) {
                    const { settings } = j.parameters
                    const checkedSettings = createSettings(settings)?.settings
                    const newSettings = checkedSettings.map(l => {
                        const { scaleModel } = l
                        return { ...l, time: getScaledVal(l.time, scaleModel?.parameters, scaleModel?.type, servingSize) }
                    })
                    return { ...j, parameters: { ...j.parameters, overruledSettings: newSettings } }
                }
                else {
                    return j
                }
            })
            return { ...i, subSteps: newSubSteps }
        })
        return { ...recipe, steps: newSteps, serving: servingSize }
    }
    return recipe

}

export const editSettings = (steps, subStepId, newSettings) => {
    const currStep = steps.find(step => step.active)
    const { subSteps } = currStep
    const newSubSteps = subSteps.map(i => {
        if (i.id === subStepId)
            return { ...i, parameters: { ...i.parameters, overruledSettings: newSettings } }
        return i
    })
    const newSteps = steps.map(i => {
        if (i.id === currStep.id) {
            return { ...i, subSteps: newSubSteps }
        }
        return i
    })
    return newSteps
}

export const getInProgressStep = (recipeData) => {
    const currStep = recipeData.find(i => i?.status === "in-progress")
    return currStep
}


export const getIndexOfSteps = (steps, id) => {
    const index = steps.findIndex(i => i.id === id)
    return index
}

export const getCompletedStep = (recipeData) => {
    const currStep = recipeData.filter(i => i?.status === "completed")
    return currStep.at(-1)
}

export const getCompletedSubsteps = (subSteps) => {
    const completedSubSteps = subSteps.filter((subStep) => subStep.progress === "completed")
    return completedSubSteps
}

export const getInFocusSubStepId = (steps, stepId) => {
    const step = steps.find(i => i?.id === stepId)
    if (step) {
        const { subSteps } = step
        const { completedSteps, inProgressSteps } = subSteps.reduce((prev, next) => {
            if (["in-progress", "paused", "paused-on-disconnect"].includes(next?.progress))
                return { ...prev, inProgressSteps: [...prev.inProgressSteps, next] }
            else if (next?.progress === "completed")
                return { ...prev, completedSteps: [...prev.completedSteps, next] }
            else
                return prev
        }, { inProgressSteps: [], completedSteps: [] })
        if (inProgressSteps.length > 0)
            return inProgressSteps.at(-1).id
        else if (completedSteps.length > 0)
            return completedSteps.at(-1).id
        else
            return subSteps.at(0).id
    }
    return undefined
}

export const setStartStatus = (recipeData, subStepId) => {
    const step = recipeData.find(i => i.priority === "immediate")
    const { subSteps } = step
    const newSubSteps = subSteps.map((i) => {
        const { parameters } = i
        if (i.id === subStepId && timerInstructions.includes(i.type)) {
            return { ...i, parameters: { ...parameters, status: "start" } }
        }
        return i
    })
    const newRecipeData = recipeData.map(i => {
        if (i.id === step.id) {
            return { ...step, subSteps: newSubSteps }
        }
        return i
    })
    return newRecipeData
}

export const getInactiveSubStepId = (steps, stepId) => {
    const step = steps.find(i => i?.id === stepId)
    if (step) {
        const { subSteps } = step
        const { completedSteps } = subSteps.reduce((prev, next) => {
            if (next?.progress === "completed") {
                return { ...prev, completedSteps: [...prev.completedSteps, next] }
            } else {
                return prev
            }
        }, { completedSteps: [] })

        const skippedBtnStatus = subSteps.find(i => i.parameters?.status === "resume")
        const skippedSubStepIndex = subSteps.indexOf(skippedBtnStatus)
        if (completedSteps.length > 0 && completedSteps.length < subSteps.length) {
            return subSteps.at(completedSteps.length).id
        } else if (skippedBtnStatus && subSteps.indexOf(skippedBtnStatus) < subSteps.length - 1) {
            return subSteps[skippedSubStepIndex + 1].id
        } else {
            return subSteps.at(0).id
        }
    }
}

export const addRatingCollectorToSubstep = (steps, stepIndex) => {
    const step = steps.at(stepIndex)
    const ratingCollectorType = [{
        type: "ratingCollector",
        id: "defultCookingExp",
        parameters: {
            iconType: "star5",
            displayText: "How was your cooking experience?",
            ratingType: "Cooking Experience"
        }
    },
    {
        type: "ratingCollector",
        id: "defultRecipeExp",
        parameters: {
            iconType: "star5",
            displayText: "How is the taste?",
            ratingType: "Recipe Taste"
        }
    }]

    const { subSteps } = step
    const lastStepWithRatingCollector = [...subSteps, ...ratingCollectorType]
    const newSteps = steps.map(i => {
        if (i.id === step.id) {
            return { ...i, subSteps: lastStepWithRatingCollector }
        }
        return i
    })
    return newSteps
}

const getFirstR1Button = (subSteps) => {
    return subSteps.find(i => i?.parameters?.settings)
}

const addTagsToSubSteps = (subSteps, firstR1ButtonId) => {
    const dataWithTags = subSteps.map(i => {
        if (i?.parameters?.settings && i.id === firstR1ButtonId) {
            return {
                ...i,
                progress: "not-started",
                parameters: {
                    ...i.parameters,
                    status: "start",
                    overruledSettings: i.parameters.settings
                }
            }
        }
        if (timerInstructions.includes(i.type)) {
            return {
                ...i,
                progress: "not-started",
                parameters: {
                    ...i.parameters,
                    overruledSettings: i.parameters.settings
                }
            }
        }
        return i
    })
    return dataWithTags
}

export const addTagsToData = (recipeData) => {
    const { steps } = recipeData
    let findFirstR1Flag = true

    const newSteps = steps.map((i, index) => {
        const { subSteps } = i

        const firstR1ButtonId = findFirstR1Flag ? getFirstR1Button(subSteps)?.id : null
        if (firstR1ButtonId) {
            findFirstR1Flag = false
        }
        const newSubSteps = addTagsToSubSteps(subSteps, firstR1ButtonId)

        return index === 0 ?
            { ...i, subSteps: newSubSteps, active: true, priority: "immediate" } :
            { ...i, subSteps: newSubSteps, active: false, priority: "deprioritised" }
    })
    return { ...recipeData, steps: newSteps }
}

export const addRatingCollectorToLastSubstep = (recipeData) => {
    const { steps } = recipeData
    const newSteps = addRatingCollectorToSubstep(steps, -1)
    return { ...recipeData, steps: newSteps }
}

export const getRunningSubStep = (steps) => {
    const activeStep = steps.find(i => i?.active)
    const { subSteps } = activeStep
    return subSteps.find(i => [inProgress, paused].includes(i?.progress))
}