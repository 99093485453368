export const recipeTransformerQL2FS = recipeQL => ({
    id: recipeQL.Recipe_ID,
    metadata: {
        name: recipeQL.Recipe_Name,
        cookTime: recipeQL.Cooking_Time,
        url: recipeQL.image_url,
        prepTime: recipeQL.prep_time,
        active: recipeQL.Active,
        tags: recipeQL.cuisine,
        categories: recipeQL.Diet,
        description: recipeQL.Description,
        author: recipeQL.author,
        editor: recipeQL.editor,
        maxServing: recipeQL.maxServing,
        minServing: recipeQL.minServing,
        lastEditedAt: recipeQL.lastEditedAt
    },
    steps: recipeQL.steps
})

export const recipeTransformerFS2QL = (recipe, recipeId = null) => ({
    Active: recipe.metadata.active,
    Recipe_Name: recipe.metadata.name,
    Cooking_Time: recipe.metadata.cookTime,
    image_url: recipe.metadata.url,
    prep_time: recipe.metadata.prepTime,
    cuisine: recipe.metadata.tags,
    Diet: recipe.metadata.categories,
    steps: recipe.steps,
    Description: recipe.metadata.description,
    author: recipe.metadata.author,
    editor: recipe.metadata.editor,
    maxServing: recipe.metadata.maxServing,
    minServing: recipe.metadata.minServing,
    lastEditedAt: recipe.metadata.lastEditedAt,
    Recipe_ID: recipeId ? recipeId : recipe.id
})