import React, { useState, useContext, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import HardwareBar from "../components/hardwareBar/HardwareBar";
import { HardwareContext, StateOfHardwareContext } from "../components/hardwareBar/HardwareTasks";
import SettingsEdit from "../components/recipeSteps/settingsComponents/SettingsEdit";
import "../css/steps.css"
import "../css/ingredients.css"

const defaultSetting = [{ time: 20, temperature: 20, speed: 13 }]

const R1Button = ({ settings }) => {
    const { forceStop, connected, triggerR1 } = useContext(HardwareContext)
    const stateOfHardware = useContext(StateOfHardwareContext)

    const setSettings = () => {
        triggerR1({ data: settings, type: "serial" })
    }

    return (
        <>{["operation", "cooldown"].includes(stateOfHardware) ? <Button
            className="r1-btn stop-btn"
            onClick={forceStop}
        >
            Stop
        </Button> : <Button
            className={!connected || ["operation", "cooldown"].includes(stateOfHardware) ? "start-btn r1-btn disabled-r1" : "start-btn r1-btn"}
            disabled={!connected || ["operation", "cooldown"].includes(stateOfHardware)}
            onClick={setSettings}
        >
            <Typography variant="button">Start R1</Typography>
        </Button>}
        </>
    )
}

const HardwareDrive2 = ({ hardwarebar, getSettings, defaultSettings = defaultSetting }) => {
    const [settings, setSettings] = useState(defaultSettings)

    const changeSettings = (setting, editIndex) => {
        const newSettings = settings.map((i, index) => {
            if (index === editIndex)
                return setting
            return i
        })
        setSettings(newSettings)
    }

    const onDelete = (editIndex) => {
        const newSettings = settings.filter((i, index) => index !== editIndex)
        setSettings(newSettings)
    }

    useEffect(() => setSettings(defaultSettings), [defaultSettings])

    return (
        <Box display="flex" height="100%">
            {hardwarebar && <Box width="100px">
                <HardwareBar />
            </Box>}
            <Box display="flex" flexDirection="column" style={hardwarebar ? { width: "90%" } : { width: "100%" }}>
                <Box display="flex" flexDirection="column" style={{ gap: "2%", overflowY: "scroll", height: "80%", padding: "2%", boxSizing: "border-box" }}>
                    {settings.map((i, index) => (<React.Fragment key={index}>
                        <SettingsEdit setting={i} setSettings={(settings) => changeSettings(settings, index)} />
                        <Button color="secondary" onClick={() => onDelete(index)}>DELETE</Button>
                        <Box className="dotted-line" />
                    </React.Fragment>
                    ))}
                </Box>
                {hardwarebar ? <Box display="flex" style={{ gap: "10px", justifyContent: "center", width: "100%", position: "fixed", bottom: 0 }}>
                    <Button style={{ fontSize: 25 }} onClick={() => setSettings([...settings, ...defaultSetting])}>
                        Add New Instruction
                    </Button>
                    <R1Button settings={settings} />
                </Box> :
                    <Box display="block" style={{ margin: "0 25%", justifyContent: "center", backgroundColor: "#fff", width: "55%", position: "fixed", bottom: 50, left: 0 }}>
                        <Button color="secondary" style={{ fontSize: 25 }} onClick={() => setSettings([...settings, ...defaultSetting])}>
                            ADD NEW INSTRUCTION
                        </Button>
                        <Button color="secondary"
                            sx={{ color: "#043F98", border: "3px solid #043F98", borderRadius: "10px", padding: "0 15px" }}
                            onClick={e => getSettings(e, settings)}>
                            SAVE
                        </Button>
                    </Box>}
            </Box>
        </Box>)
}

export default HardwareDrive2