import { Box } from "@mui/material";
import React from "react";
import IngredientDetail from "./IngredientDetail"
import DottedLine from "./DottedLine";
import PropTypes from "prop-types";

const VegetablesPantryDisplay = ({ items, displayDottedLine = true, display, ingredientsOnly = false, recipeDispatch }) => {
    if (display) {
        return (<>
            <Box padding="28px 0">
                <IngredientDetail data={items} ingredientsOnly={ingredientsOnly} recipeDispatch={recipeDispatch} />
            </Box>
            {displayDottedLine ? <DottedLine /> : null}
        </>)
    }
    return null
}

VegetablesPantryDisplay.propTypes = {
    display: PropTypes.bool,
    items: PropTypes.array,
    displayDottedLine: PropTypes.bool,
    ingredientsOnly: PropTypes.bool,
    recipeDispatch: PropTypes.func.isRequired
}

export default VegetablesPantryDisplay