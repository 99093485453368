import React from 'react'
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink } from '@apollo/client'

const client = new ApolloClient({
    link: new HttpLink({
        uri: 'https://react-labs.hasura.app/v1/graphql',
        headers: {
            "content-type": "application/ json",
            "x-hasura-admin-secret": "pux6hp1r1KcZU4XLB9hIhCRDtO8MpuxTp5oQGhMxeQsDRzvPZCDBSksd1F4Ywmdl"
        }
    }),
    cache: new InMemoryCache(),
})

const ApolloProviderWrapper = ({ children }) => {
    return (
        <ApolloProvider client={client}>
            {children}
        </ApolloProvider>
    )
}

export default ApolloProviderWrapper