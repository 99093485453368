import React from "react"
import { Box, TextField } from "@mui/material"
import PropTypes from "prop-types"

const InputField = ({ name, onChange, required = false, data, delBtn = null }) => { //add key to input and remove data=""
    return (<Box style={{ display: "flex" }}>
        <TextField key={name} name={name} onChange={onChange} required={required} value={data} />
        {delBtn}
    </Box>)
}

InputField.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    data: PropTypes.string,
    delBtn: PropTypes.element
}

export default InputField