import React from "react"
import { Typography } from "@mui/material"
import IngredientDetail from "./IngredientDetail"
import PropTypes from "prop-types";

const PowderSpicesDisplay = ({ display, items, title, ingredientsOnly = false, recipeDispatch }) => {
    if (display) {
        return (<>
            <Typography variant="button">{title}</Typography>
            <IngredientDetail data={items} ingredientsOnly={ingredientsOnly} recipeDispatch={recipeDispatch} />
        </>)
    }
    return null
}

PowderSpicesDisplay.propTypes = {
    display: PropTypes.bool,
    title: PropTypes.string,
    items: PropTypes.array,
    setNewRecipe: PropTypes.func,
}

export default PowderSpicesDisplay