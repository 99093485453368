import { Box, Button, Container } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import ListCard from "../search/ListCard";
import History from "../searchComponents/History";
import PropTypes from "prop-types";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import useRecipeListFromGQL from "../../RecipeBackEnd/loadRecipeListGQL";
import { ReactComponent as LockIcon } from "../../../svg/cardIcons/lock-dark.svg"
import { useUserSpecifcRecipe } from "../../../components/recipeSelect/RecipeList";
import { ErrorManagementContext } from "../../errorManagement/ErrorManagementProvider";
import { getUnLockedRecipes } from "../../levels/levelsUtils";

const styles = {
  display: "flex",
  flexDirection: "column",
  width: "100vw",
};

const Search = ({ open, onClose, setSecretMessage, secretMessage, setDrawerOpen, displayType = "LINK_TO_RECIPE" }) => {
  const { recipeList: activeRecipes } = useRecipeListFromGQL()
  const { cookClubLog } = useContext(ErrorManagementContext)
  const { recipes: userSpecificRecipes, userTags } = useUserSpecifcRecipe()
  const userRecipes = activeRecipes.filter(r => userSpecificRecipes.includes(r.id))
  const history = useHistory()
  function handleClose() {
    onClose()
    if (secretMessage === "golmaal") {
      setDrawerOpen(true)
    }
  }
  const unLockedRecipes = getUnLockedRecipes(userTags, activeRecipes, userRecipes)

  const handleUserLevelRecipes = (recipeId) => {
    if (unLockedRecipes.find(r => r.id === recipeId) || ["any", undefined].includes(JSON.parse(localStorage.getItem("globalSettings"))?.user)) {
      const currPath = displayType === "INGREDIENTS_ONLY" ? history.push("/ingredients/" + recipeId) : `experience2/${recipeId}`
      history.push(currPath)
    } else {
      cookClubLog("This Recipe is yet to be unlocked", "RECIPE LOCKED", { recipeId }, { ui: { type: "error" } })
    }
  }

  const handleSearch = (value) => {
    setSecretMessage(value)
  }

  return (
    <Drawer onClose={handleClose} open={open}>
      <Container style={styles}>
        <Box style={{ display: "flex", alignItems: "center", margin: "20px 0" }}>
          <Autocomplete
            id="combo-box-demo"
            options={activeRecipes}
            size="medium"
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.defaultMuiPrevented = true;
                onClose()
                if (secretMessage === "golmaal") {
                  setDrawerOpen(true)
                }
              }
            }}
            getOptionLabel={(option) => option.metadata.name}
            style={{ width: "95vw", marginRight: 4, display: "inline-block" }}
            renderInput={(params) => <TextField onChange={(e) => handleSearch(e.target.value)} {...params} label="Search recipes" variant="outlined" autoFocus />}
            renderOption={(props, option) =>
              <Box style={{ position: "relative" }}>
                <ListCard
                  key={option.id}
                  image={option?.metadata?.url}
                  username={option?.metadata?.name}
                  title={option?.metadata?.name}
                  onClick={() => handleUserLevelRecipes(option?.id)}
                />
                {!unLockedRecipes.find(r => r.id === option?.id) && !["any", undefined].includes(JSON.parse(localStorage.getItem("globalSettings"))?.user) && <Box style={{ position: "absolute", top: '30%', right: '5%' }}>
                  <LockIcon />
                </Box>}
              </Box>
            }
          />
          <Button variant="text" color="primary" onClick={handleClose}>Cancel</Button>
        </Box>
        <History setDrawerOpen={setDrawerOpen} />
      </Container>
    </Drawer>
  );
};

Search.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  displayType: PropTypes.string
}

export default Search;
