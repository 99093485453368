import { Box, ButtonBase, Typography } from '@mui/material'
import React, { useState } from 'react'

const History = ({ setDrawerOpen }) => {
  const [clickCount, setClickCount] = useState(0)
  if (clickCount === 1)
    setTimeout(() => setClickCount(0), 2000)
  if (clickCount === 5) {
    setDrawerOpen(true)
    setClickCount(0)
  }

  return (
    <Box >
      <ButtonBase onClick={() => setClickCount(clickCount + 1)}>
        <Typography variant='body1'>History Coming Soon ....</Typography>
      </ButtonBase>
    </Box>
  )
}

export default History