import React, { useContext } from 'react'
import { Box } from "@mui/material"
import PropTypes from "prop-types"
import BladeIcon from "./BladeIcon"
import ConnectButton from "./ConnectButton"
import TimerIcon from "./TimerIcon"
import ReactIcon from "./ReactIcon"
import TemperatureIcon from "./TemperatureIcon"
import WeightBtn from "../ingredients2/WeightBtn"
import { ReactComponent as WeightIcon } from '../../svg/weighing-scale.svg'
import { StateOfHardwareContext } from './HardwareTasks'
import ContainerIcon from './ContainerIcon'

const HardwareBar = ({ setHardwareStateMemo, hardwareStateMemo, homescreen }) => {
    const stateOfHardware = useContext(StateOfHardwareContext)
    return (<Box
        style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: `${stateOfHardware === "operation" ? '#FFE8D8' : '#E5F1E4'}`,
            height: "100%",
            width: "9vw",
            padding: "10px 0",
            boxSizing: "border-box", 
            gap: "10px",
            position: homescreen && "fixed"
        }}>
        <ReactIcon />
        <ContainerIcon/>
        <TimerIcon />
        <BladeIcon />
        <TemperatureIcon />
        <WeightBtn iconSize={60} iconWidth="100%" icon={WeightIcon} viewBox="0 0 60 60" />
        <ConnectButton hardwareStateMemo={hardwareStateMemo} setHardwareStateMemo={hardwareStateMemo?.btFirstConnect === false ? setHardwareStateMemo : () => { }} />
    </Box>)
}

HardwareBar.propTypes = {
    setHardwareStateMemo: PropTypes.func,
    hardwareStateMemo: PropTypes.instanceOf(Object)
}

export default HardwareBar
