import { createTheme } from '@mui/material/styles';

const ccTheme = createTheme({
    typography: {
        // fontFamily: ['Montserrat', "Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
        // fontSize: 16,
        // fontWeightRegular: 500
        h2: {
            fontSize: 70,
            fontFamily: "Montserrat",
            fontWeight: 600,
        },
        h3: {
            fontSize: 40,
            fontFamily: "Montserrat",
            fontWeight: 500,
        },
        h4: {
            fontSize: 40,
            fontFamily: "Montserrat",
            fontWeight: 400,
        },
        h5: {
            fontSize: 30,
            fontFamily: "Montserrat",
            fontWeight: 400,
        },
        button: {
            fontSize: 30,
            fontFamily: "Montserrat",
            fontWeight: 600,
            textTransform: "none",
        },
        body1: {
            fontSize: 20,
            fontFamily: "Montserrat",
            fontWeight: 400,
        },
        body2: {
            fontSize: 20,
            fontFamily: "Montserrat",
            fontWeight: 300,
        },
        caption: {
            fontSize: 20,
            fontFamily: "Montserrat",
            fontWeight: 600,
        },
        card: {
            fontSize: 18,
            fontFamily: "'Lato', 'sans-serif'",
            fontWeight: 400
        },
        cardLight: {
            fontSize: 22,
            fontFamily: "'Lato', 'sans-serif'",
            fontWeight: 300
        },
        cardBold: {
            fontSize: 20,
            fontFamily: "'Lato', 'sans-serif'",
            fontWeight: 700
        },
        cardLarge: {
            fontSize: 40,
            fontFamily: "'Lato', 'sans-serif'",
            fontWeight: 700
        }
    },
    palette: {
        primary: {
            main: "#FD8D3D",
            contrastText: "#ffffff"
        },
        secondary: {
            main: "#043F98"
        }
    }
})

export default ccTheme