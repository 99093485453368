import React from "react"
import { Box } from "@mui/material"
import PropTypes from "prop-types"

const StepperIcon = ({ connectorClass, iconClass, style }) => {
    const stepLineClass = connectorClass.split(' ')
    return (
        <Box style={{position: "relative"}}>
            <Box className={stepLineClass[0]}>
                <Box style={style} />
                <Box className={stepLineClass[1]} />
            </Box>
            <Box className={iconClass} /> 
        </Box>
    )
}

StepperIcon.propTypes = {
    connectorClass: PropTypes.string,
    iconClass: PropTypes.string
}
export default StepperIcon