import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ListImage from '../../utils/homepage/ListImage';
import ListContent from '../../utils/homepage/ListContent';
import { ReactComponent as LockIcon } from "../../svg/cardIcons/lockIcon.svg"
import { Box } from '@mui/material';

const RecipeCard = ({
  recipeImage = "",
  time,
  name,
  tags = [],
  categories = [],
  onClick = () => { },
  maxServing,
  minServing,
  recipeLocked,
  loading = false
}) => {
  return (
    <Card style={{height: "380px", borderRadius: "10px", position: "relative"}} onClick={onClick}>
      {recipeLocked && <Box style={{position: "absolute", top: "27%", left: "40%", color: "#FFF", width: "90px", height: "30px", zIndex: 100}}>
        <LockIcon/>
      </Box>}
      <ListImage image={recipeImage} loading={false} time={time} recipeLocked={recipeLocked} />
      <CardContent style={{ paddingLeft: "8px", paddingRight: "8px"}}>
        <ListContent title={name} tags={tags} categories={categories} loading={false} maxServing={maxServing} minServing={minServing} />
      </CardContent>
    </Card>
  )
}

export default RecipeCard