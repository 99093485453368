import { TextField, Typography, Grid, MenuItem, InputLabel, Select } from "@mui/material";
import React from "react";
import ChipInput from "../../utils/ChipInput";
import RenderInputField from "./RenderInputField";

const metadataSchema = {
  type: "object",
  properties: {
    name: {
      type: "string",
      title: "Recipe Display Name",
      description: "Name of the recipe",
    },
    prepTime: {
      type: "number",
      title: "Prep Time",
      description: "Time person has to spend on the recipe",
    },
    cookTime: {
      type: "number",
      title: "Cooking Time",
      description: "Time the product will cook by itself",
    },
    minServing: {
      type: "number",
      title: "Minimum Serving Size",
      description: "minimum serving of recipe",
    },
    active: {
      type: "boolean",
      title: "ACTIVE",
      description: "If the Recipe is live on the Product",
    },
    maxServing: {
      type: "number",
      title: "MAXIMUM SERVING",
      description: "maximum serving of recipe",
    },
    description: {
      type: "string",
      title: "Description",
      description: "This is the recipe Description",
    },
    url: {
      type: "string",
      title: "Recipe Image Link",
      description: "url of the image on the main page",
    }
  },
  required: ["id"],
};

const MetadataEdit = ({ objectData, onMetadataChange }) => {
  const { properties } = metadataSchema

  const editMetadata = (e, property, type) => {
    switch (type) {
      case "number":
        onMetadataChange({ ...objectData, [property]: e.target.valueAsNumber })
        break
      case "string":
        onMetadataChange({ ...objectData, [property]: e.target.value })
        break
      case "boolean":
        onMetadataChange({ ...objectData, [property]: e.target.checked })
        break
      default: alert("What have you done??")
    }
  };

  const handleAddItem = (item, props) => {
    if (objectData.hasOwnProperty(props)) {
      onMetadataChange({ ...objectData, [props]: [...objectData[props], item] })
    } else {
      onMetadataChange({ ...objectData, [props]: [item] })
    }
  }

  const handleDeleteItem = (item, props) => {
    if (objectData.hasOwnProperty(props) && objectData[props].length) {
      onMetadataChange({ ...objectData, [props]: objectData[props].filter(i => i !== item) })
    }
  }

  return (
    <Grid container>
      {Object.keys(metadataSchema.properties).map((property) => (
        <Grid item xs={4} key={property}>
          <RenderInputField
            schemaProperties={properties[property]}
            objectData={objectData[property]}
            onInputChange={(e) =>
              editMetadata(
                e,
                property,
                properties[property].type
              )
            }
          />
        </Grid>
      ))}
      <Grid item xs={4}>
        <TextField
          variant="outlined"
          label="Author"
          key={"Author"}
          name={"Author"}
          value={objectData.author ? objectData.author : ""}
          fullWidth
          helperText={"Creator of the recipe"}
          size="small"
          disabled={true}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          variant="outlined"
          label="Editor"
          key={"Editor"}
          name={"Editor"}
          value={objectData.editor ? objectData.editor : ""}
          fullWidth
          helperText={"Editor of the recipe"}
          size="small"
          disabled={true}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          variant="outlined"
          label="Last Edited At"
          key={"Editor"}
          name={"Editor"}
          value={objectData.lastEditedAt ? objectData.lastEditedAt : ""}
          fullWidth
          helperText={"Last edited at"}
          size="small"
          disabled={true}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>Categories</Typography>
        <ChipInput
          value={objectData.categories ? objectData.categories : [""]}
          onAdd={(item) => handleAddItem(item, "categories")}
          onDelete={(item) => handleDeleteItem(item, "categories")}
          placeholder="Category"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>Tags</Typography>
        <ChipInput
          value={objectData.tags ? objectData.tags : [""]}
          onAdd={(item) => handleAddItem(item, "tags")}
          onDelete={(item) => handleDeleteItem(item, "tags")}
          placeholder="Tags"
        />
      </Grid>
      <Grid item xs={12}>
        <InputLabel>Substep width</InputLabel>
        <Select
          defaultValue="default"
          onChange={e =>
            editMetadata(
              e,
              "subStepWidth",
              "string"
            )
          }
        >
          <MenuItem value="default">default</MenuItem>
          <MenuItem value="wide">wide</MenuItem>
        </Select>
      </Grid>
    </Grid>
  );
};

export default MetadataEdit;
