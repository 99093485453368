import React, { useContext, useState } from "react"
import WeightDisplay from "../weighingScale/WeightDisplay";
import PopUpDialog from "../PopUpDialog";
import { Button, SvgIcon, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { logEvents } from "../../utils/logging";
import { ReactComponent as WeighIcon } from "../../svg/weigh-icon.svg"
import { GlobalSettingsContext } from "../../utils/globalSettings/globalSettingsContext"

const WeightBtn = ({ weight, btnClass, iconSize = 28, viewBox = "0 0 32.153 30.878", iconWidth = "40%", icon = WeighIcon, nutrientInfo = null, img = null }) => {
    const [popScale, setPopScale] = useState(false)
    const { globalSettings: { user } } = useContext(GlobalSettingsContext)

    const toggleScale = (e) => {
        e.stopPropagation()
        const sessionId = sessionStorage.getItem("sessionId")
        logEvents(sessionId, "SCALES_TOGGLE", { state: !popScale, weight, user })
        setPopScale(old => !old)
    }

    return (<>
        <Button
            className={btnClass}
            onClick={toggleScale}
        >
            <SvgIcon component={icon} viewBox={viewBox} style={{ fontSize: iconSize, width: iconWidth }} />
            {weight ? <Typography variant="body1" style={{ marginLeft: 10, width: "60%", textAlign: "center" }}>{weight}</Typography> : null}
        </Button>
        <PopUpDialog
            dialogOpen={popScale}
            onClickBack={toggleScale}
            componentInDialog={<WeightDisplay text={weight || ""} nutrientInfo={nutrientInfo} img={img} />}
        />
    </>)
}

WeightBtn.propTypes = {
    weight: PropTypes.string,
    btnClass: PropTypes.string,
    iconSize: PropTypes.number,
    viewBox: PropTypes.string,
    iconWidth: PropTypes.string,
    icon: PropTypes.instanceOf(Object),
    nutrientInfo: PropTypes.instanceOf(Object),
    img: PropTypes.string
}

export default WeightBtn