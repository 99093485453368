import React, { useState } from "react";
import { Typography, Box } from "@mui/material";
import PropTypes from 'prop-types'

const servingBtn = {
    background: "#043F98",
    color: "#FFFFFF",
    padding: "5px 20px",
    borderRadius: "5px",
}

const restBtn = {
    background: "#FFFFFF",
    color: "#043F98",
    padding: "5px 20px",
    borderRadius: "5px",
}

const ServingBtn = ({ changeServing, serving, maxServing, minServing, IconSize = 50, textVariant = "h3" }) => {
    const [showScale, setShowScale] = useState(false)
    const handleServing = (value) => {
        changeServing(value)
        setShowScale(false)
    }

    const handleShowScale = () => {
        if (maxServing !== minServing) {
            setShowScale(true)
        }
    }

    return (<>
        <Box>
            {!showScale && <Box onClick={handleShowScale} style={{ background: "#043F98", borderRadius: "5px", padding: "2px 8px", display: "flex", alignItems: "center" }}>
                {maxServing !== minServing && <><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 1L1 5L5 9" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                    <Typography variant="cardLarge" style={{ background: "#043F98", color: "#FFFFFF", padding: "5px", borderRadius: "5px" }} >{serving}</Typography>
                    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L5 5L1 9" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg></>}
                {maxServing === minServing && <Typography variant="cardLarge" style={{ background: "#043F98", color: "#FFFFFF", padding: "5px 15px", borderRadius: "5px" }} >{serving}</Typography>}
            </Box>}
            {showScale && <Box sx={{ background: "#FFFFFF", display: "flex", alignItems: "center", border: "1px solid #043F98", borderRadius: "5px", padding: "5px" }} onClose={() => setShowScale(false)} open={showScale}>
                {[1, 2, 3, 4].map(i => <Typography key={i} variant="cardLarge" onClick={() => handleServing(i)} sx={i === serving ? servingBtn : restBtn}>{i}</Typography>)}
            </Box>}
        </Box>
    </>)
}

ServingBtn.propTypes = {
    changeServing: PropTypes.func,
    serving: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    size: PropTypes.number,
    textVariant: PropTypes.string
}

export default ServingBtn