import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'
import { firestoreCreateNewDocument } from '../../firebase/useFirebase'
import { GlobalSettingsContext } from '../globalSettings/globalSettingsContext'

//spec for logging => https://www.notion.so/reactlabs/Logging-Spec-e68e96abecb5430890c7e465cb7c2b4f

export const ErrorManagementContext = createContext()

const ErrorManagementProvider = ({ children }) => {
    const { globalSettings: { deviceId, userCode, device } } = useContext(GlobalSettingsContext)
    const { enqueueSnackbar } = useSnackbar()
    function cookClubLog(message, eventType, eventData, settings = { ui: { type: "default" } }) {
        const { ui, firebase, hasura, bytebeam, console } = settings
        const sessionId = sessionStorage.getItem("sessionId")
        if (ui)
            enqueueSnackbar(`${eventType} : ${message}`, { variant: ui?.type ? ui.type : "default" })
        if (firebase)
            firestoreCreateNewDocument("events", { sessionId, deviceId, eventType, eventData })
        if (console)
            console.log(eventType, message)
    }
    return (
        <ErrorManagementContext.Provider value={{ cookClubLog }}>
            {children}
        </ErrorManagementContext.Provider>
    )
}

ErrorManagementProvider.propTypes = {}

export default ErrorManagementProvider