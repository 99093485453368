import { timerInstructions, imgUtility } from "./typesVariable"
import { createSettings } from "./stepsUtils"

const checkIfStepCompleted = (subSteps) => {
    return subSteps.filter(i => !(["skipped", "completed"].includes(i?.progress))).length === 0
}

const createNewRecipeDataOnComplete = (recipeData, currStep, newSubSteps) => {
    const completed = checkIfStepCompleted(newSubSteps)
    const newRecipeData = recipeData.reduce((prev, next) => {
        if (next.id === currStep.id && completed)
            return [...prev, { ...next, subSteps: newSubSteps, status: "completed", priority: "deprioritised" }]
        else if (next.id === currStep.id)
            return [...prev, { ...next, subSteps: newSubSteps }]
        else if (prev.at(-1)?.status === "completed")
            return [...prev, { ...next, priority: "immediate" }]
        return [...prev, next]
    }, [])
    return newRecipeData
}

const setFirstR1BtnStatusToStart = (steps, stepId) => {
    const currStep = steps.find(i => i.id === stepId)
    const { subSteps } = currStep || {}

    if (subSteps) {
        let setR1BtnStatusFlag = true
        const newSubSteps = subSteps.map(i => {
            if (setR1BtnStatusFlag && i?.parameters?.settings) {
                setR1BtnStatusFlag = false
                return { ...i, parameters: { ...i.parameters, status: "start" } }
            }
            return i
        })
        return newSubSteps
    }
    return null
}

const setPrevSubStepsStatusAndProgress = (subSteps) => {
    return subSteps.map(i => {
        if (i?.parameters?.settings) {
            const progress = i?.progress === "completed" ? i.progress : "skipped"
            return { ...i, progress, parameters: { ...i?.parameters, status: "completed" } }
        }
        else
            return { ...i, progress: "completed" }
    })
}

const setPrevStepAndSubsteps = (steps) => {
    const newSteps = steps.map(i => {
        const { subSteps } = i
        const newSubSteps = setPrevSubStepsStatusAndProgress(subSteps)
        return { ...i, status: "completed", subSteps: newSubSteps }
    })
    return newSteps
}

const createRecipeDataOnTriggerR1 = (recipeData, newCurrSubSteps, stepId) => {
    const allR1ButtonsClicked = newCurrSubSteps.find(i => i?.parameters?.settings && i.parameters?.status === "start") ? false : true

    return recipeData.reduce((prev, next) => {
        if (next.id === stepId) {
            const newPrevSteps = setPrevStepAndSubsteps(prev)
            return [...newPrevSteps, { ...next, subSteps: newCurrSubSteps, status: "in-progress" }]
        }
        else if (prev.at(-1)?.id === stepId && allR1ButtonsClicked) {
            const newSubSteps = setFirstR1BtnStatusToStart(recipeData, next.id)
            return [...prev, { ...next, subSteps: newSubSteps }]
        }
        return [...prev, next]
    }, [])
}

export const updateInProgressToCompletedOnChangeStep = (steps) => {
    const currStep = steps.find(step => step?.status === "in-progress")
    const { subSteps } = currStep || {}
    if (subSteps) {
        const allR1ButtonsClicked = subSteps.find(i => timerInstructions.includes(i.type) &&
            (i.parameters?.status === "start" || !i.parameters?.status)) ? false : true
        if (allR1ButtonsClicked) {
            const newSubSteps = subSteps.map(i => {
                if (!(timerInstructions.includes(i.type)))
                    return { ...i, progress: "completed" }
                else
                    return i
            })
            const newSteps = createNewRecipeDataOnComplete(steps, currStep, newSubSteps)
            return newSteps
        }
        return steps
    }
    return steps
}

export const setSubStepStatusOnIdle = (recipeData) => {
    const currStep = recipeData.find(step => step?.status === "in-progress")
    if (currStep) {
        const { subSteps } = currStep
        const newSubSteps = subSteps.map((i) => {
            if (i?.progress === "in-progress" || i?.progress === "paused") {
                return { ...i, progress: "completed" }
            }
            return i
        })
        const newRecipeData = createNewRecipeDataOnComplete(recipeData, currStep, newSubSteps)
        return newRecipeData
    }
    return recipeData
}

export const setSubStepStatusForTriggerR1 = (recipeData, subStepId, stepId) => {
    const currStep = recipeData.find(step => stepId === step.id)
    const { subSteps } = currStep
    let setNextR1BtnStatusFlag = true

    const newCurrSubSteps = subSteps.reduce((prev, next) => {
        if (next.id === subStepId) {
            const newPrevSubSteps = setPrevSubStepsStatusAndProgress(prev)
            return [...newPrevSubSteps, {
                ...next, progress: "in-progress",
                parameters: { ...next?.parameters, status: "completed" }
            }]
        }
        else if (setNextR1BtnStatusFlag && next?.parameters?.settings && next.progress === "not-started") {
            setNextR1BtnStatusFlag = false
            return [...prev, { ...next, parameters: { ...next?.parameters, status: "start" } }]
        }
        return [...prev, next]
    }, [])

    const newSteps = createRecipeDataOnTriggerR1(recipeData, newCurrSubSteps, stepId)

    const runningInstruction = subStepId && subSteps.find(i => i.id === subStepId)
    const instruction = runningInstruction?.parameters?.overruledSettings || runningInstruction?.parameters?.settings
    const settings = instruction && createSettings(instruction)

    return { r1Settings: settings, newRecipeData: newSteps }
}

export const changeProgressTypeOnDisconnect = (recipeData, type) => {
    const currStep = recipeData.find(step => step?.status === "in-progress")
    if (currStep) {
        const { subSteps } = currStep
        const newSubSteps = subSteps.map(i => {
            if (i?.progress === "in-progress")
                return { ...i, progress: type }
            else
                return i
        })
        const newRecipeData = recipeData.map(i => {
            if (i.id === currStep.id)
                return { ...currStep, subSteps: newSubSteps }
            else
                return i
        })
        return newRecipeData
    }
    return recipeData
}

export const changeProgressType = (recipeData, subStepId, type) => {
    const currStep = recipeData.find(step => step?.active)
    const { subSteps } = currStep
    const newSubSteps = subSteps.map(i => {
        if (i.id === subStepId) {
            return { ...i, progress: type }
        }
        return i
    })
    const newRecipeData = recipeData.map(i => {
        if (i.id === currStep.id)
            return { ...currStep, subSteps: newSubSteps }
        else
            return i
    })
    return newRecipeData
}