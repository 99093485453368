import {Grid, MenuItem, Select, TextField} from "@mui/material"
import R1SerialSettings from "../customInputs/R1SerialSettings"
import React from 'react'

const ImgUtilityRender = ({parameters, setParameters}) => {
  const onInputChange = (e, props, fieldType="string") => {
    if (fieldType === "number") {
      setParameters({...parameters, [props]: e.target.valueAsNumber})
    } else {
      setParameters({...parameters, [props]: e.target.value})
    }
  }
  const {settings} = parameters || {}
  const setSettings = newSettings => setParameters({...parameters, settings: newSettings})
  return (
    <Grid container>
      <TextField value={parameters.title} label={"Title"} onChange={(e) => onInputChange(e, "title")} />
      <TextField value={parameters.url} label={"Image Url"} onChange={(e) => onInputChange(e, "url")}/>
      <TextField value={parameters.type} label={"Type"} onChange={(e) => onInputChange(e, "type")}/>
      <TextField value={parameters.weigh} label={"Weigh"} type="number" onChange={(e) => onInputChange(e, "weigh", "number")}/>
      {settings && <Grid item xs={12}>
        <Select
          value={parameters?.cap || "None"}
          onChange={e => onInputChange(e, "cap")}
        >
          <MenuItem value="None">None</MenuItem>
          <MenuItem value="on">Cap On</MenuItem>
          <MenuItem value="off">Cap Off</MenuItem>
        </Select>
      </Grid>}
      <Grid item xs={12}>
          <R1SerialSettings settings={settings} setSettings={setSettings} />
      </Grid>
    </Grid>
    
  )
}

export default ImgUtilityRender