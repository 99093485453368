import React from "react"
import ArrayEditor from "./ArrayEditor"
import ObjectEditor from "./ObjectEditor"
import PropTypes from 'prop-types'
import InputField from "./components/InputField"
import BooleanField from "./components/BooleanField"
import NumberField from "./components/NumberField"

const Type = ({ data, schema, editData, position, path, addNewField, delBtn = null, deleteField, title, description }) => {
    switch (schema?.type) {
        case "string": return <InputField name={path} onChange={e => editData(e.target.value, position, e.target.name)} required={true} data={data} delBtn={delBtn} />
        case "number": return <NumberField name={path} onChange={e => editData(e.target.valueAsNumber, position, e.target.name)} required={true} data={data} delBtn={delBtn} />
        case "boolean": return <BooleanField name={path} onChange={e => editData(e.target.checked, position, e.target.name)} required={true} data={data} delBtn={delBtn} />
        case "array": return <ArrayEditor data={data} schema={schema.items} editData={editData} path={path} addNewField={addNewField} deleteField={deleteField} />
        case "object": return <ObjectEditor data={data} schema={schema} editData={editData} path={path} position={position} addNewField={addNewField} delBtn={delBtn} deleteField={deleteField} />
        default: return null
    }
}

Type.propTypes = {
    data: PropTypes.object,
    schema: PropTypes.object,
    path: PropTypes.string,
    position: PropTypes.number,
    addNewField: PropTypes.func,
    delBtn: PropTypes.element,
    deleteField: PropTypes.func
}

export default Type