export const recipeLevelOrder = {
  "level_1": 1,
  "level_2": 2,
  "level_3": 3
}

export const getTrackRecipes = (activeRecipes) => {
  const categoryTrack = ["track_veg", "track_nonveg"]
  const trackRecipes = activeRecipes.filter(r => {
    return (categoryTrack.find(i => r?.metadata?.hiddenTags.includes(i)))
  })
  return trackRecipes
}

export const getLevelRecipes = (activeRecipes) => {
  const categoryLevel = ["level_1", "level_2", "level_3"]
  const levelRecipes = activeRecipes.filter(r => {
    return (categoryLevel.find(i => r?.metadata?.hiddenTags.includes(i)))
  })
  return levelRecipes
}

export const getUserTag = (tag, userTags) => {
  const userTag = userTags.find(i => i.includes(tag))
  return userTag
}

export const removeDuplicateRecipes = (recipesA, recipesB) => {
  // remove recipes from recipesA that contains recipesB
  return recipesA.filter(r => !recipesB.find(i => i.id === r.id))
}

export const getUnLockedRecipes = (userTags, activeRecipes, userRecipes) => {
  const userLevel = getUserTag("level", userTags)
  const userTrack = getUserTag("track", userTags)
  const userTrackRecipes = getTrackRecipes(activeRecipes).filter(r => {
    return (r?.metadata?.hiddenTags.find(i => i.includes(userTrack)))
  })
  const unLockedLevelRecipes = userTrackRecipes.filter(r => {
    const { hiddenTags } = r.metadata
    const recipeLevel = hiddenTags.find(r => r.includes("level"))
    return (recipeLevelOrder[userLevel] >= recipeLevelOrder[recipeLevel])
  })
  const unLockedRecipes = userLevel==="level_3" ? activeRecipes : [ ...unLockedLevelRecipes, ...userRecipes]
  return unLockedRecipes
}