import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { animated, useSpring, config } from 'react-spring'
import PropTypes from "prop-types"
import { WeightContext } from '../hardwareBar/HardwareTasks';

const ScaleAnimation = ({ src, tare = 0 }) => {
    const reading = useContext(WeightContext)
    const rotation = -1 * Math.ceil((reading - tare) / 10)

    const styles = useSpring({
        to: { rotateZ: rotation },
        config: config.slow,
    })

    return (
        <Box style={{ position: "absolute", zIndex: 0, height: "40vh", top: 0, left: 0, width: "100%" }}>
            <Box style={{ position: "relative", height: "100%", width: "100%", overflow: "hidden" }}>
                <animated.img style={{ width: "100%", position: "absolute", bottom: "0", ...styles }} src={src} />
            </Box>
        </Box>
    )
}

ScaleAnimation.propTypes = {
    reading: PropTypes.number,
    src: PropTypes.string
}

export default ScaleAnimation