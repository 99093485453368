import React from "react"
import { Box, Rating } from "@mui/material"
import PropTypes from "prop-types"
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';

const StarRating = ({ maxValue, onSelectRating, subStepId }) => {
    return (<Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Rating
            key={subStepId}
            name={subStepId}
            defaultValue={0}
            max={maxValue}
            icon={<StarIcon sx={{ fontSize: 100, maxWidth: "100%" }} />}
            emptyIcon={<StarBorderIcon sx={{ fontSize: 100, maxWidth: "100%" }} />}
            onChange={onSelectRating}
        />
    </Box>)
}

StarRating.propTypes = {
    maxValue: PropTypes.number,
    onSelectRating: PropTypes.func,
    subStepId: PropTypes.string
}

export default StarRating