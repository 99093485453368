import { Container, Grid } from '@mui/material'
import DialEditor from "./DialEditor"
import { ReactComponent as Time } from "../../svg/Time1.svg"
import { ReactComponent as Temperature } from "../../svg/Temperature1.svg"
import { ReactComponent as Speed } from '../../svg/BladeSpeed1.svg';
import React, { useState } from 'react'

const SettingDisplay = ({ setting, onChangeSettings, setScroll, instructionCount }) => {
  const { time, temperature, speed } = setting
  const [styles, setStyles] = useState({time: {}, temperature: {}, speed: {}})
  const [active, setActive] = useState(false)

  const handleSettingsChange = (newSettings, type) => {
    switch(type) {
      case "time":
        onChangeSettings({...setting, time: newSettings})
        break
      case "temperature":
        onChangeSettings({...setting, temperature: (Number(newSettings)).toString()})
        break
      case "speed":
        onChangeSettings({...setting, speed: newSettings})
        break
      default: return null
    }
  }

  /* For Dial */
  const handleSelect = (type) => {
    setScroll("hidden")
    if (active===type) {
      setActive(false)
      setScroll("scroll")
      setStyles(prev => ({time: {}, temperature: {}, speed: {}}))
    } else if (type==="time") {
      setActive(prev => "time")
      setStyles(prev => ({temperature: {}, speed: {}, time: { borderRadius: "18px", margin: "0px", backgroundColor: "#F4F4F4"}}))
    } else if (type==="temperature") {
      setActive(prev => "temperature")
      setStyles(prev => ({time: {}, speed: {}, temperature: { borderRadius: "18px", margin: "0px", backgroundColor: "#F4F4F4"}}))
    } else if (type==="speed") {
      setActive(prev =>  "speed")
      setStyles(prev => ({time: {}, temperature: {}, speed: { borderRadius: "18px", margin: "0px", backgroundColor: "#F4F4F4"}}))
    }
  }

  return (
    <Container maxWidth={"50vw"}>
      <Grid container rowSpacing={2} >
        <Grid item xs={4}>
          <DialEditor onChange={(e) => handleSettingsChange(e, "temperature")} title="Temperature" value={Number(temperature)} minValue={20} maxValue={120} icon={<Temperature style={{width: "40px", height: "40px"}}/>} styles={styles.temperature} stepSize={5} unit={"°C"} edit={active} setScroll={setScroll} instructionCount={instructionCount} />
        </Grid>
        <Grid item xs={4}>
          <DialEditor onChange={(e) => handleSettingsChange(e, "time")} title="Time" value={time}  minValue={0} maxValue={3600} icon={<Time style={{width: "40px", height: "40px"}}/>} styles={styles.time} stepSize={time >= 30 ? 5 : 1} edit={active} setScroll={setScroll} instructionCount={instructionCount} />
        </Grid>
        <Grid item xs={4}>
          <DialEditor onChange={(e) => handleSettingsChange(e, "speed")} title="Speed" value={speed}  minValue={0} maxValue={13} icon={<Speed style={{width: "40px", height: "40px"}}/>} styles={styles.speed} stepSize={1} edit={active} setScroll={setScroll} instructionCount={instructionCount} />
        </Grid>
      </Grid>
    </Container>
  )
}

export default SettingDisplay