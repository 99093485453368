import React from 'react'
import './App.css'
import ccTheme from './theme/Experience2Theme'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { AuthProvider } from "./firebase/context"
import NotFound404 from './pages/NotFound404';
import RecipeListing from './pages/RecipeListing';
import RecipeDisp from './pages/RecipeDisp';
import TestHardwareConnect from './pages/TestHardwareConnect';
import IngredientsEditor from './pages/IngredientsEditor';
import NewBluetoothTest from './pages/NewBluetoothTest';
import RecipeDisplay4 from './pages/RecipeDisplay4';
import NewRecipeJson from './pages/NewRecipeJson';
import Experience2Listing from './pages/Experience2Listing'
import HardwareDrive2 from './pages/HardwareDrive2'
import { GlobalSettingsProvider } from './utils/globalSettings/globalSettingsContext';
import AdminPage from './pages/AdminPage';
import HardwareDrive3 from './pages/HardwareDrive3';
import ApolloProviderWrapper from './apollo/ApolloProviderWrapper';
import ErrorManagementProvider from './utils/errorManagement/ErrorManagementProvider';
import { HardwareTask } from './components/hardwareBar/HardwareTasks';

function App() {
  try {
    let wifi = JSON.parse(window.Wifi.getCurrentWifiConnection());

    if (!wifi.BSSID) {
      window.Wifi.openWifiSettings();
    }
  } catch (e) {
    console.log("Errored out while checking for wifi", e)
  }

  return (
    <div className="App">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={ccTheme}>
          <AuthProvider>
            <GlobalSettingsProvider>
              <ApolloProviderWrapper>
                <ErrorManagementProvider>
                  <HardwareTask>
                    <Router>
                      <Switch>
                        <Route exact path="/">
                          <RecipeListing />
                        </Route>
                        <Route exact path="/index.html">
                          <RecipeListing />
                        </Route>
                        <Route exact path="/experience2">
                          <Experience2Listing />
                        </Route>
                        <Route path="/driveHardware" >
                          <HardwareDrive2 hardwarebar />
                        </Route>
                        <Route path="/driveHardware2" >
                          <HardwareDrive3 />
                        </Route>
                        <Route path="/experience2/:id">
                          <RecipeDisplay4 />
                        </Route>
                        <Route path="/recipelist">
                          <RecipeListing ingredientsOnly />
                        </Route>
                        <Route path="/testhardware">
                          <TestHardwareConnect />
                        </Route>
                        <Route path="/ingredients/:recipeId">
                          <RecipeDisp ingredientsOnly />
                        </Route>
                        <Route exact path="/ingredientsadmin" >
                          <IngredientsEditor />
                        </Route>
                        <Route path="/ingredientsadmin/:id" >
                          <IngredientsEditor />
                        </Route>
                        <Route path="/newjson/:recipeId">
                          <NewRecipeJson />
                        </Route>
                        <Route path="/newjson">
                          <NewRecipeJson />
                        </Route>
                        <Route path="/bluetest" >
                          <NewBluetoothTest />
                        </Route>
                        <Route path="/admin" >
                          <AdminPage />
                        </Route>
                        <Route>
                          <NotFound404 />
                        </Route>
                      </Switch>
                    </Router>
                  </HardwareTask>
                </ErrorManagementProvider>
              </ApolloProviderWrapper>
            </GlobalSettingsProvider>
          </AuthProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </div >
  );
}

export default App;
