export const initialObject = {
  metadata: {
    name: "New Recipe",
    prepTime: 10,
    cookTime: 25,
    active: false,
    description: "A super fast recipe",
  },
  steps: [
    {
      name: "Cook Recipe",
      id: "",
      subSteps: [
        {
          type: "scrape",
          id: "",
          name: "",
          parameters: {},
        },
      ],
    },
  ],
};

export const defaultStep = {
  name: "Cook Recipe",
  id: "",
  subSteps: [
    {
      type: "saute",
      id: "",
      name: "",
      parameters: {
        settings: [
          {
            temperature: "90",
            time: 180,
            speed: 0,
          },
        ],
        cap: "on",
      },
    },
  ],
};

export const defaultSubStep = {
  type: "saute",
  id: "",
  name: "",
  parameters: {
    settings: [
      {
        temperature: "90",
        time: 180,
        speed: 0,
      },
    ],
    cap: "on",
  },
};

export const defaultUrlUtility = {
  url: "",
  muted: true,
  loop: true,
  duration: 10,
  startTime: 10,
};

export const defaultImgUtility = {
  title: "",
  url: "",
  style: {},
  type: "",
  weigh: 0,
  settings: [{
    time: 0,
    temperature: "90",
    speed: 0,
  }],
}

export const defaultSettings = [
  {
    temperature: "90",
    time: 180,
    speed: 0,
  },
];
