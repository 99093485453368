
import React from "react"
import { Box, Button, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PropTypes from 'prop-types'

const PageTitle = ({ title, onClickBack = () => { }, onClickNext = () => { }, displayNextBtn = true, children }) => {
    return (
        <Box display="flex" flexDirection="column">
            <Box style={{
                padding: "1%",
                display: "flex",
                backgroundColor: "#ffffff",
                zIndex: 2,
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                boxSizing: "border-box",
                height: "11vh"
            }
            } >
                <Button
                    style={{
                        color: "black",
                        padding: 0
                    }}
                    onClick={onClickBack}
                    size="large">
                    <ArrowBackIcon style={{ fontSize: 50 }} />
                </Button>
                <Typography variant="h4" style={{ textAlign: "center" }}>{title}</Typography>
                {displayNextBtn ? <Button
                    color="primary"
                    variant="contained"
                    style={{
                        borderRadius: 10,
                        width: "10%",
                        boxShadow: "none"
                    }}
                    onClick={onClickNext}
                    size="large">
                    <ArrowBackIcon style={{ fontSize: 50, transform: "rotate(180deg)" }} />
                </Button> :
                    <Box width="10%" />}
            </Box >
            {children}
        </Box >
    );
}

PageTitle.propTypes = {
    title: PropTypes.string,
    onClickBack: PropTypes.func,
    onClickNext: PropTypes.func,
    displayNextBtn: PropTypes.bool,
    children: PropTypes.element
}

export default PageTitle