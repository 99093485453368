import React from "react";
import StepsDescription from "../StepsDescription";
import PropTypes from 'prop-types';
import InstructionBox from "../InstructionBox";
import { nonTimerInstruction, videos, titles } from '../../../utils/experience2/typesVariable';

const NonTimerInstructionType = ({ status, text, type, parameters }) => {
    return (<StepsDescription
        status={status}
        details={<InstructionBox text={text || titles[type]} url={parameters?.url ? parameters.url : videos[type]} />}
    />)
}

nonTimerInstruction.propTypes = {
    parameters: PropTypes.object,
    title: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
    video: PropTypes.string
}

export default NonTimerInstructionType