import React from "react"
import { Accordion, AccordionSummary, Typography, Box, AccordionDetails } from "@mui/material"
import PropTypes from "prop-types"

const AccordionCeption = ({ children, title }) => {
    return (<Accordion>
        <AccordionSummary><Typography variant="h6">{title ? title : "Click to expand"}</Typography></AccordionSummary>
        <AccordionDetails>
            <Box display="flex" flexDirection="column" style={{ gap: 15 }}>
                {children}
            </Box>
        </AccordionDetails>
    </Accordion>)
}

AccordionCeption.propTypes = {
    children: PropTypes.element,
    title: PropTypes.string
}

export default AccordionCeption