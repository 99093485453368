import { Box } from "@mui/material";
import React, { useContext, useState } from "react"
import { IngredientsContext } from "../ingredients2/IngredientsFromDB";
import PrepModal from "../ingredients2/PrepModal";
import PropTypes from 'prop-types'
import { logEvents } from "../../utils/logging";
import DefaultListItemDisplay from "./DefaultListItemDisplay";
import { getIngredientsData } from "../../utils/experience2/ingredientsUtil";
import { GlobalSettingsContext } from "../../utils/globalSettings/globalSettingsContext"

const List = ({ items = [] }) => {
    const { ingredients } = useContext(IngredientsContext)
    const { globalSettings: { user } } = useContext(GlobalSettingsContext)
    const [modalId, setModalId] = useState(null)
    const displayedIngredients = items.map(i => getIngredientsData(i, ingredients))
    return (<Box className="list">
        {displayedIngredients.map(({ id, displayName, weighScaleAmount, prep, prevPrep, image, nutrientInfo, img }) =>
            <Box
                key={id}
                className="list-item"
                onClick={() => {
                    if (!prevPrep) {
                        const sessionId = sessionStorage.getItem("sessionId")
                        logEvents(sessionId, "PREP_OPENED", { id, displayName, weighScaleAmount, user })
                        setModalId(id)
                    }
                    return
                }}
            >
                <DefaultListItemDisplay
                    label={displayName}
                    prep={prep}
                    weighScale={weighScaleAmount}
                    nutrientInfo={nutrientInfo}
                    img={img}
                />
                <PrepModal
                    ingredient={{ displayName, image }}
                    weight={weighScaleAmount}
                    prep={prep}
                    open={modalId === id}
                    onClose={e => {
                        e.stopPropagation()
                        setModalId(null)
                    }}
                />
            </Box>
        )}
    </Box>)
}

List.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        ingredientId: PropTypes.string,
        amount: PropTypes.shape({
            qty: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            unit: PropTypes.string
        }),
        prep: PropTypes.object //change to shape
    }))
}

export default List