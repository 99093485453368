import React from "react"
import { Box, Typography } from "@mui/material"
import PropTypes from 'prop-types';
import WeightBtn from "../ingredients2/WeightBtn";

const DefaultListItemDisplay = ({ label, prep, weighScale, nutrientInfo, img }) => {
    return (<>
        <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center" style={{ gap: 5 }}>
                <Typography variant="caption">{label}</Typography>
                {weighScale?.defaultVal ? <Typography variant="body2" style={{ lineHeight: 0.5 }}>{`x ${weighScale.defaultVal}`}</Typography> : null}
            </Box>
            <Typography variant="body2" style={{ lineHeight: 0.5, color: "#585858" }}>{prep?.displayName}</Typography>
        </Box>
        {weighScale?.displayBtn ? <WeightBtn weight={weighScale?.value} btnClass="prep-ing-btn" nutrientInfo={nutrientInfo} img={img} /> : <Typography variant="body2" style={{ textAlign: "center", width: "30%" }}>{weighScale?.value}</Typography>}
    </>)
}

DefaultListItemDisplay.propTypes = {
    label: PropTypes.string,
    prep: PropTypes.instanceOf(Object),
    weighScale: PropTypes.instanceOf(Object),
    nutrientInfo: PropTypes.instanceOf(Object),
    img: PropTypes.string,
}

export default DefaultListItemDisplay