import { Typography, Box } from "@mui/material";
import React from "react";
import PopUpModal from "../PopUpModal";
import PropTypes from "prop-types"

const AlternateIngredientsModal = ({ items, open, onClose, selectIngredient, selectedIngredientId }) => {
    return (<>
        {items && <PopUpModal
            open={open}
            onClose={onClose}
        >
            <Box style={{ padding: "10px 0", boxSizing: "border-box" }}>
                {items.map(({ displayName, image, id }) => <Box
                    key={id}
                    display="flex"
                    style={selectedIngredientId === id ? { gap: 20, alignItems: "center", backgroundColor: "#DAECD8", padding: "0 20px" } : { gap: 20, alignItems: "center", padding: "0 20px" }}
                    onClick={e => {
                        e.stopPropagation()
                        onClose()
                        selectIngredient(id)
                    }}>
                    <img src={image} style={{ width: 100, height: 100 }} alt="ingredient" />
                    <Typography variant="button">{displayName}</Typography>
                </Box>)}
            </Box>
        </PopUpModal>}
    </>)
}

AlternateIngredientsModal.propTypes = {
    items: PropTypes.instanceOf(Array),
    oprn: PropTypes.bool,
    onClose: PropTypes.func,
    selectIngredient: PropTypes.func,
    selectedIngredientId: PropTypes.string
}

export default AlternateIngredientsModal