import React from 'react'
import PropTypes from 'prop-types'
import { Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import R1SerialSettings from '../customInputs/R1SerialSettings'


const RunR1 = ({ parameters, setParameters, localRecipeJson: recipe }) => {
    const { settings, cap } = parameters || {}
    const setCap = newValue => setParameters({ ...parameters, cap: newValue })
    const setSettings = newSettings => setParameters({ ...parameters, settings: newSettings })
    const setStepId = newStepId => setParameters({ ...parameters, parallelPrep: { ...parameters.parallelPrep, stepId: newStepId } })
    const setSubStepId = newSubStepId => setParameters({ ...parameters, parallelPrep: { ...parameters.parallelPrep, subStepId: newSubStepId } })
    const setDescription = newDescription => setParameters({ ...parameters, parallelPrep: { ...parameters.parallelPrep, description: newDescription } })

    const stepsId = recipe?.steps?.map(i => { return { id: i?.id, name: i.name } })
    const subStepIds = recipe?.steps?.map(i => {
        return { id: i?.id, subSteps: i?.subSteps?.map(v => { return { id: v.id, name: v.name, type: v.type } }) }
    })
    const getSubStepsId = (stepId) => {
        return subStepIds.find((subStep) => (subStep.id === stepId))?.subSteps
    }
    const stepIsPresent = (stepId) => {
        return stepsId.find((i) => i.id === stepId)
    }

    return (
        <Grid container alignItems={"center"}>
            <Grid item xs={12}>
                <Select
                    value={cap}
                    onChange={e => setCap(e.target.value)}
                >
                    <MenuItem value="on">Cap On</MenuItem>
                    <MenuItem value="off">Cap Off</MenuItem>
                </Select>
            </Grid>
            <Grid item xs={12}>
                <R1SerialSettings settings={settings} setSettings={setSettings} />
            </Grid>
            <Grid item xs={12}>
                <Typography>Parallel Preparation</Typography>
            </Grid>
            <Grid item xs={6}>
                <InputLabel id="demo-simple-select-label">StepId</InputLabel>
                <Select
                    value={parameters?.parallelPrep?.stepId || "None"}
                    onChange={e => setStepId(e.target.value)}
                    label="Step Id"
                >
                    <MenuItem value={{}}>None</MenuItem>
                    {stepsId.map(i => (
                        <MenuItem key={i.id} value={i.id}>{i.name}</MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item xs={6}>
                <InputLabel id="demo-simple-select-label">SubStepId</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="SubStep Id"
                    value={parameters?.parallelPrep?.subStepId || "None"}
                    onChange={e => setSubStepId(e.target.value)}
                >
                    <MenuItem value={{}}>None</MenuItem>
                    {stepIsPresent(parameters?.parallelPrep?.stepId) && getSubStepsId(parameters?.parallelPrep?.stepId).map(i => (
                        <MenuItem key={i.id} value={i.id}>{i.name || `SubStep#${i.type}`}</MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item>
                <TextField
                    fullWidth
                    label="Description"
                    value={parameters?.parallelPrep?.description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </Grid>
        </Grid>
    )
}

RunR1.propTypes = {
    parameters: PropTypes.object,
    setParameters: PropTypes.func,
    localRecipeJson: PropTypes.object
}

export default RunR1