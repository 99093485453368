import React from "react";
import StepsDescription from "../StepsDescription";
import PropTypes from 'prop-types';
import List from "../List";
import { titles } from "../../../utils/experience2/typesVariable";

const DescriptionType = ({ parameters, title, status, type }) => {
    return (<StepsDescription
        title={title || titles[type]}
        status={status}
        details={<List items={parameters.items} />}
        button={null}
    />
    )
}

DescriptionType.propTypes = {
    parameters: PropTypes.object,
    title: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string
}

export default DescriptionType