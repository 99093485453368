import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked"
import { Grid, Box, Typography } from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import React, { useContext } from "react"
import { FilterContext } from "../FilterContext"

const Categories = ({ selectOptions, selectType}) => {
  const [options, setOptions] = useContext(FilterContext)

  const handleChange = (event, selectType) => {
    // If not present in the categories list, include it in the list
    if (!options[selectType].includes(event.target.value)) {
      setOptions((prevOptions) => ({
        ...prevOptions,
        [selectType]: [...prevOptions[selectType], event.target.value],
      }))
    } else {
      // Remove it from the categories list
      setOptions((prevOptions) => ({
        ...prevOptions,
        [selectType]: prevOptions[selectType].filter(
          (i) => i !== event.target.value
        ),
      }))
    }
  }

  const controlProps = (item, selectType) => ({
    checked: options[selectType].includes(item),
    onClick: (e) => handleChange(e, selectType),
    value: item,
    name: "size-checkbox-button-demo",
    inputProps: { "aria-label": item },
  })

  return (
    <Box>
      <Typography variant="h6" color={"primary"} style={{marginBottom: 2}}>
        {selectType.toUpperCase()}
      </Typography>
      <Grid container direction="row" justifyContent="space-between">
        {selectOptions.map((item, i) => (
          <Grid item xs={12} md={6} key={item} >
            <Box 
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Typography>{item}</Typography>
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleOutlineIcon />}
                {...controlProps(item, selectType)}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default Categories
