import React from "react";
import PropTypes from 'prop-types';

const ImageUtilityBox = ({ src, style }) => {
    return (
        <img
            src={src}
            width="100%"
            style={{ paddingBottom: "2%", ...style }}
            alt="UtilityBox"
        />)
}

ImageUtilityBox.propTypes = {
    src: PropTypes.string,
    style: PropTypes.object
}

export default ImageUtilityBox