import { Button, Typography, Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { HardwareContext, StateOfHardwareContext } from "../../hardwareBar/HardwareTasks";
import PropTypes from 'prop-types';
import R1BtnPopUp from "./R1BtnPopUp";
import { errorUncontrolledIssueUrl, resumeSoundUrl, timerEndUrl } from "../../../audioUrls/audioUrl";
import useCookClubAudio from "../../../utils/audio/cookClubAudio";

const R1Buttons = ({ dispatch, progress, buttonStatus, subStepId, stepName, btFirstConnect, scroll = () => { }, stepId }) => {
    const { connected, triggerR1, pauseR1, resumeR1, lidOn, jarOn, forceStop } = useContext(HardwareContext)
    const stateOfHardware = useContext(StateOfHardwareContext)
    const [popUp, setPopUp] = useState(false)
    const [showSkippedStepDialog, setShowSkippedStepDialog] = useState(false)
    const [resumeSound] = useCookClubAudio(resumeSoundUrl)
    const [stopSound] = useCookClubAudio(errorUncontrolledIssueUrl)
    const [pauseSound] = useCookClubAudio(errorUncontrolledIssueUrl)
    const [skipSound] = useCookClubAudio(errorUncontrolledIssueUrl)
    const [timerEndSound] = useCookClubAudio(timerEndUrl)

    const runR1status = () => {
        return (connected && jarOn === "on" && lidOn === "on")
    }

    const onClickResume = () => {
        dispatch({ type: "CHANGE_PROGRESS_TO_IN_PROGRESS", data: { subStepId, resumeR1 } })
        resumeSound()
        changePopUpState()
    }

    const onClickPause = () => {
        pauseR1()
        pauseSound()
        changePopUpState()
        dispatch({ type: "CHANGE_PROGRESS_TO_PAUSED", data: { subStepId } })
    }

    const changePopUpState = () => {
        setPopUp(old => !old)
    }

    const goToFirstSkippedStep = () => {
        dispatch({ type: "GO_TO_CURRENT_STEP", data: { scroll } })
        setShowSkippedStepDialog(false)
    }

    const onClickStop = () => {
        forceStop()
        stopSound()
        changePopUpState()
    }

    const triggerSettingsFunction = () => {
        dispatch({ type: "TRIGGER_R1", data: { triggerR1, subStepId, stepId } })
    }

    const onClickSkip = () => {
        triggerSettingsFunction()
        setShowSkippedStepDialog(false)
    }

    const handleTriggerRunSteps = () => {
        if (["start", "resume", "completed"].includes(buttonStatus)) {
            triggerSettingsFunction()
        } else {
            setShowSkippedStepDialog(true)
            skipSound()
        }
    }

    const closeOnClickOutside = () => {
        if (progress !== "paused-on-disconnect") {
            dispatch({ type: "CHANGE_PROGRESS_TO_PAUSED", data: { subStepId } })
        }
        pauseSound()
        changePopUpState()
    }

    useEffect(() => {
        if (!connected && btFirstConnect && progress === "in-progress") {
            dispatch({ type: "CHANGE_PROGRESS_TO_PAUSED_ON_DISCONNECT" })
            setPopUp(true)
        }
        if ((lidOn === "off" && progress === "in-progress") || (jarOn === "off" && progress === "in-progress")) {
            onClickPause()
        }
    }, [connected, btFirstConnect, lidOn, jarOn])

    useEffect(() => {
        if (stateOfHardware === "idle") {
            dispatch({ type: "ON_SUBSTEPS_COMPLETION" })
            timerEndSound()
        }
    }, [stateOfHardware])

    switch (progress) {
        case "in-progress": {
            return (<>
                <Button
                    className="r1-btn pause-btn"
                    onClick={onClickPause}
                >
                    Pause
                </Button>
                <R1BtnPopUp
                    open={popUp}
                    title={"Paused"}
                    btnTitle={"Stop"}
                    btnStyle={"stop-btn"}
                    description={<>
                        <Typography variant="body2">
                            {`The R1 has been paused on "${stepName}".`}
                        </Typography>
                        <Typography variant="body2">
                            Tap on Resume to continue cooking.
                        </Typography></>}
                    btn={<Button
                        className={!connected ? "r1-btn dialog-btn-dimension disable-r1" : "r1-btn dialog-btn-dimension"}
                        variant="contained"
                        onClick={onClickResume}
                        style={{ boxShadow: "none" }}
                        disabled={!runR1status()}
                    >
                        <Typography variant="button">Resume</Typography>
                    </Button>}
                    onClickStop={onClickStop}
                    closeOnClickOutside={closeOnClickOutside}
                    disableBtn={!runR1status()}
                />
            </>)
        }
        case "paused-on-disconnect": {
            return (<>
                <Typography variant="button">R1 is disconnected. Connect and then start R1.</Typography>
                <Box display="flex" justifyContent="space-between" width="100%">
                    <Button
                        className={!runR1status() ? "r1-btn non-dialog-resume-stop-dimension disabled-r1" : "r1-btn non-dialog-resume-stop-dimension"}
                        variant="contained"
                        onClick={triggerSettingsFunction}
                        style={{ boxShadow: "none" }}
                        disabled={!runR1status()}
                    >
                        <Typography variant="button">Re-start R1</Typography>
                    </Button>
                    <Button
                        className="r1-btn stop-btn non-dialog-resume-stop-dimension"
                        onClick={onClickStop}
                        disabled={!runR1status()}
                    >
                        <Typography variant="button">Stop</Typography>
                    </Button>
                </Box>
                <R1BtnPopUp
                    open={popUp}
                    title={"Disconnected"}
                    description={<Typography variant="body2">
                        R1 is disconnected. Connect and then start R1.
                    </Typography>}
                    btn={<Button
                        className={!runR1status() ? "r1-btn dialog-btn-dimension disable-r1" : "r1-btn dialog-btn-dimension"}
                        variant="contained"
                        onClick={triggerSettingsFunction}
                        style={{ boxShadow: "none" }}
                        disabled={!runR1status()}
                    >
                        <Typography variant="button">Re-start R1</Typography>
                    </Button>}
                    onClickStop={onClickStop}
                    closeOnClickOutside={closeOnClickOutside}
                    disableBtn={!runR1status()}
                />
            </>)
        }
        case "paused": {
            return (<Box display="flex" flexDirection="column">
                <Typography variant="button">Paused, please press Resume to resume cooking.</Typography>
                <Box display="flex" justifyContent="space-between" width="100%">
                    <Button
                        className={!runR1status() ? "r1-btn non-dialog-resume-stop-dimension disabled-r1" : "r1-btn non-dialog-resume-stop-dimension"}
                        variant="contained"
                        onClick={onClickResume}
                        style={{ boxShadow: "none" }}
                        disabled={!runR1status()}
                    >
                        <Typography variant="button">Resume</Typography>
                    </Button>
                    <Button
                        className="r1-btn stop-btn non-dialog-resume-stop-dimension"
                        onClick={onClickStop}
                        disabled={!runR1status()}
                    >
                        <Typography variant="button">Stop</Typography>
                    </Button>
                </Box>
                <R1BtnPopUp
                    open={popUp}
                    title={"Paused"}
                    btnTitle={"Stop"}
                    btnStyle={"stop-btn"}
                    description={<>
                        <Typography variant="body2">
                            {`The R1 has been paused on "${stepName}".`}
                        </Typography>
                        <Typography variant="body2">
                            Tap on Resume to continue cooking.
                        </Typography></>}
                    btn={<Button
                        className={!connected ? "r1-btn dialog-btn-dimension disable-r1" : "r1-btn dialog-btn-dimension"}
                        variant="contained"
                        onClick={onClickResume}
                        style={{ boxShadow: "none" }}
                        disabled={!runR1status()}
                    >
                        <Typography variant="button">Resume</Typography>
                    </Button>}
                    onClickStop={onClickStop}
                    closeOnClickOutside={closeOnClickOutside}
                    disableBtn={!runR1status()}
                />
            </Box>)
        }
        default: {
            return (
                <>
                    <Button
                        className={!runR1status() || !(["idle", "Paused"].includes(stateOfHardware)) ? "start-btn r1-btn disabled-r1" : "start-btn r1-btn"}
                        disabled={!runR1status() || !(["idle", "Paused"].includes(stateOfHardware))}
                        onClick={handleTriggerRunSteps}
                    >
                        <Typography variant="button">Start R1</Typography>
                    </Button>
                    <R1BtnPopUp
                        open={showSkippedStepDialog}
                        title={"Skipped a Step"}
                        btnTitle={"Skip It"}
                        btnStyle={"skip-btn"}
                        description={<>
                            <Typography variant="body2">
                                {`You seemed to have skipped some instructions in the "${stepName}".`}
                            </Typography>
                            <Typography variant="body2">
                                {`"${stepName}" step.`}
                            </Typography></>}
                        btn={<Button
                            className={!connected ? "r1-btn dialog-btn-dimension disable-r1" : "r1-btn dialog-btn-dimension"}
                            variant="contained"
                            onClick={goToFirstSkippedStep}
                            style={{ boxShadow: "none" }}
                            disabled={!connected}
                        >
                            <Typography variant="button">Go To Step</Typography>
                        </Button>}
                        onClickStop={onClickSkip}
                        closeOnClickOutside={() => setShowSkippedStepDialog(false)}
                        disableBtn={!runR1status()}
                    />
                </>)
        }
    }
}

R1Buttons.propTypes = {
    dispatch: PropTypes.func,
    inProgress: PropTypes.bool,
    stepId: PropTypes.string
}

export default R1Buttons