import { gql, useMutation } from '@apollo/client'
import { v4 as uuid } from 'uuid'
import { recipeTransformerFS2QL } from './hasuraFSMigrationTransformers'

const ADD_RECIPE = gql`
mutation AddNewRecipe($Recipe_ID: String = "", $Active: Boolean = false, $Cooking_Time: numeric = "", $Description: String = "", $Diet: jsonb = "", $Recipe_Name: String = "", $cuisine: jsonb = "", $image_url: String = "", $prep_time: numeric = "", $steps: jsonb = "", $author: String = "", $editor: String = "", $lastEditedAt: String = "", $maxServing: Int = 2, $minServing: Int = 2) {
  insert_Recipe_DB_one(object: {Active: $Active, Cooking_Time: $Cooking_Time, Description: $Description, Diet: $Diet, Recipe_Name: $Recipe_Name, cuisine: $cuisine, image_url: $image_url, prep_time: $prep_time, steps: $steps,Recipe_ID: $Recipe_ID,author: $author, editor: $editor, lastEditedAt: $lastEditedAt, maxServing: $maxServing, minServing: $minServing}) {
    Recipe_ID
    Recipe_Name
  }
}`

const useNewRecipe = () => {
  const [addRecipe, { data: newRecipe, loading, error }] = useMutation(ADD_RECIPE)

  const saveNew = (newRecipe, onSaveCallBack = () => { }) => {
    addRecipe({ variables: recipeTransformerFS2QL(newRecipe, uuid()) }).then(
      ({ data }) => onSaveCallBack(data.insert_Recipe_DB_one.Recipe_ID)
    )
  }

  return { saveNew, saving: loading }
}

export default useNewRecipe