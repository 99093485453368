import React from "react"
import { Button, SvgIcon } from "@mui/material"
import { useHistory } from 'react-router-dom'
import { ReactComponent as ReactLogo } from "../../svg/reactLogo.svg"

const ReactIcon = () => {
    const history = useHistory()
    const onLogoClick = () => history.push("/")

    return (<Button onClick={onLogoClick} sx={{marginBottom: "5px"}}>
        <SvgIcon component={ReactLogo} viewBox="0 0 1131.47 1441" sx={{ fontSize: 35, color: "black" }} />
    </Button>)
}

export default ReactIcon