import React, { useState } from 'react'
import { getDisplayType } from '../../utils/experience2/stepsUtils';
import {
    DISPLAY_DESCRIPTION,
    DISPLAY_EXTRA_PREP,
    DISPLAY_TIMER_INSTRUCTION,
    DISPLAY_NON_TIMER_INSTRUCTION,
    DISPLAY_UTILITIES,
    URL_UTILITY,
    CRITICAL_STEP,
    RATING_COLLECTOR
} from '../../utils/experience2/displayTypes';
import PropTypes from 'prop-types';
import DescriptionType from './subSteps/DescriptionType';
import NonTimerInstructionType from './subSteps/NonTimerInstructionType';
import UtilityType from './subSteps/UtilityType';
import ExtraPrepType from './subSteps/ExtraPrep';
import TimerInstructionType from './subSteps/TimerInstructionType';
import UrlUtilityType from './subSteps/UrlUtitilityType';
import CriticalType from './subSteps/CriticalType';
import RatingCollectorType from './subSteps/RatingCollectorType';

const DynamicSubStep = ({ type, parameters, status, dispatch, subStepId, name, hardwareStateMemo, scroll, stepId }) => {
    const [expanded, setExpanded] = useState(null)
    const changeExpanded = () => setExpanded(old => {
        if (old?.id === subStepId)
            return { ...old, expand: !old.expand }
        return { id: subStepId, expand: true }
    })

    switch (getDisplayType(type)) {
        case DISPLAY_DESCRIPTION: {
            return (<DescriptionType
                title={name}
                parameters={parameters}
                status={status}
                type={type}
                stepId={stepId}
            />)
        }
        case DISPLAY_NON_TIMER_INSTRUCTION: {
            return (<NonTimerInstructionType
                status={status}
                parameters={parameters}
                text={name}
                type={type}
                stepId={stepId}
            />)
        }
        case DISPLAY_UTILITIES: {
            return (<UtilityType
                parameters={parameters}
                status={status}
                title={name}
                type={type}
                dispatch={dispatch}
                subStepId={subStepId}
                stepId={stepId}
            />)
        }
        case DISPLAY_EXTRA_PREP: {
            return (<ExtraPrepType
                title={name}
                status={status}
                parameters={parameters}
                stepId={stepId}
            />)
        }
        case DISPLAY_TIMER_INSTRUCTION: {
            return (
                <TimerInstructionType
                    parameters={parameters}
                    title={name}
                    type={type}
                    status={status}
                    subStepId={subStepId}
                    changeExpanded={changeExpanded}
                    dispatch={dispatch}
                    expanded={expanded}
                    hardwareStateMemo={hardwareStateMemo}
                    scroll={scroll}
                    stepId={stepId}
                />)
        }
        case URL_UTILITY: {
            return <UrlUtilityType
                parameters={parameters}
                title={name}
                type={type}
                status={status}
                stepId={stepId}
            />
        }
        case CRITICAL_STEP: {
            return <CriticalType
                parameters={parameters}
                title={name}
                type={type}
                status={status}
                stepId={stepId}
            />
        }
        case RATING_COLLECTOR: {
            return <RatingCollectorType
                parameters={parameters}
                title={name}
                subStepId={subStepId}
                stepId={stepId}
            />
        }
        default: return null
    }
}

DynamicSubStep.propTypes = {
    type: PropTypes.string,
    parameters: PropTypes.object, //change to shape
    status: PropTypes.string,
    dispatch: PropTypes.func,
    id: PropTypes.string
}

export default DynamicSubStep