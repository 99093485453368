import React from "react"
import { TextField } from "@mui/material"
import PropTypes from "prop-types"

const NumberField = ({ name, onChange, required = false, data, delBtn = null }) => {
    return (
        <TextField
            key={name} name={name} onChange={onChange} type="number" required={required} value={data}
        />
    )
}

NumberField.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    data: PropTypes.number,
    delBtn: PropTypes.element
}

export default NumberField