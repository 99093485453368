import { List, ListItem, ListItemText } from "@mui/material";
import React, { useState } from "react";
import { createNewData } from "./utils";
import PropTypes from "prop-types";
import SearchBar from "./components/SearchBar";
import ButtonItem from "./components/ButtonItem";

const IngredientsNameDisplay = ({ data, onClick, schema, onAdd }) => {
    const [search, setSearch] = useState(null)
    return (
        <>
            <SearchBar search={search} setSearch={setSearch} />
            <List style={{ overflowY: "scroll" }}>
                {data.map(({ displayName, id }) =>
                    <>
                        {!search || displayName.toLowerCase().includes(search.toLowerCase()) ? <ListItem button key={displayName} onClick={() => onClick(id)}>
                            <ListItemText primary={displayName} />
                        </ListItem> : null}
                    </>
                )}
            </List>
            <ButtonItem onClick={() => {
                const data = createNewData(schema)
                onAdd(data)
            }} name="Add" />
        </>
    )
}

IngredientsNameDisplay.propTypes = {
    data: PropTypes.object,
    onClick: PropTypes.func,
    schema: PropTypes.object,
    onAdd: PropTypes.func
}

export default IngredientsNameDisplay