import { Box, Button, Typography } from "@mui/material";
import React, { useContext } from "react"
import StepsLabel from "./stepsLabel/StepsLabel";
import "../../css/steps.css"
import DynamicSubStep from "./DynamicSubStep"
import PropTypes from "prop-types";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { getIndexOfSteps } from "../../utils/experience2/stepsUtils";
import useScrollFeature from "../ScrollFeature";
import useSubStepScrollFeature from "../SubStepScrollFeature";
import { StateOfHardwareContext } from "../hardwareBar/HardwareTasks";

const subStepOffSet = -180

const getLabelParentClassName = (status, view, stateOfHardware) => {
    let className = "description description-not-completed"
    if (stateOfHardware === "operation") className = `${className} in-progress-color`
    if (status === "completed") className = `${className} description-completed`
    if (view === "wide") className = `${className} instruction-reduced-view`
    return className
}

const Steps = ({ recipe, recipeDispatch, hardwareStateMemo }) => {
    const stateOfHardware = useContext(StateOfHardwareContext)

    function dispatch({ type, data }) {
        recipeDispatch({ type: "FROM_STEPS_REDUCER", data: { ...data, type } })
    }

    const { steps: recipeData, view, inFocusSubstepId } = recipe

    const { subSteps: activeSubSteps, status, description, id: stepId } = recipeData.find(step => step.active)
    const { scroll,
        createParentRef,
        createChildrenRef,
        triggerScroll,
        subStepScrollBehavior } = useScrollFeature()
    const subStepIndex = getIndexOfSteps(activeSubSteps, inFocusSubstepId)
    const { createSubStepParentRef,
        createSubStepChildrenRef } = useSubStepScrollFeature(triggerScroll, subStepIndex, subStepOffSet, subStepScrollBehavior)
    return (
        <Box
            height="89vh"
            display="flex"
            justifyContent="space-between"
        >
            <Box
                height="100%"
                display="flex"
                className={view === "default" ? "label-full-view" : "label-reduced-view"}
            >
                <StepsLabel
                    steps={recipeData}
                    recipeDispatch={dispatch}
                    displayStepNames={view === "default"}
                    scroll={scroll}
                    createParentRef={createParentRef}
                    createChildrenRef={createChildrenRef}
                />
                <Box>
                    <Button onClick={() => {
                        dispatch({ type: "CHANGE_VIEW" })
                    }}
                        variant=""
                        sx={{ padding: 0 }}
                    >
                        <DoubleArrowIcon style={{
                            transform: view === "wide" ? "" : "rotate(-180deg)",
                            fontSize: 50,
                            zIndex: 2,
                            transition: "all 0.25s ease"
                        }} />
                    </Button>
                </Box>
            </Box>
            <Box
                className={getLabelParentClassName(status, view, stateOfHardware, activeSubSteps.find(i => ["paused", "in-progress"].includes(i?.progress)))}
            >
                <Box
                    ref={el => createSubStepParentRef(el)}
                    className="instruction"
                >
                    <Typography variant="body2">{description}</Typography>
                    {activeSubSteps.map(({ type, parameters, id, progress, name }, index) =>
                        <Box
                            key={id}
                            ref={el => createSubStepChildrenRef(el, index)}
                        >
                            <DynamicSubStep
                                type={type}
                                subStepId={id}
                                name={name}
                                parameters={parameters}
                                dispatch={dispatch}
                                status={progress}
                                hardwareStateMemo={hardwareStateMemo}
                                scroll={scroll}
                                stepId={stepId}
                            />
                        </Box>
                    )
                    }
                </Box >
            </Box >
        </Box >
    )
}

Steps.propTypes = {
    recipe: PropTypes.object.isRequired,
    recipeDispatch: PropTypes.func.isRequired
}

export default Steps