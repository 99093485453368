import React from 'react'
import { Grid, Typography, Button } from "@mui/material"
import PopUpModal from "../PopUpModal"
import PropTypes from "prop-types"

const CheckDevicePlacement = ({ open, onClose, title, description, onClick }) => {
    return (
        <PopUpModal open={open} onClose={onClose}>
            <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
                <Grid item margin={"40px"}>
                    <Typography variant="h4" >{title}</Typography>
                </Grid>
                <Grid item margin="30px">
                    <Typography variant="body1">{description}</Typography>
                    <Typography variant="body1" textAlign={"center"}>you proceed</Typography>
                </Grid>
                <Grid item marginTop={"20px"}>
                    <Button onClick={onClick} sx={{ border: "3px solid", padding: "5px 20px" }} color="secondary" >
                        <Typography variant="h3">How ?</Typography>
                    </Button>
                </Grid>
            </Grid>
        </PopUpModal>
    )
}

CheckDevicePlacement.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    data: PropTypes.instanceOf(Object),
    onClick: PropTypes.func,
}

export default CheckDevicePlacement