import React, { useContext, useState } from "react";
import { Box } from "@mui/material";
import CheckDevicePlacement from "./CheckDevicePlacement";
import VideoDialog from "./VideoDialog";
import { HardwareContext } from "./HardwareTasks";
import { ReactComponent as ContainerDeviceOff } from "../../svg/hardwareBar/containerDeviceOff.svg"
import { ReactComponent as ContainerJarLidOff } from "../../svg/hardwareBar/containerJarLidOff.svg"
import { ReactComponent as ContainerJarOnLidOff } from "../../svg/hardwareBar/containerJarOnLidOff.svg"
import { ReactComponent as ContainerJarOnLidOn } from "../../svg/hardwareBar/containerJarOnLidOn.svg"

const jarTitle = "Check the Jar"
const jarDescription = "Set the Jar into the R1 firmly before"
const jarVideoTitle = "Positioning the Jar"
const jarVideoDescription = "Put the jar in position as shown in the video"
const jarUrl = "https://res.cloudinary.com/richeek/video/upload/v1652961387/Jar-how_mzfsj0.mp4"

const lidTitle = "Check the lid"
const lidDescription = "Set the lid into the R1 jar firmly before"
const lidVideoTitle = "Positioning the Lid"
const lidVideoDescription = "Put the lid in position as shown in the video"
const lidUrl = "https://res.cloudinary.com/richeek/video/upload/v1652961551/Lid-how_bgixxp.mp4"

const styles = {
    margin: "10px 0"
}

const ContainerIcon = () => {
    const { jarOn, lidOn, connected } = useContext(HardwareContext)
    const [lidOffDialogOpen, setLidOffDialogOpen] = useState(false)
    const [jarOffDialogOpen, setJarOffDialogOpen] = useState(false)
    const [lidVideoDialogOpen, setLidVideoDialogOpen] = useState(false)
    const [jarVideoDialogOpen, setJarVideoDialogOpen] = useState(false)

    const handleButtonClick = (prop) => {
        if (prop === 'lid') {
            setLidOffDialogOpen(false)
            setLidVideoDialogOpen(true)
        }
        if (prop === 'jar') {
            setJarOffDialogOpen(false)
            setJarVideoDialogOpen(true)
        }
    }

    if (!connected || lidOn === "loading" || jarOn === "loading")
        return (<Box sx={styles}><ContainerDeviceOff/></Box>)
    else if (jarOn === "on" && lidOn === "off")
        return (<Box sx={styles}>
            <ContainerJarOnLidOff onClick={() => setLidOffDialogOpen(true)} />
            <CheckDevicePlacement open={lidOffDialogOpen} onClose={() => setLidOffDialogOpen(false)} title={ lidTitle } description={ lidDescription } onClick={() => handleButtonClick('lid')} />
            <VideoDialog open={lidVideoDialogOpen} onClose={() => setLidVideoDialogOpen(false)} url={lidUrl} title={lidVideoTitle} description={lidVideoDescription} duration={7} />
        </Box>)
    else if (jarOn === "on" && lidOn === "on")
        return (<Box sx={styles}><ContainerJarOnLidOn/></Box>)
    else
        return (<Box sx={styles}>
            <ContainerJarLidOff onClick={() => setJarOffDialogOpen(true)} />
            <CheckDevicePlacement open={jarOffDialogOpen} onClose={() => setJarOffDialogOpen(false)} title={jarTitle} description={jarDescription} onClick={() => handleButtonClick('jar')} />
            <VideoDialog open={jarVideoDialogOpen} onClose={() => setJarVideoDialogOpen(false)} url={jarUrl} title={jarVideoTitle} description={jarVideoDescription} duration={7} />
        </Box>)

}

export default ContainerIcon