import React, { useState, useEffect, useContext } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { animated, useSpring } from 'react-spring'
import ScaleAnimation from './ScaleAnimation'
import TriangleBox from './TriangleBox'
import "../../css/weighingScale.css"
import PropTypes from "prop-types"
import { weighingScaleUrl } from '../../audioUrls/audioUrl'
import useCookClubAudio from '../../utils/audio/cookClubAudio'
import { WeightContext } from '../hardwareBar/HardwareTasks'

const commonBtnStyle = { borderRadius: "2vh", fontSize: "4vh", textTransform: "none", padding: "2vh" }
const scaleImg = 'https://res.cloudinary.com/akshadasingh/image/upload/v1641813765/weighing-scale_iokk6k.png'

const WeightTypography = ({ tare = 0 }) => {
    const reading = useContext(WeightContext)
    return <Typography
        variant="h1"
        style={{ fontWeight: 600 }}
        align="center"
    >
        {`${reading - tare} g`}
    </Typography>
}

const WeightDisplay = ({ text, img = "", nutrientInfo = null }) => {
    const reading = useContext(WeightContext)
    const [scaleProperties, setScaleProperties] = useState({
        tare: 0,
        add: 0,
        prevAdd: 0,
        displayedPrevAdd: null,
        displayedAdd: null,
        mode: "default",
    })
    const [transition, setTransition] = useState(false)
    const [weightSound] = useCookClubAudio(weighingScaleUrl)

    const [styleDisplayAdd, setStyleDisplayAdd] = useSpring(() => ({
        from: { y: 50 },
        onRest: () => {
            setScaleProperties(old => ({ ...old, displayedPrevAdd: null, displayedAdd: old.add }))
            setTransition(false)
        }
    }))

    const AnimatedTypography = animated(Typography)

    useEffect(() => {
        (transition) ?
            setStyleDisplayAdd({ y: 220, config: { duration: 700 } }) :
            setStyleDisplayAdd({ y: 50, config: { duration: 0 } })
    }, [transition, setStyleDisplayAdd])

    // useEffect(() => {
    //     if(parseInt(text)+2 > (reading - scaleProperties.tare) && (reading - scaleProperties.tare) > parseInt(text)-2) {
    //         setTimeout(() => {
    //             weightSound()
    //         }, 2000)
    //     }
    // },[(reading - scaleProperties.tare)])

    // const onAdd = e => {
    //     setScaleProperties(old => ({
    //         ...old,
    //         tare: reading,
    //         add: old.add + (reading - old.tare),
    //         displayedPrevAdd: `${reading - old.tare} g`,
    //         mode: "add",
    //     }))
    //     setTransition(true)
    // }

    const onTare = e => {
        setScaleProperties(old => ({ ...old, tare: reading, mode: "tare" }))// removing this "reading" will give fantastic performance return on weight scale
    }

    const onClear = e => {
        setScaleProperties(old => ({ ...old, displayedAdd: null, add: 0 }))
    }

    return (
        <Box className="parent" onClick={e => e.stopPropagation()}>
            <ScaleAnimation tare={scaleProperties.tare} src={scaleImg} />
            <Box style={{ zIndex: 2 }}>
                <WeightTypography tare={scaleProperties.tare} />
                <AnimatedTypography
                    variant="h1"
                    style={{ top: "7.8%", position: "absolute", fontWeight: 600, ...styleDisplayAdd }}
                >
                    {scaleProperties.displayedPrevAdd}
                </AnimatedTypography>
                <Box height="5vh" display="flex" flexDirection="row" alignItems={"flex-start"} sx={{ marginBottom: 15 }}>
                    {img && <img src={img} style={{ height: "8vh", width: "8vh" }} alt="ingredient" />}
                    {text === "" ? null : <Typography
                        variant="h3"
                        align="center"
                        style={{ fontWeight: 600, color: "#636363", lineHeight: 1.5, marginLeft: 20 }}
                    >
                        {text}
                    </Typography>
                    }
                </Box>
            </Box>
            <TriangleBox height={25} />
            <Box
                style={{ height: "20%" }}
                display="flex"
                alignItems="center"
            >
                <AnimatedTypography
                    variant="h1"
                    style={{ fontWeight: 600, marginLeft: 100 }}
                >
                    {scaleProperties.displayedAdd !== null ? `${scaleProperties.displayedAdd} g` : null}
                </AnimatedTypography>
                {scaleProperties.displayedAdd !== null ?
                    <Button
                        style={{ color: "#ff0000", textDecoration: "underline", ...commonBtnStyle }}
                        onClick={onClear}
                    >
                        Clear
                    </Button> : null}
            </Box>
            <Button
                style={{ border: "solid 2px #000000", color: "#000000", ...commonBtnStyle }}
                onClick={onTare}
            >
                Bring to zero
            </Button>
            {/* <Button
                    style={{ backgroundColor: "#3F51B5", color: "#FFFFFF", ...commonBtnStyle }}
                    onClick={onAdd}
                >
                    Add +
                </Button> */}
        </Box>
    )
}

WeightDisplay.propTypes = {
    reading: PropTypes.number,
    text: PropTypes.string,
    img: PropTypes.string,
    nutrientInfo: PropTypes.instanceOf(Object)
}

export default WeightDisplay