import React, { useRef, useState } from "react";
import ReactPlayer from "react-player";
import PropTypes from 'prop-types';
import { LinearProgress, Typography } from "@mui/material";

const MediaPlayer = ({ url = null, muted = true, duration, startTime, loop = true }) => {
    const mediaFragment = () => {
        const startVid = startTime ? ("#t=" + startTime) : "#t=0"
        if (duration)
            return startVid + "," + (startTime + duration)
        return startVid
    }

    const player = useRef(null)
    const [videoStatus, setVideoStatus] = useState("loading")
    const [totalDuration, setTotalDuration] = useState(null)

    const seek = (seekTime) => {
        player.current.seekTo(seekTime)
    }
    if (url)
        return (
            <>
                <ReactPlayer
                    ref={player}
                    url={url + mediaFragment()}
                    playing={true}
                    muted={muted}
                    onStart={() => setVideoStatus("started")}
                    width="100%"
                    height="100%"
                    onDuration={i => setTotalDuration(i)}
                    onProgress={(progress) => {
                        const selectedDuration = duration || totalDuration
                        if ((Math.ceil(progress.playedSeconds) >= startTime + selectedDuration) && loop) {
                            seek(startTime ? startTime : 0)
                        }
                    }}
                />
                {videoStatus === "loading" && <LinearProgress />}
            </>)
    return <Typography>Video Coming Soon</Typography>
}

MediaPlayer.propTypes = {
    url: PropTypes.string,
    muted: PropTypes.bool,
    duration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    startTime: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    videoLoop: PropTypes.bool
}

export default MediaPlayer