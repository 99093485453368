import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { HardwareContext } from "./HardwareTasks";
import { Button, SvgIcon } from '@mui/material'
import { ReactComponent as ConnectedIcon } from "../../svg/device-connected.svg"
import { ReactComponent as ConnectIcon } from "../../svg/device-not-connected.svg"
import PropTypes from 'prop-types';
import { errorUncontrolledIssueUrl } from "../../audioUrls/audioUrl";
import CheckDevicePlacement from "./CheckDevicePlacement";
import VideoDialog from "./VideoDialog";
import useCookClubAudio from "../../utils/audio/cookClubAudio";

const ConnectButton = ({ setHardwareStateMemo, hardwareStateMemo }) => {
    const { connectToHardware, connected, disconnect } = useContext(HardwareContext)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [bluetoothDisconnectSound] = useCookClubAudio(errorUncontrolledIssueUrl)
    const [videoDialogOpen, setVideoDialogOpen] = useState(false)
    const title = "Bluetooth is disconnected"
    const description = "Please reconnect to resume cooking"
    const videoTitle = "Connecting Bluetooth"
    const videoDescription = "Pair the tablet with the R1 as shown in the video"
    const url = "Need to shoot video for connecting to bluetooth?"

    useEffect(() => {
        if (connected) {
            setHardwareStateMemo(old => ({ ...old, btFirstConnect: true }))
        }
    }, [connected, setHardwareStateMemo])

    useEffect(() => {
        if (!connected && hardwareStateMemo?.btFirstConnect) {
            bluetoothDisconnectSound()
            setDialogOpen(true)
        }
    }, [connected])

    const handleButtonClick = () => {
        setDialogOpen(false)
        setVideoDialogOpen(true)
    }

    return (<>
        {!connected ?
            <>
                <Button
                    variant=""
                    onClick={() => { connectToHardware(); setButtonDisabled(true); setTimeout(() => setButtonDisabled(false), 15000) }}
                    disabled={buttonDisabled}
                    sx={{padding: "0 16px"}}
        >
                    <ConnectIcon/>
                </Button>
                <CheckDevicePlacement open={dialogOpen} onClose={() => setDialogOpen(false)} data={{ title, description }} onClick={handleButtonClick} />
                <VideoDialog open={videoDialogOpen} onClose={() => setVideoDialogOpen(false)} url={url} title={videoTitle} description={videoDescription} duration={7} />
            </>
            :
            <Button
                variant=""
                onClick={() => { disconnect(); setButtonDisabled(true); setTimeout(() => setButtonDisabled(false), 5000) }}
                disabled={buttonDisabled}
            >
                <SvgIcon component={ConnectedIcon} viewBox="0 0 60 60" style={{ fontSize: 60 }} />
            </Button>
        }
    </>)
}

ConnectButton.propTypes = {
    setHardwareStateMemo: PropTypes.func.isRequired,
}

export default ConnectButton