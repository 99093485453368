import { wrapperReducer } from "../components/recipeSteps/reducerUtil";
import { changeDefaultIngredient } from "../utils/experience2/ingredientsUtil"
import { addActiveToSubSteps, addTagsToData, changeRecipeOnServing } from "../utils/experience2/stepsUtils"
import { logEvents, startSession } from "../utils/logging"
import { v4 as uuid } from 'uuid'

function errorOnMissingData(type, data, checkList) {
    checkList.forEach(element => {
        if (!Object.prototype.hasOwnProperty.call(data, element))
            console.error(`Recipe Reducer Called Action Type ${type} does not have ${element}`)
    });
}

const defaultServe = 2

export default function recipeReducer(recipeState, { type, data }) {
    const sessionId = sessionStorage.getItem("sessionId")
    const { recipeId, user, device } = data
    logEvents(sessionId, type === "FROM_STEPS_REDUCER" ? data.type : type, data)
    switch (type) {
        case "INITIALIZE_RECIPE":
            errorOnMissingData(type, data, ["recipeToInitialize", "device", "user"])
            const recipeWithTags = addTagsToData(data?.recipeToInitialize ? data.recipeToInitialize : recipeState)
            const { minServing = 2, maxServing = 2 } = recipeWithTags.metadata
            const defaultServing = Math.max(Math.min(defaultServe, maxServing), minServing)
            const recipeWithServing = recipeReducer(recipeWithTags, { type: "CHANGE_SERVING_SIZE", data: { newServingSize: defaultServing } })
            const newSessionId = uuid()
            const recipeWithActive = {
                ...recipeWithServing,
                steps: addActiveToSubSteps(recipeWithServing.steps),
                view: recipeWithServing.metadata?.subStepWidth ? recipeWithServing.metadata.subStepWidth : "default",
                inFocusSubstepId: null,
                sessionId: newSessionId
            }
            sessionStorage.setItem("sessionId", newSessionId)
            startSession(newSessionId, device, user, recipeId)
            return recipeWithActive
        case "CHANGE_SERVING_SIZE":
            errorOnMissingData(type, data, ["newServingSize"])
            if (data.newServingSize)
                return changeRecipeOnServing(recipeState, data.newServingSize)
            return recipeState
        case "CHANGE_DEFAULT_INGREDIENT":
            errorOnMissingData(type, data, ["stepId", "ingredientId"])
            return changeDefaultIngredient(recipeState, data.stepId, data.ingredientId)
        case "FROM_STEPS_REDUCER":
            const stepsReducerReturn = wrapperReducer({ ...recipeState, recipeData: recipeState.steps }, { type: data.type, data })
            return { ...recipeState, steps: stepsReducerReturn.recipeData, view: stepsReducerReturn.view, inFocusSubstepId: stepsReducerReturn.inFocusSubstepId }
        default:
            console.error(`Recipe Reducer Called with invalid Action Type ${type} with data ${data}`)
            return recipeState
    }
}