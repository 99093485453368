import { useContext, useMemo } from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'
import { recipeTransformerFS2QL, recipeTransformerQL2FS } from './hasuraFSMigrationTransformers'
import { ErrorManagementContext } from '../errorManagement/ErrorManagementProvider'

const GET_RECIPE_FROM_ID = gql`
query MyQuery($Recipe_ID: String = "") {
  Recipe_DB_by_pk(Recipe_ID: $Recipe_ID) {
    Active
    Cooking_Time
    Description
    Diet
    Recipe_ID
    Recipe_Name
    cuisine
    image_url
    prep_time
    steps
    maxServing
    minServing
    author
    editor
    lastEditedAt
  }
}`

const UPDATE_RECIPE_FROM_ID = gql`
mutation MyMutation($Recipe_ID: String = "", $Active: Boolean = false, $Cooking_Time: numeric = "", $Description: String = "", $Diet: jsonb = "", $Recipe_Name: String = "", $cuisine: jsonb = "", $image_url: String = "", $prep_time: numeric = "", $steps: jsonb = "", $author: String = "", $editor: String = "", $lastEditedAt: String = "", $maxServing: Int = 2, $minServing: Int = 2) {
  update_Recipe_DB_by_pk(_set: {Active: $Active, Cooking_Time: $Cooking_Time, Description: $Description, Diet: $Diet, Recipe_Name: $Recipe_Name, cuisine: $cuisine, image_url: $image_url, prep_time: $prep_time, steps: $steps,author: $author, editor: $editor, lastEditedAt: $lastEditedAt, maxServing: $maxServing, minServing: $minServing}, pk_columns: {Recipe_ID: $Recipe_ID}) {
    Recipe_ID
    Recipe_Name
  }
}`

const DELETE_RECIPE_FROM_ID = gql`
mutation MyMutation($Recipe_ID: String = "") {
  delete_Recipe_DB_by_pk(Recipe_ID: $Recipe_ID) {
    Recipe_ID
    Recipe_Name
  }
}`

const useRecipeFromDB = (recipeId, onComplete = () => { }) => {
  const { cookClubLog } = useContext(ErrorManagementContext)
  const { loading, data } = useQuery(GET_RECIPE_FROM_ID, {
    variables: { Recipe_ID: recipeId },
    onError: err => cookClubLog(
      `Fectching recipeId ${recipeId}`,
      "ERROR_ON_ACCESS_RECIPE",
      { recipeId, err },
      { ui: { type: "error" }, console: true }
    ),
    onCompleted: data => data.Recipe_DB_by_pk ? onComplete(recipeTransformerQL2FS(data.Recipe_DB_by_pk)) : null
  })
  const [updateRecipe, { loading: saving, data: savedData, reset }] = useMutation(UPDATE_RECIPE_FROM_ID)
  const [deleteRecipeGQL, { loading: deleteing }] = useMutation(DELETE_RECIPE_FROM_ID)

  const recipeJSON = useMemo(() => loading || !recipeId ? null : recipeTransformerQL2FS(data.Recipe_DB_by_pk), [loading, data, recipeId])
  //need to handle situation where Id coming in does not exist
  const save = (recipeEditedValidJSON, onSaveCallBack = () => { }) => {
    updateRecipe({
      variables: recipeTransformerFS2QL(recipeEditedValidJSON, recipeId)
    }).then(
      ({ data }) => onSaveCallBack(data.update_Recipe_DB_by_pk.Recipe_ID)
    )
  }
  const deleteRecipe = () => {
    deleteRecipeGQL({ variables: { Recipe_ID: recipeId } })
  }

  return [recipeJSON, loading, save, saving || deleteing, deleteRecipe]
}

export default useRecipeFromDB