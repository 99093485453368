import { firestoreCreateNewDocument } from "../firebase/useFirebase"
import { sessionsStream } from '../bytebeamClient'

var mixpanel = require('mixpanel-browser')

mixpanel.init('b05972c3080f1dd13ce569166a58e871', { debug: process.env.REACT_APP_VERCEL_ENV !== "production" })

export const startSession = (sessionID = "default_id",
  deviceID = "default_device_ID",
  userID = "default_user",
  recipeID = "default_ID",
  startTime = Date.now()) => {
  firestoreCreateNewDocument("sessions", { sessionID, deviceID, userID, recipeID, startTime });

  sessionsStream.publish({ sessionID, deviceID, userID, recipeID, startTime });
  console.log("start session logged")
}

export const endSession = (sessionID = "default_id",
  completionCode = "default_completion_code",
  CookingExperienceRating = "-1",
  foodExperienceRating = "-1",
  endTime = Date.now()) => {
  console.log("end session logged");
  sessionsStream.publish({ sessionID, completionCode, CookingExperienceRating, foodExperienceRating, endTime });
}

export const logEvents = (sessionID = "default_id",
  eventType = "default_event_type",
  eventData = {},
  timeStamp = Date.now()) => {
  firestoreCreateNewDocument("events", { sessionID, eventType, eventData, timeStamp })
  mixpanel.track(eventType, { ...eventData, sessionId: sessionID, time: timeStamp, live: process.env.REACT_APP_VERCEL_ENV === "production" })
  console.log("events logged - " + eventType)
}
