import * as React from 'react';
import { useDrag } from 'react-use-gesture';
import { v4 as uuid } from 'uuid';
import Style from './Style';
import {
  valueToAngle,
  getCenter,
  getAngle,
  angleToValue,
  limitValue,
  getArc,
} from './utils';

const classNamePrefix = 'RoundSlider';

const defaultProps = {
  color: 'purple',
  bgColor: '#ccc',
  max: 100,
  min: 0,
  stepSize: 0,
  // by default we want smooth sliding
  steps: 0,
  sliced: true,
  strokeWidth: 35,
  rotationOffset: 0,
  arcSize: 360,
  value: 50,
  radius: 100,
};

function Roundy(optProps) {
  const props = { ...defaultProps, ...optProps };
  const uniqueId = uuid();
  const {
    color,
    bgColor,
    max,
    min,
    steps,
    stepSize,
    strokeWidth,
    radius,
    sliced,
    style,
    arcSize,
    rotationOffset,
    onAfterChange,
    allowClick,
    render,
    onChange,
  } = props;

  const _wrapper = React.useRef(null);
  const _handle = React.useRef(null);
  const isDrag = React.useRef(false);
  const [state, setAll] = React.useState({
    value: props.value,
    angle: valueToAngle(props.value, props),
  });

  const bind = useDrag(({ down, xy: [x, y] }) => {
    setValueAndAngle(x, y, !down ? newState => {
      isDrag.current = down
      onAfterChange && onAfterChange(newState, props);
    } : undefined);
  });

  React.useEffect(() => {
    if (props.value !== state.value) {
      const newState = {
        value: props.value,
        angle: valueToAngle(props.value, props),
      };
      setAll(newState);
    }
  }, [props.value]);

  const setState = (obj) =>
    setAll(prev => ({ ...prev, ...obj }));
  const { angle } = state;
  const segments = steps || (stepSize ? Math.floor((max - min) / stepSize) : 0);
  const maskName = `${classNamePrefix}_${uniqueId}`;
  const size = radius * 2;
  const styleRotation = {
    transform: `rotate(${rotationOffset}deg)`,
    transformOrigin: '50% 50%',
  };

  const setValueAndAngle = (
    x,
    y,
    cb
  ) => {
    const { left, top } = getCenter(_wrapper, radius);
    const dX = x - left;
    const dY = y - top;

    const { value, angle } = stepRounding(getAngle(dY, dX, rotationOffset));
    const newState = { value, angle };
    setState(newState);
    if (cb) {
      cb(newState);
    }
    onChange && onChange(value, props);
  };

  const updateOnClick = event => {
    if (isDrag.current) {
      return
    }
    const { clientX, clientY } = event;
    let eX = clientX;
    let eY = clientY;
    setValueAndAngle(eX, eY, newState => {
      onAfterChange && onAfterChange(newState, props);
    });
  };

  const getMaskLine = (segments, index) => {
    const { radius, arcSize } = props;
    const val = (arcSize / segments) * index + 180;
    const rotateFunction =
      'rotate(' + val.toString() + ',' + radius + ',' + radius + ')';
    return (
      <g key={index} transform={rotateFunction}>
        <line
          x1={radius}
          y1={radius}
          x2={radius * 2}
          y2={radius}
          style={{
            stroke: 'rgb(0,0,0)',
            strokeWidth: 2,
          }}
        />
      </g>
    );
  };

  const stepRounding = (degree) => {
    const { stepSize, steps, min, max, arcSize } = props;
    const step = stepSize || (steps ? (max - min) / steps : 1);
    const { angle: oldAngle } = state;
    let angToValue = min;
    if (!isDrag.current) {
      angToValue = angleToValue(degree, props);
    }
    else {
      angToValue = angleToValue(
        oldAngle > arcSize - 20 && degree < arcSize / 4
          ? Math.max(degree, arcSize)
          : oldAngle < 20 && degree > arcSize - 20
            ? Math.min(degree, 0)
            : degree,
        props
      );
    }
    let value;
    const remain = (angToValue - min) % step;
    const currVal = angToValue - remain;
    const nextVal = limitValue(currVal + step, min, max);
    const preVal = limitValue(currVal - step, min, max);
    if (angToValue >= currVal)
      value = angToValue - currVal < nextVal - angToValue ? currVal : nextVal;
    else {
      value = currVal - angToValue > angToValue - preVal ? currVal : preVal;
    }
    const overflowValue = angleToValue(70, props) - min
    if (angToValue > max && angToValue < max + overflowValue / 2) {
      value = max
    }
    if (angToValue < overflowValue + max && angToValue > max + overflowValue / 2) {
      value = min
    }
    value = Math.round(value);
    const ang = valueToAngle(value, props);
    return { value, angle: ang };
  };

  return (
    <Style
      className="roundy"
      onClick={updateOnClick}
      style={
        allowClick || render
          ? style
          : { ...(style || {}), pointerEvents: 'none' }
      }
    >
      {render ? (
        <div
          className="roundyRenderPropsParent"
          ref={_wrapper}
          {...bind()}
          style={{ width: size, height: size, display: 'inline-block' }}
        >
          {render(state, props)}
        </div>
      ) : (
        <React.Fragment>
          <svg ref={_wrapper} width={size} height={size}>
            {sliced && (
              <defs>
                <mask
                  id={maskName}
                  maskUnits="userSpaceOnUse"
                  style={styleRotation}
                >
                  <rect x={0} y={0} width={size} height={size} fill="white" />
                  {Array.from({ length: segments }).map((_, i) => {
                    return getMaskLine(segments, i);
                  })}
                </mask>
              </defs>
            )}

            <path
              fill="transparent"
              strokeDashoffset="0"
              strokeWidth={strokeWidth}
              stroke={bgColor}
              mask={sliced ? `url(#${maskName})` : undefined}
              style={styleRotation}
              d={getArc(Math.min(arcSize, 359.9999), 0, props)}
            />
            <path
              fill="none"
              strokeWidth={strokeWidth}
              stroke={color}
              mask={sliced ? `url(#${maskName})` : undefined}
              style={styleRotation}
              d={getArc(Math.min(angle, 359.9999), 0, props)}
            />
          </svg>
          <div
            ref={_handle}
            className="sliderHandle"
            {...bind()}
            style={{
              transform: `rotate(${angle + rotationOffset}deg) scaleX(-1)`,
            }}
          />
        </React.Fragment>
      )}
    </Style>
  );
}

export default Roundy;