import { Chip, Grid, Skeleton } from "@mui/material"
import Typography from "@mui/material/Typography"
import { ReactComponent as ServingIcon } from "../../svg/cardIcons/servingIcon.svg"
import React from "react"
import PropTypes from "prop-types"


const ListContent = ({ title, maxServing = 2, minServing = 2, tags = [], categories = [], loading }) => {
    const [firstCategory, ...restCategories] = categories;
    const [firstTag, ...restTags] = tags;
    return (
        <Grid container style={{ display: "flex", alignItems: "center" }}>
            <Grid style={{ marginBottom: "8px" }} item xs={12}>
                <Typography variant="cardBold">{loading ? <Skeleton /> : title}</Typography>
            </Grid>
            {/* {time &&
                <Grid item xs={3}>
                    <TimeWithClockIcon timeInMin={time} />
                </Grid>
            } */}
            <Grid container item xs={12} style={{ marginBottom: "8px", alignItems: "center" }}>
                <Grid container item xs={9}>
                    <Grid item>
                        {firstCategory && <Chip sx={{ marginRight: "8px", height: "26px", backgroundColor: "#E7E7E7", color: "#434343", borderRadius: "5px" }} label={firstCategory} />}
                    </Grid>
                    <Grid item>
                        {firstTag && <Chip sx={{ marginRight: "8px", height: "26px", backgroundColor: "#E7E7E7", color: "#434343", borderRadius: "5px" }} label={firstTag} />}
                    </Grid>
                </Grid>
                <Grid item xs={3} style={{ border: "1px solid #909090", display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center", borderRadius: "5px", flex: 1 }}>
                    <ServingIcon />
                    <Typography variant="card">{minServing === maxServing ? minServing : `${minServing}-${maxServing}`}</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {restCategories && restCategories.map((tag, i) => <Chip label={tag} key={i} sx={{ marginRight: "8px", height: "26px", backgroundColor: "#E7E7E7", color: "#434343", borderRadius: "5px" }} />)}
                {restTags && restTags.map((tag, i) => <Chip label={tag} key={i} sx={{ marginRight: "8px", height: "26px", backgroundColor: "#E7E7E7", color: "#434343", borderRadius: "5px" }} />)}
            </Grid>
        </Grid >
    );
};

ListContent.propTypes = {
    title: PropTypes.string.isRequired,
    tags: PropTypes.array
}

export default ListContent
