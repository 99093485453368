import React, { useEffect, useReducer, useState, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import SecureArea from "../components/authentication/SecureArea";
import { Box } from "@mui/material";
import HardwareBar from "../components/hardwareBar/HardwareBar";
import OfflinePage from "./OfflinePage";
import Experience2 from "./Experience2";
import { IngredientsFromDB } from "../components/ingredients2/IngredientsFromDB";
import { GlobalSettingsContext } from "../utils/globalSettings/globalSettingsContext";
import useRecipeFromDB from "../utils/RecipeBackEnd/loadRecipe";
import recipeReducer from "../recipeCore/recipeReducer";
import { errorUncontrolledIssueUrl, generalTapUrl } from "../audioUrls/audioUrl";
import useCookClubAudio from "../utils/audio/cookClubAudio";
import FreshChatReact from "../utils/chatBot/FreshChatReact";

const RecipeDisplay4 = () => {
    const { id } = useParams()
    const [localRecipe, recipeDispatchDecorated] = useReducer(recipeReducer, null)
    const { globalSettings: { device, user } } = useContext(GlobalSettingsContext)
    const recipeDispatch = useCallback(
        ({ type, data }) => recipeDispatchDecorated({ type, data: { ...data, recipeId: id, user, device } }),
        [recipeDispatchDecorated, user, id, device]
    )
    const [, loading] = useRecipeFromDB(id, result => recipeDispatch({ type: "INITIALIZE_RECIPE", data: { recipeToInitialize: result } }))
    const [wifiDisconnectSound] = useCookClubAudio(errorUncontrolledIssueUrl)
    const [generalTapSound] = useCookClubAudio(generalTapUrl)
    const [hardwareStateMemo, setHardwareStateMemo] = useState({
        btFirstConnect: false
    })

    useEffect(() => {
        if (!navigator.onLine) {
            wifiDisconnectSound()
        }
    }, [navigator.onLine])

    return navigator.onLine ?
        <SecureArea>
            <IngredientsFromDB>
                <Box display="flex" height="100vh" onClick={() => generalTapSound()} >
                    <HardwareBar
                        setHardwareStateMemo={setHardwareStateMemo}
                        hardwareStateMemo={hardwareStateMemo}
                    />
                    <Experience2
                        recipe={localRecipe}
                        hardwareStateMemo={hardwareStateMemo}
                        recipeLoading={loading}
                        recipeDispatch={recipeDispatch}
                    />
                </Box>
            </IngredientsFromDB>
            <FreshChatReact recipeInfo={localRecipe} />
        </SecureArea > : <OfflinePage />
}

export default RecipeDisplay4