import React, { useState, useContext } from "react"
import WeightBtn from "../WeightBtn";
import { Box, Button, IconButton, SvgIcon, Typography } from "@mui/material";
import PrepModal from "../PrepModal";
import PropTypes from "prop-types";
import { logEvents } from "../../../utils/logging";
import { ReactComponent as PrepIcon } from "../../../svg/how-button.svg"
import AlternateIngredientsModal from "../AlternateIngredientsModal"
import { ReactComponent as alternateIcon } from "../../../svg/alternate.svg"
import { GlobalSettingsContext } from "../../../utils/globalSettings/globalSettingsContext"


const IngredientDetail = ({ data = [], ingredientsOnly = false, recipeDispatch }) => {
    const [modalName, setModalName] = useState(null)
    const [openAlt, setOpenAlt] = useState(false)
    const { globalSettings: { user } } = useContext(GlobalSettingsContext)

    const changeDefault = (stepId, ingredientId) => {
        recipeDispatch({ type: "CHANGE_DEFAULT_INGREDIENT", data: { stepId, ingredientId } })
    }

    return (<>
        {data ? <Box className="item-row-parent">
            {data.map(({ displayName, id, prep, image, nutrientInfo, weighScaleAmount, alternateIngredients, stepId }) => <Box className="item-row" key={displayName}>
                <Box className="item-props">
                    {image && <Box width="13.5vh">
                        <IconButton
                            onClick={e => {
                                e.stopPropagation()
                                if (alternateIngredients)
                                    setOpenAlt(id)
                            }}>
                            <Box sx={{
                                position: "relative"
                            }}>
                                {alternateIngredients && <SvgIcon component={alternateIcon} viewBox="0 0 30 30" sx={{ position: "absolute", right: 0, bottom: 0, fontSize: 40 }} />}
                                <img
                                    src={image}
                                    className="icon"
                                    alt="ingredient"
                                />
                            </Box>
                        </IconButton>
                    </Box>}
                    <Box display="flex" flexDirection="column">
                        <Box display="flex" alignItems="center" style={{ gap: 20 }}>
                            <Typography variant="button">
                                {displayName}
                            </Typography>
                            {weighScaleAmount?.defaultVal ? <Typography variant="body1">{`x ${weighScaleAmount.defaultVal}`}</Typography> : null}
                        </Box>
                        {prep && !ingredientsOnly ? <Box className="prep-info-display">
                            <Typography variant="body1">{prep?.displayName}</Typography>
                            <Button
                                variant=""
                                onClick={() => {
                                    const sessionId = sessionStorage.getItem("sessionId")
                                    logEvents(sessionId, "INGREDITENT_HELP", { user, ingredient: displayName })
                                    setModalName(displayName)
                                }}
                            >
                                <SvgIcon component={PrepIcon} viewBox="0 0 34 34" style={{ fontSize: 40 }} />
                            </Button>
                        </Box> : null}
                    </Box>
                </Box>
                <AlternateIngredientsModal
                    items={alternateIngredients}
                    open={id === openAlt}
                    selectedIngredientId={id}
                    onClose={() => setOpenAlt(null)}
                    selectIngredient={(ingredientId) => changeDefault(stepId, ingredientId)}
                />
                {weighScaleAmount?.displayBtn && !ingredientsOnly ?
                    <WeightBtn weight={weighScaleAmount?.value} btnClass="btn" nutrientInfo={nutrientInfo} img={image} /> :
                    <Typography variant="body1" style={{ width: "15%", textAlign: "center" }}>{weighScaleAmount?.value}</Typography>}
                <PrepModal
                    open={modalName === displayName}
                    onClose={() => setModalName(null)}
                    ingredient={{ displayName, image, nutrientInfo }}
                    prep={prep}
                    weight={weighScaleAmount}
                />
            </Box>
            )}
        </Box>
            : null}
    </>)
}

IngredientDetail.propTypes = {
    data: PropTypes.array,
    ingredientsOnly: PropTypes.bool,
    recipeDispatch: PropTypes.func.isRequired
}

export default IngredientDetail