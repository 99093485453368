import styled from 'styled-components';

const Style = styled.div`
  display: inline-block;
  position: relative;
  svg path {
    opacity: 0.7;
  }
  .sliderHandle {
    width: 50%;
    pointer-events: all;
    position: absolute;
    left: 50%;
    top: 50%;
    transform-origin: 0 50%;
    &:after {
      content: '';
      display: block;
      width: 80px;
      height: 150px;
      border-radius: 50px;
      position: absolute;
      right: 3px;
      background: transparent;
      border: 1px solid #ccc;
      right: -15px;
      top: -55px;
      transform: all ease 0.4s;
      border: none;
      touch-action: none;
    }
    &:hover:after {
      box-shadow: none;
    }
  }
  ${({ overrideStyle }) => overrideStyle}
`;
export default Style;