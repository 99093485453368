import React from 'react'
import { Container, Typography } from '@mui/material'

import { useLocation } from 'react-router-dom'

const NotFound404 = (props) => {
    const location = useLocation();

    return (
        <Container>
            <Typography variant="h2">Error 404: Page not Found {location.pathname}</Typography>
        </Container>
    )
}

NotFound404.propTypes = {

}

export default NotFound404
