const projectId = 'reactlabs';

if (navigator.userAgent !== "Reactlabs") {
    window.Wifi = {
        openWifiSettings: () => {
            console.log("Dummy openWifiSettings")
        },

        getCurrentWifiConnection: () => {
            console.log("Dummy getCurrentWifiConnection")
            return "{}"
        }
    }

    window.Bytebeam = {
        getDeviceId: () => {
            return -1
        }
    }
}

export class BytebeamClient {
    constructor(deviceId) {
        this.deviceId = deviceId;
        this.connect();
        this.streams = {};
    }

    connect() {
        if (navigator.userAgent !== "Reactlabs") {
            return
        }

        const clientId = `device-${this.deviceId}`;
        this.ws = new WebSocket('wss://demo.bytebeam.io:5883');

        const sendPing = () => {
            setTimeout(() => {
                if (this.ws.readyState === 1) {
                    this.ws.send(JSON.stringify({ type: 'ping', ping: true }));
                }
            }, 5000);
        }

        this.ws.addEventListener('open', (_event) => {
            this.ws.send(JSON.stringify({
                client_id: clientId,
                filters: [],
            }));

            //sendPing();
        });

        this.ws.addEventListener('message', (message) => {
            console.debug('received form bytebeam: ', message);

            try {
                const m = JSON.parse(message.data);

                if (m && m.type && typeof (m.type) === "string" && m.type.toLowerCase() === "pong") {
                    sendPing();
                }
            } catch (e) {
                console.log("Error in processing received message", e);
            }
        })

        this.ws.addEventListener('error', (error) => {
            // console.error("bytebeam Websocket error", error);
        })

        this.ws.addEventListener('close', (event) => {
            // console.error(`bytebeam Websocket closed. Reopening ${new Date()}`, event);
            this.connect();
        })
    }

    stream(streamName) {
        if (this.streams[streamName]) {
            return this.streams[streamName]
        }

        this.streams[streamName] = new Stream(this, streamName);

        return this.streams[streamName]
    }
}

export class Stream {
    constructor(bytebeamClient, name) {
        this.logSequence = 0;
        this.name = name;
        this.bytebeamClient = bytebeamClient;
    }

    publish(payload) {
        if (navigator.userAgent !== "Reactlabs") {
            return;
        }

        if (this.bytebeamClient.ws.readyState !== 1) {
            console.log(`Attempt to write to bytebeam stream before connection is ready ws.readyState: ${this.bytebeamClient.ws.readyState}`);
            return;
        }

        this.logSequence += 1
        const data = [
            Object.assign({
                timestamp: new Date().valueOf(),
                sequence: this.logSequence,
            }, payload)
        ]

        //console.log(`Writing to bytebeam ${this.name} stream`, data)
        this.bytebeamClient.ws.send(JSON.stringify({
            type: 'publish',
            topic: `/tenants/${projectId}/devices/${this.bytebeamClient.deviceId}/events/${this.name}/jsonarray`,
            data: data
        }));
    }
}

export const bytebeamClient = new BytebeamClient(window.Bytebeam.getDeviceId())

export const deviceShadowStream = bytebeamClient.stream('device_shadow');
export const sessionsStream = bytebeamClient.stream('sessions');

window.bytebeamClient = bytebeamClient
