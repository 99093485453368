
import React from "react";
import { Typography } from "@mui/material";
import IngredientDetail from "./IngredientDetail";
import PropTypes from "prop-types";

const WholeSpicesDisplay = ({ display, title, items, ingredientsOnly = false, recipeDispatch }) => {
    if (display) {
        return (<>
            <Typography variant="button">{title}</Typography>
            <IngredientDetail data={items} ingredientsOnly={ingredientsOnly} recipeDispatch={recipeDispatch} />
        </>)
    }
    return null
}

WholeSpicesDisplay.propTypes = {
    display: PropTypes.bool,
    title: PropTypes.string,
    items: PropTypes.array,
    setNewRecipe: PropTypes.func,
    displayDottedLine: PropTypes.bool,
}

export default WholeSpicesDisplay