import { Box, Button } from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import { grey } from "@mui/material/colors";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import makeStyles from '@mui/styles/makeStyles';
import FilterListIcon from "@mui/icons-material/FilterList";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PropTypes from 'prop-types';
import React, { useContext, useState } from "react";
import { FilterContext } from "../../utils/homepage/FilterContext";
import Filter from "../../utils/homepage/page/Filter";
import useRecipeListFromGQL from "../../utils/RecipeBackEnd/loadRecipeListGQL";

const color = grey[500];
const styles = {
  button: {
    flex: 1,
    color: color,
  },
};

const useStyles = makeStyles({
  root: {
    textTransform: "none",
    display: ({ showClearFilter }) => showClearFilter,
    zIndex: 1000,
    borderRadius: 4,
    padding: "10px 20px",
    color: "white",
    backgroundColor: "black",
    cursor: "pointer"
  },
});

const ToolBars = ({ onFilterChange }) => {
  const [value, setValue] = useState("categories");
  const { recipeList } = useRecipeListFromGQL()
  const [filterOptions, setFilterOptions] = useContext(FilterContext);
  const [openFilterPage, setOpenFilterPage] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const [showClearFilter, setShowClearFilter] = useState("none");
  const classes = useStyles({ showClearFilter });

  const activeRecipes = recipeList.filter((recipe) => recipe?.metadata?.active);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  let filteredData
  // Handles the sorting of the recipe list
  const handleSort = () => {
    let sortedData;
    switch (value) {
      case "rating":
        sortedData = activeRecipes.sort((x, y) => {
          return y.rating - x.rating;
        });
        break;
      case "likes":
        sortedData = activeRecipes.sort((x, y) => {
          return y.likes - x.likes;
        });
        break;
      case "time":
        let mapped = filteredData.map((v, i) => {
          return { i, value: v.metadata.totalTime };
        })
        if (sortOrder === 'asc') {
          setSortOrder("desc")
          mapped.sort((a, b) => {
            if (a.value > b.value) {
              return 1;
            }
            if (a.value < b.value) {
              return -1;
            }
            return 0;
          });
        } else {
          setSortOrder("asc")
          mapped.sort((a, b) => {
            if (a.value < b.value) {
              return 1;
            }
            if (a.value > b.value) {
              return -1;
            }
            return 0;
          });
        }

        sortedData = mapped.map(v => filteredData[v.i]);
        onFilterChange(sortedData)
        break;
      default:
        throw Error();
    }
  };


  const handleOpen = () => {
    setOpenFilterPage(true);
  };

  const handleClose = () => {
    setOpenFilterPage(false);
  };

  const handleClearFilter = () => {
    onFilterChange(activeRecipes)
    resetFilter()
    setShowClearFilter("none")
  }

  const resetFilter = () => {
    setFilterOptions({ ...filterOptions, categories: [], tags: [] })
  }

  const getTags = (field) => {
    const uniqueTags = []
    const temp = recipeList.map(recipe => recipe?.metadata[field]).filter(i => i !== undefined)
    temp.forEach(i => {
      i.forEach(v => {
        if (!uniqueTags.includes(v)) {
          uniqueTags.push(v)
        }
      })
    })
    return uniqueTags
  }

  const handleFilter = () => {
    const filteredData1 = activeRecipes.filter((list) => {
      const tags = list?.metadata?.tags || []
      const categories = list?.metadata?.categories || []
      const x = categories.find(i => filterOptions?.categories.includes(i))
      const y = tags.find(i => filterOptions?.tags.includes(i))
      return tags.includes(y) || categories.includes(x)
    })
    onFilterChange(filteredData1)
    handleClose();
    setShowClearFilter("block")
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-evenly"
        alignItems="center"
        divider={<Divider orientation="vertical" flexItem />}
        flex={1}
        padding={2}
        style={styles}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            flex: 0.5,
          }}
        >
          <FormControl fullWidth>
            <Select
              value={value}
              IconComponent={KeyboardArrowDownIcon}
              onChange={handleChange}
            >
              <MenuItem value="categories">Categories</MenuItem>
              <MenuItem value="tags">Tags</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <ButtonGroup variant="text" style={{ flex: 1 }}>
          <Button style={{ flex: 1 }} startIcon={<ImportExportIcon />} onClick={handleSort} >
            Sort
          </Button>
          <Button style={{ flex: 1 }} startIcon={<FilterListIcon />} onClick={handleOpen} >
            Filter
          </Button>
        </ButtonGroup>
      </Box>
      <Filter open={openFilterPage} getSelectOptions={getTags} onClose={handleClose} resetFilter={resetFilter} handleFilter={handleFilter} />
      <Box
        width="100%"
        position="fixed"
        style={{ display: "flex", justifyContent: "center", left: '40%', bottom: 80, backgroundColor: "black", width: "25vw" }}
      >
        <Button className={classes.root} onClick={handleClearFilter}>CLEAR FILTERS</Button>
      </Box>
    </>
  );
};

ToolBars.propTypes = {
  filterRecipeList: PropTypes.func
}

export default ToolBars;
