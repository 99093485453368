import React, { useContext } from "react"
import PropTypes from "prop-types"
import { AuthContext } from "../../../firebase/context";
import { GlobalSettingsContext } from "../../../utils/globalSettings/globalSettingsContext";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useFirestore from "../../../firebase/useFirebase";
import StarRating from "./StarRating";
import ThumbsRating from "./ThumbsRating"
import HeartRating from "./HeartRating";
import SentimentRating from "./SentimentRating";
import { fiveStarRatingSoundUrl, generalTapUrl } from "../../../audioUrls/audioUrl";
import useCookClubAudio from "../../../utils/audio/cookClubAudio";

const RatingsDisplay = ({ iconType, ratingType = "", subStepId, displayText = "", setDisplayRatingText }) => {
    const { user } = useContext(AuthContext)
    const { id: recipeId } = useParams()
    const { globalSettings } = useContext(GlobalSettingsContext)
    const sessionId = sessionStorage.getItem("sessionId")
    const { saveNewData, data, updateData } = useFirestore("userRating")
    const [fiveStarRatingSound] = useCookClubAudio(fiveStarRatingSoundUrl)
    const [generalRatingSound] = useCookClubAudio(generalTapUrl)

    function onSelectRating(e) {
        const ratingVal = Number(e.currentTarget.value) || 0
        if ([1,2,3].includes(ratingVal)) {
            generalRatingSound()
        } else if ([4,5].includes(ratingVal)) {
            fiveStarRatingSound()
        }
        setDisplayRatingText(ratingVal)
        const recipeData = {
            userId: user.uid,
            recipeId,
            subStepId: subStepId,
            globalSettings,
            sessionId,
            ratingType,
            displayText,
            iconType
        }
        const existingData = data.find(i => i.userId === user.uid && i.subStepId === subStepId && i.recipeId === recipeId)
        if (existingData) {
            updateData({ ...recipeData, rating: [...existingData.rating, ratingVal] }, existingData.id)
        }
        else {
            saveNewData({ ...recipeData, rating: [ratingVal] })
        }
    }

    switch (iconType) {
        case "star5": {
            return (<StarRating maxValue={5} onSelectRating={onSelectRating} subStepId={subStepId} />)
        }
        case "star10": {
            return (<StarRating maxValue={10} onSelectRating={onSelectRating} subStepId={subStepId} />)
        }
        case "heart5": {
            return (<HeartRating maxValue={5} onSelectRating={onSelectRating} subStepId={subStepId} />)
        }
        case "heart10": {
            return (<HeartRating maxValue={10} onSelectRating={onSelectRating} subStepId={subStepId} />)
        }
        case "satisfaction": {
            return (<SentimentRating onSelectRating={onSelectRating} subStepId={subStepId} />)
        }
        case "thumbs": {
            return (<ThumbsRating onSelectRating={onSelectRating} subStepId={subStepId} />)
        }
        default: {
            return null
        }
    }
}

RatingsDisplay.propTypes = {
    iconType: PropTypes.string,
    ratingType: PropTypes.string,
    subStepId: PropTypes.string,
    displayText: PropTypes.string,
    setDisplayRatingText: PropTypes.func,
}

export default RatingsDisplay