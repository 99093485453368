import React from "react";
import PropTypes from 'prop-types';
import { Typography, SvgIcon, Box } from "@mui/material";
import { ReactComponent as CapOn } from "../../svg/hardwareBar/crownOn.svg";
import { ReactComponent as CapOff } from "../../svg/hardwareBar/crownOff.svg";

const CapDisplay = ({ cap, status }) => {
    const color = ["in-progress", "completed"].includes(status) ? "#B5D1C5" : "#ffffff"
    
    return (<Box display="flex" justifyContent="space-between" alignItems="center" sx={{ backgroundColor: color }}>
        {cap.toLowerCase() === "on" ?
            <><Typography variant="h5">Crown on</Typography>
                <SvgIcon component={CapOn} viewBox="0 0 98.968 68.863" style={{ fontSize: 60 }} />
            </> : <>
                <Typography variant="h5">Crown off</Typography>
                <SvgIcon component={CapOff} viewBox="0 0 98.968 68.863" style={{ fontSize: 60 }} />
            </>
        }
    </Box>)
}

CapDisplay.propTypes = {
    cap: PropTypes.string,
    status: PropTypes.string
}

export default CapDisplay