import { useEffect, useRef } from "react"

const useSubStepScrollFeature = (triggerScroll, index, topOffset, behavior) => {
    const scrollRef = useRef([])
    const parentRef = useRef(null)

    const createScrollRef = (el, index) => {
        scrollRef.current[index] = el
    }

    const createParentRef = (el) => {
        parentRef.current = el
    }

    useEffect(() => {
        parentRef?.current?.scrollTo({ top: scrollRef?.current[index]?.offsetTop + topOffset, behavior: behavior })
    }, [triggerScroll, index, topOffset, behavior])

    return { createSubStepParentRef: createParentRef, createSubStepChildrenRef: createScrollRef }
}

export default useSubStepScrollFeature