import { Dialog, DialogContent, Typography, Box } from '@mui/material'
import React, { useState, useEffect, useCallback } from 'react'
import ReactApexChart from "react-apexcharts"
import PropTypes from "prop-types"

/* The Array of Line Graphs */
const lineGraphList = ["Temperature", "Heating", "DutyCycle"]
const defaultDataList = lineGraphList.map(name => ({
  name: name,
  data: []
}));

const RealtimeLineChart = ({ timerDataList }) => {
  const [dataList, setDataList] = useState(defaultDataList);
  /* Default data for multiple line graphs */
  const options = {
    chart: {
      zoom: {
        enabled: false
      },
      animations: {
        enabled: true,
        easing: "linear",
        dynamicAnimation: {
          speed: 1000
        }
      },
      toolbar: {
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: []
        },
        export: {
          csv: {
            filename: "testing",
            columnDelimiter: ',',
            headerCategory: 'Time',
            headerValue: 'value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toString()
            }
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          }
        },
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    title: {
      text: 'Temperature Plot',
      align: 'left',
      style: {
        fontSize: "20px"
      }
    },
    markers: {
      size: 0
    },
    xaxis: {
      type: "datetime",
      labels: {
        formatter: function (value, timestamp) {
          return `${new Date(timestamp).getHours()}:${new Date(timestamp).getMinutes()}`
        }
      },
    },
    yaxis: {
      labels: {
        formatter: val => val.toFixed(0) + " [°C]",
      },
      min: 0,
      max: 150,
    }
  };


  const getDutyCycleData = useCallback(() => {
    const dutyCycleCoordinates = timerDataList.map(({ time, currentTime, dutyCycle }) => {
      return { x: currentTime, y: dutyCycle }
    })
    return dutyCycleCoordinates
  },[timerDataList])

  const getHeatingData = useCallback(() => {
    const heatingDataCoordinates = timerDataList.map(({ time, currentTime, heating }) => {
      return { x: currentTime, y: heating }
    })
    return heatingDataCoordinates
  },[timerDataList])

  const getTemperatureData = useCallback(() => {
    const tempCoordinates = timerDataList.map(({ time, currentTime, temperature }) => {
      return { x: currentTime, y: temperature }
    })
    return tempCoordinates.filter((item) => item.y !== 0)
  },[timerDataList])

  const getDataFromGraph = useCallback((name) => {
    if (name === "Temperature") {
      return getTemperatureData()
    } else if (name === "DutyCycle") {
      return getDutyCycleData()
    } else if (name === "Heating") {
      return getHeatingData()
    }
  },[getTemperatureData, getDutyCycleData, getHeatingData])
  

  useEffect(() => {
    setDataList(dataList.map(val => ({
      name: val.name,
      data: getDataFromGraph(val.name)
    })))
    
  }, [timerDataList, getDataFromGraph, dataList]);

  return (
    <ReactApexChart type="line" options={options} series={dataList} height={450} width={450} />
  );
};

const TimeTemperaturePlotting = ({ dialogOpen, onClose, timerDataList }) => {
  const timerData = timerDataList[timerDataList.length - 1]
  return (
    <Dialog open={dialogOpen} onClose={onClose}>
      <DialogContent>
        <RealtimeLineChart timerDataList={timerDataList} />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="body1">Temperature: {timerData?.temperature}</Typography>
          <Typography variant="body1">Heating: {timerData?.heating}</Typography>
          <Typography variant="body1">Duty Cycle: {timerData?.dutyCycle} </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

TimeTemperaturePlotting.propTypes = {
  timerDataList: PropTypes.instanceOf(Array)
}

export default TimeTemperaturePlotting