import { Button, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types"

const ParallelPrepDisplay = ({ subStepId, stepId, description, dispatch }) => {
    return (<>
        {description ? <Button
            onClick={() => {
                if (stepId && subStepId)
                    dispatch({ type: "ON_STEP_CHANGE", data: { stepId: stepId, subStepId: subStepId } })
                else
                    return
            }}>
            <Typography variant="h5">{description}</Typography>
        </Button> : null}
    </>)

}

ParallelPrepDisplay.propTypes = {
    subStepId: PropTypes.string,
    stepId: PropTypes.string,
    description: PropTypes.string,
    dispatch: PropTypes.func
}

export default ParallelPrepDisplay