import { Stepper, Step, Box, StepLabel, Typography, StepConnector } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import React, { useContext } from "react";
import PropTypes from "prop-types";

import { getCompletedStep, getCompletedSubsteps, getIndexOfSteps, getInFocusSubStepId, getInProgressStep } from "../../../utils/experience2/stepsUtils";
import { inProgress, scrollStepTopOffset } from "../../../utils/experience2/typesVariable";
import InProgressDisplay from "./InprogressDisplay"
import StepperIcon from "./StepperIcon";
import RecenterBtn from "./RecenterBtn";
import { StateOfHardwareContext } from "../../hardwareBar/HardwareTasks";
import { generalTapUrl } from "../../../audioUrls/audioUrl";
import useCookClubAudio from "../../../utils/audio/cookClubAudio";

const getActiveIconClass = (priority) => priority === "immediate" ? "active-icon" : "inactive-icon"

const Connector = withStyles({
    line: {
        height: 0,
        border: 0,
    },
})(StepConnector);

const getStatusColor = (displayStepNames, stateOfHardware) => {
    return displayStepNames ? stateOfHardware === "operation" ? `active-btn in-progress-color` : "active-btn" : `active-btn decrease-active-btn-width`
}

const StepsLabel = ({ steps, recipeDispatch, displayStepNames, scroll, createChildrenRef, createParentRef }) => {
    const stateOfHardware = useContext(StateOfHardwareContext)
    const [generalTapSound] = useCookClubAudio(generalTapUrl)
    function onClickStep(e, id) {
        e.stopPropagation()
        generalTapSound()
        const inFocusSubstepId = getInFocusSubStepId(steps, id)
        recipeDispatch({ type: "ON_STEP_CHANGE", data: { stepId: id, subStepId: inFocusSubstepId } })
    }

    const getTimerProgress = (step) => {
        const validatedStep = step?.subSteps || []
        const inProgressSubstep = validatedStep.find(i => i.progress === inProgress)
        const settings = inProgressSubstep?.parameters?.settings
        const totalTime = settings?.reduce((prev, curr) => prev + curr.time, 0)
        return { totalTime: totalTime, progress: inProgressSubstep?.progress }
    }

    const scrollToStepOnRecenter = (e) => {
        const inProgressStep = getInProgressStep(steps)
        const completedStep = getCompletedStep(steps)
        if (inProgressStep) {
            const index = getIndexOfSteps(steps, inProgressStep.id)
            scroll(index, scrollStepTopOffset, "smooth")
            onClickStep(e, inProgressStep.id, index)
        }
        else if (completedStep) {
            const index = getIndexOfSteps(steps, completedStep.id)
            scroll(index, scrollStepTopOffset, "smooth")
            onClickStep(e, completedStep.id, index)
        }
        else {
            if (steps.length && steps[0].subSteps.length) {
                const { subSteps } = steps[0]
                const subStepId = subSteps[0].id
                const stepId = steps[0].id
                scroll(0, scrollStepTopOffset, "smooth")
                recipeDispatch({ type: "ON_STEP_CHANGE", data: { stepId: stepId, subStepId: subStepId } })
            }
            return
        }
    }

    return (
        <>
            <Box
                style={{
                    paddingTop: "120px",
                    boxSizing: "border-box",
                    overflowY: "hidden",
                    height: "400vh",
                    paddingBottom: "400vh",
                    width: "90%"
                }}
                ref={el => createParentRef(el)}
            >
                <Box className={getStatusColor(displayStepNames, stateOfHardware)} />
                <Stepper orientation="vertical" connector={<Connector />} style={{ padding: "0 10px" }} >
                    {steps.map(({ name, status, id, active, priority }, index) => {
                        const { subSteps } = steps[index];
                        const totalSubSteps = subSteps.length;
                        const completedSubSteps = getCompletedSubsteps(subSteps).length
                        const heightValue = (completedSubSteps / totalSubSteps) * 100 // 100px
                        return (<Step
                            key={id}
                            ref={el => createChildrenRef(el, index)}
                        >
                            <StepLabel
                                StepIconComponent={() => <StepperIcon
                                    style={{
                                        position: "absolute",
                                        height: `${heightValue}px`,
                                        top: "-10",
                                        borderLeft: "10px solid var(--green)",
                                    }}
                                    connectorClass={
                                        `step-line not-completed-line`
                                    }
                                    iconClass={status === "completed" ?
                                        `step-icon stepper-icon completed-stepper-icon` :
                                        `step-icon stepper-icon not-completed-stepper-icon ${getActiveIconClass(priority)}`}
                                />}
                            >
                                <Box
                                    className={active ?
                                        "stepper-button stepper-button-active-color" :
                                        "stepper-button stepper-button-inactive-color"}
                                    onClick={e => {
                                        onClickStep(e, id, index)
                                        scroll(index, scrollStepTopOffset, "smooth", "onClickStep")
                                    }}
                                >
                                    <Typography variant={active ? "caption" : "body2"}>
                                        {`Step ${index + 1}`}
                                    </Typography>
                                    {displayStepNames ?
                                        <Typography style={{ lineHeight: 1 }} variant={active ? "button" : "body1"}>
                                            {name}
                                        </Typography> :
                                        null}
                                    {status === "in-progress" && <InProgressDisplay timerProps={getTimerProgress(steps[index])} />}
                                </Box>
                            </StepLabel>
                        </Step>)
                    })
                    }
                </Stepper >
            </Box >
            <Box
                width={displayStepNames ? "50vw" : "20vw"}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "fixed",
                    bottom: 25,
                    zIndex: 5,
                    transition: "width 0.5s ease-in"
                }}>
                <RecenterBtn
                    onClick={scrollToStepOnRecenter}
                    view={displayStepNames ? "big" : "small"}
                />
            </Box>
        </>
    )
}

StepsLabel.propTypes = {
    completedCooking: PropTypes.bool,
    steps: PropTypes.array, //change to shape
    recipeDispatch: PropTypes.func
}

export default StepsLabel