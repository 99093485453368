import { Button, Typography, Grid, Accordion, AccordionSummary } from "@mui/material";
import React, { useState } from "react";
import AddIngredients from "./subStepTypes/AddIngredients";
import AddIcon from '@mui/icons-material/Add'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete'
import PropTypes from "prop-types"

const AddIngredientsWithAlternate = ({ parameters, setParameters }) => {
    const items = parameters?.items || [{ ingredients: [] }]
    const [activeAccordionIndex, setActiveAccordionIndex] = useState(null)

    const changeParameters = (newValue, index) => {
        const newItems = [...items.slice(0, index), { ingredients: newValue }, ...items.slice(index + 1, items.length)]
        setParameters({ ...parameters, items: newItems })
    }

    const addItem = () => {
        setParameters({ ...parameters, items: [...items, { ingredients: [] }] })
    }

    const moveUp = (e, index) => {
        e.stopPropagation()
        const currItem = items[index]
        const prevItem = items[index - 1]
        const newItems = [...items.slice(0, index - 1), currItem, prevItem, ...items.slice(index + 1, items.length)]
        setActiveAccordionIndex(activeAccordionIndex - 1)
        setParameters({ ...parameters, items: newItems })
    }

    const moveDown = (e, index) => {
        e.stopPropagation()
        const currItem = items[index]
        const nextItem = items[index + 1]
        const newItems = [...items.slice(0, index), nextItem, currItem, ...items.slice(index + 2, items.length)]
        setActiveAccordionIndex(activeAccordionIndex + 1)
        setParameters({ ...parameters, items: newItems })
    }

    const onDelete = (e, index) => {
        e.stopPropagation()
        const newItems = [...items.slice(0, index), ...items.slice(index + 1, items.length)]
        setParameters({ ...parameters, items: newItems })
    }

    return (<Grid container alignItems={"center"}>
        <Typography>ingredients</Typography>
        <Grid item xs={10}>
            {items.map((i, index) => {
                return (
                    <Accordion
                        expanded={index === activeAccordionIndex}
                        onChange={() => setActiveAccordionIndex(index)}
                        style={{ margin: "20px 10px", border: "3px solid #2e7d32" }}
                        key={index}
                    >
                        <AccordionSummary>
                            <Typography>Alternate Ingredients</Typography>
                            <Button
                                disabled={index === 0}
                                onClick={e => moveUp(e, index)}
                                color="success"
                            >
                                <ArrowUpwardIcon />
                            </Button>
                            <Button
                                disabled={index === items.length - 1 || items.length < 2}
                                color="error"
                                onClick={e => moveDown(e, index)}>
                                <ArrowDownwardIcon />
                            </Button>
                            <Button onClick={e => onDelete(e, index)}><DeleteIcon /></Button>
                        </AccordionSummary>
                        <AddIngredients parameters={{ items: i.ingredients }} setParameters={(newValue) => {
                            const { items } = newValue
                            changeParameters(items, index)
                        }} />
                    </Accordion>
                )
            })}
            <Button onClick={addItem}><AddIcon /></Button>
        </Grid>
    </Grid>)
}

AddIngredientsWithAlternate.propTypes = {
    parameters: PropTypes.instanceOf(Object),
    setParameters: PropTypes.instanceOf(Array)
}

export default AddIngredientsWithAlternate