import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { MenuItem, Select, Typography, Grid, TextField, Tabs, Tab, Box, Button, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { getWeighingScaleValue } from "../../../utils/experience2/ingredientsUtil"

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const IngredientsTab = ({ ingredients, ingredientId, setIngredientId }) => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // Get all the unique tags from all the ingredients
    const uniqueTags = []
    const temp = ingredients.map(i => i.tags)
    temp.forEach(i => {
        i.forEach(v => {
            if (!uniqueTags.includes(v.toLowerCase())) {
                uniqueTags.push(v)
            }
        })
    })

    const getIngredientsFromTags = (tag) => {
        return ingredients.filter(i => i.tags.includes(tag))
    }

    return (
        <Box style={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} variant="scrollable" scrollButtons="auto" onChange={handleChange} aria-label="basic tabs example">
                    {uniqueTags.map((tag, index) => (
                        <Tab label={tag || "Others"} {...a11yProps(index)} key={tag} />
                    ))}
                </Tabs>
            </Box>
            {uniqueTags.map((tag, index) => (
                <TabPanel key={tag} value={value} index={index}>
                    <List key={tag}>
                        {getIngredientsFromTags(tag).map(({ id, displayName, image }) => (
                            <ListItem disablePadding key={id}>
                                <ListItemButton onClick={(e) => setIngredientId(id)}>
                                    <img src={image} width={30} alt="ingredient" />
                                    <ListItemText primary={displayName} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </TabPanel>
            ))}
        </Box>
    )
}

const SearchIngredients = ({ ingredients, setIngredientId }) => {
    const [searchItem, setSearchItem] = useState('')
    const [filteredIngredients, setFilteredIngredients] = useState([])

    const handleSearch = (e) => {
        const filteringItems = ingredients.filter(i => {
            return i.displayName.toLowerCase().includes((e.target.value).toLowerCase())
        })
        setFilteredIngredients(filteringItems)
        setSearchItem(e.target.value)
        if (searchItem?.length === 1) {
            setFilteredIngredients([])
        }
    }

    return (
        <>
            <TextField autoFocus={true} label="search" value={searchItem} onChange={handleSearch} />
            <List>
                {filteredIngredients.map(({ id, displayName, image }) => (
                    <ListItem disablePadding key={id}>
                        <ListItemButton onClick={() => setIngredientId(id)}>
                            <img src={image} width={30} alt="ingredient" />
                            <ListItemText primary={displayName} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </>
    )
}


const IngredientsSelector = ({ ingredientId, setIngredientId, ingredients }) => {
    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const getDefaultIngredient = (id) => {
        return ingredients.find(i => {
            return i.id === id
        })?.defaultWeightUnit
    }

    const handleIngredient = (id) => {
        setIngredientId(id, { qty: 10, unit: getDefaultIngredient(id) })
    }

    return (
        <>
            <Button variant="outlined" onClick={handleClickOpen}>
                <img src={ingredients.find(i => i.id === ingredientId)?.image} width={30} alt="ingredient" />
                <Typography marginLeft={1}>{ingredients.find(i => i.id === ingredientId)?.displayName}</Typography>
            </Button>
            <Dialog onClose={handleClose} open={open}>
                <DialogTitle>Add Ingredients from Tags</DialogTitle>
                <SearchIngredients ingredients={ingredients} setIngredientId={(id) => handleIngredient(id)} />
                <IngredientsTab ingredients={ingredients} ingredientId={ingredientId} setIngredientId={(id) => handleIngredient(id)} />
            </Dialog>
        </>
    )
}

export const PrepSelector = ({ selectedPrepId, setPrepId, ingredient }) => {
    const ddValue = selectedPrepId ? selectedPrepId : "none"
    return (
        <Select
            value={ddValue}
            onChange={e => setPrepId(e.target.value)}
            autoWidth
            size="small"
            placeholder="None"
        >
            <MenuItem value="none"><Grid container><Typography>None</Typography></Grid></MenuItem>
            {
                ingredient?.preps.map(({ prepId, displayName }) => (
                    <MenuItem value={prepId} key={prepId}><Grid container><Typography>{displayName}</Typography></Grid></MenuItem>
                ))
            }
        </Select >
    )
}

export const QtySelector = ({ amount, setAmount, ingredient }) => {
    if (!ingredient) return null
    const { qty, unit } = amount
    const { conversion } = ingredient
    const setQty = newQty => setAmount({ qty: newQty, unit: ingredient.defaultWeightUnit })
    return (
        <Grid container alignItems={"center"}>
            <Grid item xs={4}>
                <TextField size="small" type="number" value={qty} onChange={e => setQty(e.target.value)} />
            </Grid>
            <Grid item xs={4}>
                <Select
                    value={unit}
                    disabled sx={{ width: "100%" }}
                    size="small"
                >
                    <MenuItem value={unit}><Grid container><Typography>{ingredient.defaultWeightUnit}</Typography></Grid></MenuItem>
                </Select>
            </Grid>
            <Grid item xs={4}>
                <Typography>{getWeighingScaleValue(conversion, "g", qty, ingredient.defaultWeightUnit).value}</Typography>
            </Grid>
        </Grid >
    )
}

export const ScaleModel = ({ scaleModel = {}, setScaleModel }) => {
    const { type, parameters } = scaleModel
    const setScaleFactor = (newScaleFactor) => {
        setScaleModel({
            ...scaleModel,
            type: "linear",
            parameters: { ...scaleModel.parameters, scaleFactor: newScaleFactor }
        })
    }
    const setBaseValue = (newBaseVal) => {
        setScaleModel({
            ...scaleModel,
            type: "linear",
            parameters: { ...scaleModel.parameters, baseValue: newBaseVal }
        })
    }

    return (
        <Grid container justifyContent={"space-around"} sx={{ marginBottom: 2 }}>
            <Grid item xs={4}>
                <Select
                    value={type || "linear"}
                    disabled sx={{ width: "100%" }}
                    size="small"
                >
                    <MenuItem value={"linear"}>{type}</MenuItem>
                </Select>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    size="small"
                    label="BaseValue"
                    key="BaseValue"
                    type="number"
                    value={parameters?.baseValue}
                    onChange={(e) => setBaseValue(e.target.valueAsNumber)}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    size="small"
                    label="ScaleFactor"
                    key="ScaleFactor"
                    type="number"
                    value={parameters?.scaleFactor}
                    onChange={(e) => setScaleFactor(e.target.valueAsNumber)}
                />
            </Grid>
        </Grid>
    )
}

IngredientsSelector.propTypes = {
    ingredientId: PropTypes.string,
    ingredients: PropTypes.array,
    setIngredientId: PropTypes.func
}

export default IngredientsSelector