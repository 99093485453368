import React, { useContext } from 'react'
import useRecipeFromDB from '../../utils/RecipeBackEnd/loadRecipe'
import { v4 as uuid } from "uuid"
import { Button, DialogContent, Typography } from '@mui/material'
import { AuthContext } from '../../firebase/context'
import useNewRecipe from '../../utils/RecipeBackEnd/saveNewRecipe'

const DuplicateRecipe = ({ recipeId, setDuplicateOpen }) => {
  const [recipeJSON, loading] = useRecipeFromDB(recipeId)
  const { user } = useContext(AuthContext)

  const recipeReadyToDublicate = recipeJSON && !loading

  const duplicateRecipe = recipeReadyToDublicate ? {
    ...recipeJSON,
    metadata: {
      ...recipeJSON?.metadata,
      name: `${recipeJSON?.metadata?.name} Copy`,
      active: false,
      author: user.displayName,
      editor: user.displayName,
    },
    steps: recipeJSON?.steps?.map((step) => {
      return {
        ...step, id: uuid(), subSteps: step?.subSteps?.map((subStep) => {
          return { ...subStep, id: uuid() }
        })
      }
    })
  } : null

  const { saveNew, saving } = useNewRecipe()

  const handleSave = () => {
    saveNew(duplicateRecipe)
    setDuplicateOpen(false)
  }

  return (
    <>
      <DialogContent>
        <Typography>Duplicate {recipeJSON?.metadata?.name}</Typography>
      </DialogContent>
      <Button variant="contained" color="secondary" onClick={handleSave} disabled={!recipeReadyToDublicate}>Save Recipe</Button>
    </>
  )
}

export default DuplicateRecipe