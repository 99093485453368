import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { endSession } from '../../utils/logging'
import RecipeListByExperiance from './RecipeListByExperiance'
import { GlobalSettingsContext } from '../../utils/globalSettings/globalSettingsContext'
import { useFirestoreDoc } from '../../firebase/useFirebase'
import EmptyCards from './EmptyCards'
import { getTrackRecipes, getUserTag, removeDuplicateRecipes } from '../../utils/levels/levelsUtils'


export const useUserSpecifcRecipe = () => {
    const { globalSettings: { user } } = useContext(GlobalSettingsContext)
    const { data, loading } = useFirestoreDoc("users", user)
    const baseReturn = { name: "any", recipes: [], loading, userTags: [] }
    if (loading)
        return { ...baseReturn }
    if (user === "any" || !data)
        return { ...baseReturn, loading: false }
    return {
        ...baseReturn,
        name: data.nickName,
        recipes: data.recipesForUser,
        userTags: data?.customUserTags ? data.customUserTags : []
    }
}

const RecipeList = ({ displayType = "LINK_TO_RECIPE", loading,
    experimentalRecipes2, experience2Recipe, setBackground }) => {
    const [expandedAccordion, setExpandedAccordion] = useState('level1')
    const history = useHistory()
    const { name: userName,
        recipes: userSpecificRecipes,
        loading: userRecipesLoading,
        userTags } = useUserSpecifcRecipe()
    const userRecipes = experience2Recipe.filter(r => userSpecificRecipes.includes(r.id))
    const userTrack = getUserTag("track", userTags)
    const userTrackRecipes = getTrackRecipes(experience2Recipe).filter(r => {
        return (r?.metadata?.hiddenTags.find(i => i.includes(userTrack)))
    })
    const level1Recipes = [...userRecipes, ...userTrackRecipes.filter(r => r?.metadata?.hiddenTags.includes("level_1"))]
    const level2Recipes = userTrackRecipes.filter(r => r?.metadata?.hiddenTags.includes("level_2"))
    const formattedLevel2Recipes = removeDuplicateRecipes(level2Recipes, level1Recipes)

    const handleAccordionChange = (value) => (event, isExpanded) => {
        setBackground(isExpanded)
        setExpandedAccordion(isExpanded ? value : false)
    }

    if (loading || userRecipesLoading)
        return <Box
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                flexWrap: "wrap"
            }}
        >
            <EmptyCards />
        </Box>

    return (
        <>
            <RecipeListByExperiance
                accordionLevel={1}
                recipeList={level1Recipes}
                displayType={displayType}
                title="Home Cook"
                loading={loading}
                userRecipeLoading={userRecipesLoading}
                expanded={expandedAccordion === "level1"}
                onChange={handleAccordionChange("level1")}
                experiance="2.0"
            />
            <RecipeListByExperiance
                accordionLevel={2}
                recipeList={formattedLevel2Recipes}
                displayType={displayType}
                title="Pro Chef"
                loading={loading}
                userRecipeLoading={userRecipesLoading}
                expanded={expandedAccordion === "level2"}
                onChange={handleAccordionChange("level2")}
                experiance="2.0"
            />
            {
                userTags.map(tag => <RecipeListByExperiance
                    recipeList={experience2Recipe.filter(r => {
                        const tagArray = r?.metadata?.tags
                        return Array.isArray(tagArray) ? tagArray.includes(tag) : false
                    })}
                    displayType={displayType}
                    accordionLevel={2}
                    loading={loading}
                    userRecipeLoading={userRecipesLoading}
                    title={tag + " Recipes"}
                    experiance="2.0"
                    key={tag}
                />)
            }
            <RecipeListByExperiance
                recipeList={experience2Recipe}
                manualMode
                displayType={displayType}
                accordionLevel={3}
                loading={loading}
                userRecipeLoading={userRecipesLoading}
                title="Food Scientist"
                experiance="2.0"
                expanded={userName === "any" ? expandedAccordion === "level1" : expandedAccordion === userName}
                onChange={handleAccordionChange(userName === "any" ? "level1" : userName)}
            />
            {process.env.REACT_APP_VERCEL_ENV !== "production" && <RecipeListByExperiance
                recipeList={experimentalRecipes2}
                displayType={displayType}
                title="Experimental Recipes"
                accordionLevel={0}
                loading={loading}
                userRecipeLoading={userRecipesLoading}
                expanded={expandedAccordion === "experimental"}
                onChange={handleAccordionChange("experimental")}
                experiance="2.0"
            />}
        </>
    )
}

RecipeList.propTypes = {
    displayType: PropTypes.oneOf(["LINK_TO_RECIPE", "INGREDIENTS_ONLY"]),
    recipeList: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
    experimentalRecipes: PropTypes.arrayOf(PropTypes.object)
}

export default RecipeList
