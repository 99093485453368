import React from "react"
import MediaPlayer from "../MediaPlayer"
import PopUpModal from "../PopUpModal"
import { Typography, Box, Grid } from "@mui/material"
import PropTypes from "prop-types"

const VideoDialog = ({ open, onClose, url, title, description, duration }) => {
    return (
        <PopUpModal open={open} onClose={onClose} >
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={5}>
                    <Box sx={{ marginLeft: "20%" }}>
                        <Typography variant="h3" textAlign="left" marginBottom={"10%"}>{title}</Typography>
                        <Typography variant="body2" textAlign="left">{description}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={7}>
                    <Box sx={{ height: 450, width: 470, margin: "10% 0" }}>
                        <MediaPlayer
                            url={url}
                            muted={true}
                            duration={duration}
                            startTime={0}
                            loop={true}
                        />
                    </Box>
                </Grid>
            </Grid>
        </PopUpModal>
    )
}

VideoDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    url: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    duration: PropTypes.number,
}

export default VideoDialog