import { Grid, Stack, Typography, Button, SvgIcon } from '@mui/material'
import React, {useState, useEffect} from 'react'
import SettingsEditor from './SettingsEditor'
import { ReactComponent as Minus } from "../../svg/minus.svg"
import { ReactComponent as Plus } from "../../svg/plus.svg"
import { getTime } from '../../utils/experience2/stepsUtils' 


const DialEditor = ({title, value, icon, unit, minValue, maxValue, onClick, onChange, stepSize, styles, edit=false, setScroll, instructionCount}) => {
  const [localValue, setLocalValue] = useState(value)
  const actualValue = title==='Time'? getTime(localValue): title === "Speed" && value === 13 ? "Stir" :localValue

  useEffect(() => {
    setLocalValue(value)
  }, [value])

  const handleLocalChange = (newValue) => {
    setLocalValue(newValue)
  }

  const handleStepChange = (newValue) => {
    setLocalValue(newValue)
    onChange(newValue)
  }

  return (
    <Grid container rowSpacing={8} justifyContent="center" sx={{margin: "0px"}}>
      <Stack spacing={2} alignItems="center" justifyContent="center" style={styles}>
        <Typography variant="body2">{title}</Typography>
        <Stack sx={{width: "100%"}} direction="row" justifyContent="space-between" alignItems="center">
          <Button onClick={() => {value-stepSize > minValue-1 && handleStepChange(value-stepSize)}}>
            <SvgIcon component={Minus} viewBox="0 0 50 50" style={{ fontSize: 40 }} />
          </Button>
          <Typography variant="button">{actualValue}{unit}</Typography>
          <Button onClick={() => {value < maxValue && handleStepChange(value+stepSize)}}>
            <SvgIcon component={Plus} viewBox="0 0 50 50" style={{ fontSize: 40 }} />
          </Button>
        </Stack>
        <SettingsEditor 
          onClick={onClick} 
          onChange={onChange} 
          onlocalChange={handleLocalChange} 
          value={value} 
          minValue={minValue}
          maxValue={maxValue} 
          stepSize={stepSize} 
          setScroll={setScroll}
          icon={icon} 
          instructionCount={instructionCount}
        />    
      </Stack>
    </Grid>
  )
}

export default DialEditor