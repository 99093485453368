import React from "react";
import StepsDescription from "../StepsDescription";
import PropTypes from 'prop-types';
import R1Buttons from "../settingsComponents/R1Buttons";
import { Box } from "@mui/material";
import RecipeTimer from "../RecipeTimer";
import { createSettings } from "../../../utils/experience2/stepsUtils";
import ImageUtilityBox from "./ImageUtilityBox";
import ParallelPrepDisplay from "../ParallelPrepDisplay";
import CapDisplay from "../CapDisplay";

const UtilityType = ({ parameters, title, status, dispatch, subStepId, servingSize, stepId }) => {
    const r1Settings = createSettings(parameters?.settings)
    const imageSrc = parameters?.url
    return (
        <Box sx={{display: "flex", flexDirection:"column", gap: 2}} >
            {parameters?.cap !== "None" && parameters?.cap ? <StepsDescription
                status={status}
                details={< CapDisplay cap={parameters.cap} status={status} />}
            /> : null}
            <StepsDescription
                title={title}
                status={status}
                details={<>
                    {imageSrc && <ImageUtilityBox src={imageSrc} style={parameters?.style} />}
                    {status === "in-progress" ? <>
                        <RecipeTimer
                            initialTime={r1Settings.totalTime}
                            active={status === "in-progress"}
                        />
                        <ParallelPrepDisplay {...parameters?.parallelPrep} dispatch={dispatch} />
                    </> : null}
                </>}
                button={r1Settings?.settings.length ?
                    <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <R1Buttons
                            dispatch={dispatch}
                            subStepId={subStepId}
                            inProgress={status === "in-progress"}
                            progress={status}
                            buttonStatus={parameters?.status}
                            servingSize={servingSize}
                            stepId={stepId}
                        />
                    </Box> : null}
            />
        </Box>
    )
}

UtilityType.propTypes = {
    parameters: PropTypes.object,
    title: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
    dispatch: PropTypes.func,
    stepId: PropTypes.string
}

export default UtilityType