
/*************************************************** 
 This is a React WebApp written to Flash an ESP32 via BLE
 
 Written by Andrew England (SparkFun)
 BSD license, all text above must be included in any redistribution.
 *****************************************************/

import React, { useState } from 'react';
import '../css/bluetoothTest.css';

// const myESP32 = 'd804b643-6ce7-4e81-9f8a-ce0f699085eb'

const otaServiceUuid = 'c8659210-af91-4ad3-a995-a58d6fd26145'
const versionCharacteristicUuid = 'c8659212-af91-4ad3-a995-a58d6fd26145'
const fileCharacteristicUuid = 'c8659211-af91-4ad3-a995-a58d6fd26145'

// let esp32Device = null;
let esp32Service = null;
let readyFlagCharacteristic = null;
let dataToSend = null;
let updateData = null;
let disconnected = null;

let totalSize;
let remaining;
let amountToWrite;
let currentPosition;

let currentHardwareVersion = "N/A";
let softwareVersion = "N/A";
// let latestCompatibleSoftware = "N/A";

const characteristicSize = 512;

/* BTConnect
 * Brings up the bluetooth connection window and filters for the esp32
 */
function BTConnect(setPromptUser) {
  navigator.bluetooth.requestDevice({
    acceptAllDevices: true,
    optionalServices: [otaServiceUuid]
  })
    .then(device => {
      device.addEventListener('gattserverdisconnected', disconnect)
      return device.gatt.connect()
    })
    .then(server => server.getPrimaryService(otaServiceUuid))
    .then(service => {
      esp32Service = service;
    })
    .then(service => {
      return service;
    })
    .then(_ => {
      return CheckVersion(setPromptUser);
    })
    .catch(error => { console.log(error); });
}


function disconnect(e) {
  disconnected = true
}
/* onDisconnected(event)
 * If the device becomes disconnected, prompt the user to reconnect.
 */
// function onDisconnected(event) {
//   Popup.create({
//     content: esp32Device.name + ' is disconnected, would you like to reconnect?',
//     buttons: {
//       left: [{
//         text: 'Yes',
//         action: function () {
//           Popup.close();
//           BTConnect();
//         }
//       }],
//       right: [{
//         text: 'No',
//         action: function () {
//           Popup.close();
//         }
//       }]
//     }
//   })
// }

/* CheckVersion()
 * Grab most current version from Github and Server, if they don't match, prompt the user for firmware update
 */
function CheckVersion(setPromptUser) {
  return esp32Service.getCharacteristic(versionCharacteristicUuid)
    .then(characteristic => characteristic.readValue())
    .then(value => {
      currentHardwareVersion = 'v' + value.getUint8(0) + '.' + value.getUint8(1);
      softwareVersion = 'v' + value.getUint8(2) + '.' + value.getUint8(3) + '.' + value.getUint8(4);
      document.getElementById('hw_version').innerHTML = "Hardware: " + currentHardwareVersion;
      document.getElementById('sw_version').innerHTML = "Software: " + softwareVersion;
    })
    .then(() => setPromptUser(true))
    .catch(error => { console.log(error); });
}

/* PromptUserForUpdate()
 * Asks the user if they want to update, if yes downloads the firmware based on the hardware version and latest software version and begins sending
 */
function PromptUserForUpdate({ setPromptUser }) {
  const [file, setFile] = useState(null)
  async function fetchUpdate() {
    updateData = await file.arrayBuffer()
    SendFileOverBluetooth()
  }

  return (
    <div style={{
      backgroundColor: "#ffffff",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      color: "#000000",
      padding: 20
    }}>
      <div style={{ width: "100%", justifyContent: "center", alignItems: "center", display: "flex", gap: "2%" }}>
        <label>Upload file</label>
        <input onChange={e => setFile(e.target.files[0])} type="file" />
      </div>
      <p>Update Software</p>
      <div style={{
        display: "flex",
        justifyContent: "center",
        gap: 20,
      }}>
        <button
          onClick={fetchUpdate}>
          Yes
        </button>
        <button onClick={() => setPromptUser(false)}>
          No
        </button>
      </div>
    </div>)
}

/* SendFileOverBluetooth(data)
 * Figures out how large our update binary is, attaches an eventListener to our dataCharacteristic so the Server can tell us when it has finished writing the data to memory
 * Calls SendBufferedData(), which begins a loop of write, wait for ready flag, write, wait for ready flag...
 */
function SendFileOverBluetooth() {
  if (!esp32Service) {
    console.log("No esp32 Service");
    return;
  }
  totalSize = updateData.byteLength;
  remaining = totalSize;
  amountToWrite = 0;
  currentPosition = 0;
  esp32Service.getCharacteristic(fileCharacteristicUuid)
    .then(characteristic => {
      readyFlagCharacteristic = characteristic;
      return characteristic.startNotifications()
        .then(_ => {
          readyFlagCharacteristic.addEventListener('characteristicvaluechanged', SendBufferedData)
        });
    })
    .catch(error => {
      console.log(error);
    });
  SendBufferedData();
}


/* SendBufferedData()
 * An ISR attached to the same characteristic that it writes to, this function slices data into characteristic sized chunks and sends them to the Server
 */
function SendBufferedData() {
  if (remaining > 0) {
    if (remaining >= characteristicSize) {
      amountToWrite = characteristicSize
    }
    else {
      amountToWrite = remaining;
    }
    dataToSend = updateData.slice(currentPosition, currentPosition + amountToWrite);
    currentPosition += amountToWrite;
    remaining -= amountToWrite;
    console.log("remaining: " + remaining);
    esp32Service.getCharacteristic(fileCharacteristicUuid)
      .then(characteristic => RecursiveSend(characteristic, dataToSend))
      .then(_ => {
        return document.getElementById('completion').innerHTML = (100 * (currentPosition / totalSize)).toPrecision(3) + '%';
      })
      .catch(error => {
        console.log(error);
      });
  }
}


/* resursiveSend()
 * Returns a promise to itself to ensure data was sent and the promise is resolved.
 */
function RecursiveSend(characteristic, data) {
  return characteristic.writeValue(data)
    .catch(error => {
      if (!disconnected)
        return RecursiveSend(characteristic, data);
    });
}


/* App()
 * The meat and potatoes of our web-app; where it all comes together
 */
function NewBluetoothTest() {
  const [promptUser, setPromptUser] = useState(false)
  return (
    <div className="App" id="top">
      <header className="App-header" id="mid">
        {promptUser ? <PromptUserForUpdate setPromptUser={setPromptUser} /> : null}
        <p id="hw_version">Hardware: Not Connected</p>
        <p id="sw_version">Software: Not Connected</p>
        <p id="completion"></p>
        <button id="connect"
          onClick={() => BTConnect(setPromptUser)}
        >
          Connect to Bluetooth
        </button>
      </header>
    </div>
  )
}

export default NewBluetoothTest;