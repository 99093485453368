import { useState, useRef } from "react"

const useScrollFeature = () => {
    const scrollRef = useRef([])
    const parentRef = useRef(null)
    const [returnedData, setReturnedData] = useState({
        triggerScroll: true,
        subStepScrollBehavior: "smooth"
    })

    const createScrollRef = (el, index) => {
        scrollRef.current[index] = el
    }

    const createParentRef = (el) => {
        parentRef.current = el
    }

    const scroll = (index, topOffset, behavior, scrollTriggerType = "default") => {
        parentRef?.current?.scrollTo({ top: scrollRef?.current[index]?.offsetTop + topOffset, behavior: behavior })
        setReturnedData({
            triggerScroll: !returnedData.triggerScroll,
            subStepScrollBehavior: scrollTriggerType === "default" ? "smooth" : "auto"
        })
    }

    return { createParentRef, createChildrenRef: createScrollRef, scroll, ...returnedData, childrenRef: scrollRef }
}

export default useScrollFeature