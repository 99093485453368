import { Box, Paper, Typography } from '@mui/material'
import React from 'react'

const PresetCard = ({title, icon, onClick}) => {
  return (
    <Paper onClick={onClick} sx={{width: 300, height: 90, borderRadius: "8px"}}>
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "flex-start", margin: "5% 10%", gap: "20px"}}>
        {icon}
        <Typography variant="h5" >{title}</Typography>
      </Box>
    </Paper>
  )
}

export default PresetCard