import React from "react"
import TimerProgressBar from "../TimerProgressBar"
import { Typography } from "@mui/material"
import PropTypes from "prop-types";

const InProgressDisplay = ({ timerProps }) => {
    if (timerProps?.progress === "in-progress") {
        return (
            <TimerProgressBar totalTime={timerProps?.totalTime} />
        )
    }
    return <Typography variant="h6" style={{ color: "#00704A", fontWeight: 700 }}>In progress...</Typography>
}

InProgressDisplay.propTypes = {
    timerProps: PropTypes.instanceOf(Object)
}

export default InProgressDisplay