import React, { useContext } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material'
import { ReactComponent as DownArrow } from '../../svg/cardIcons/down-arrow.svg'
import { ReactComponent as UpArrow } from '../../svg/cardIcons/up-arrow.svg'
import PropTypes from 'prop-types'
import Experience2Card from './Experience2Card'
import Recipe2Card from './Recipe2Card'
import RecipeCard2 from './RecipeCard2'
import { useHistory } from 'react-router-dom'
import makeStyles from "@mui/styles/makeStyles";
import { ErrorManagementContext } from '../../utils/errorManagement/ErrorManagementProvider'
import EmptyCards from './EmptyCards'
import { getUnLockedRecipes, getUserTag, recipeLevelOrder } from '../../utils/levels/levelsUtils'
import useRecipeListFromGQL from '../../utils/RecipeBackEnd/loadRecipeListGQL'
import { useUserSpecifcRecipe } from './RecipeList'


const useStyles = makeStyles(() => ({
    root: {
        "& .MuiAccordionSummary-content": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "2px",
        },
    }
}))

const AccordionForExperiment = ({ title, children, recipeLocked, expanded = false, onChange }) => {
    const classes = useStyles()
    return (
        <Accordion
            style={{ margin: "2vh 0" }}
            defaultExpanded={expanded}
            expanded={expanded} onChange={onChange}
            sx={recipeLocked && { background: "#CECECE", borderRadius: "10px" }}
            TransitionProps={{ unmountOnExit: true }}
        >
            <AccordionSummary className={classes.root} sx={recipeLocked && { background: "#CECECE", borderRadius: "10px" }}>
                <Typography
                    variant="h5"
                    style={{ fontWeight: 600 }}>
                    {title}
                </Typography>
                {expanded ? <UpArrow /> : <DownArrow />}
            </AccordionSummary>
            <AccordionDetails
                style={{ display: "flex", justifyContent: "center" }}>
                {children}
            </AccordionDetails>
        </Accordion>
    )
}

const RecipeListByExperiance = ({ recipeList, title, experiance, accordionLevel, manualMode = false, expanded = false, displayType, onChange, loading }) => {
    const { recipeList: activeRecipes } = useRecipeListFromGQL()
    const {
        recipes: userSpecificRecipes,
        loading: userRecipesLoading,
        userTags } = useUserSpecifcRecipe()
    const userRecipes = activeRecipes.filter(r => userSpecificRecipes.includes(r.id))
    const userLevel = getUserTag("level", userTags)
    const { cookClubLog } = useContext(ErrorManagementContext)
    const history = useHistory()
    const unLockedRecipes = getUnLockedRecipes(userTags, activeRecipes, userRecipes)
    const onCardClickExp1 = recipeId => displayType === "INGREDIENTS_ONLY" ? history.push("/ingredients/" + recipeId) :
        history.push("/test/" + recipeId)
    const handleUserLevelRecipes = (recipeId) => {
        if (unLockedRecipes.find(r => r.id === recipeId) || ["any", undefined].includes(JSON.parse(localStorage.getItem("globalSettings"))?.user)) {
            const currPath = displayType === "INGREDIENTS_ONLY" ? history.push("/ingredients/" + recipeId) : `experience2/${recipeId}`
            history.push(currPath)
        } else {
            cookClubLog("This Recipe is yet to be unlocked", "RECIPE LOCKED", { recipeId }, { ui: { type: "error" } })
        }
    }

    if (loading || userRecipesLoading)
        return (
            <AccordionForExperiment title={title} expanded={expanded} onChange={onChange} >
                <Box
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        flexWrap: "wrap"
                    }}
                >
                    <EmptyCards />
                </Box>
            </AccordionForExperiment>)

    if (recipeList && recipeList.length > 0)
        return (
            <AccordionForExperiment title={title} expanded={expanded} onChange={onChange} recipeLocked={recipeLevelOrder[userLevel] < accordionLevel} >
                <Grid container spacing={3}>
                    {recipeList.map((recipe, i) => (
                        <Grid xs={4} item key={recipe?.id || i}>
                            {experiance === "1.0" ?
                                <Recipe2Card recipeJson={recipe} onCardClick={() => onCardClickExp1(recipe.id)} /> :
                                <Experience2Card
                                    onCardClick={() => handleUserLevelRecipes(recipe.id)}
                                    recipeLocked={!unLockedRecipes.find(r => r.id === recipe?.id) && !["any", undefined].includes(JSON.parse(localStorage.getItem("globalSettings"))?.user)}
                                    url={recipe.metadata.url}
                                    name={recipe.metadata.name}
                                    categories={recipe.metadata.categories}
                                    tags={recipe.metadata.tags}
                                    minServing={recipe.metadata?.minServing}
                                    maxServing={recipe.metadata?.maxServing}
                                    totalTime={Number(recipe.metadata.prepTime) + Number(recipe.metadata.cookTime)}
                                />
                            }
                        </Grid>
                    ))}
                    {manualMode &&
                        <Grid xs={6} item>
                            <RecipeCard2
                                time={30}
                                recipeImage={"https://res.cloudinary.com/prodjour/image/upload/v1633257170/General/ManualMode_xi2mho.jpg"}
                                name="Manual Operation"
                                onClick={() => history.push(experiance === "1.0" ? "/drivehardware/" : "/experience2/drivehardware/")}
                            />
                        </Grid>
                    }
                </Grid>
            </AccordionForExperiment>
        )
    return null
}

RecipeListByExperiance.propTypes = {
    recipeList: PropTypes.arrayOf(PropTypes.object).isRequired,
    title: PropTypes.string.isRequired,
    manualMode: PropTypes.bool,
    expanded: PropTypes.bool,
    experiance: PropTypes.string,
    displayType: PropTypes.string.isRequired
}

export default RecipeListByExperiance