import React from "react"
import { Input } from "@mui/material"
import PropTypes from "prop-types"

const SearchBar = ({ search, setSearch }) => {
    return (<Input onChange={e => setSearch(e.target.value)} value={search} placeholder="Search" />)
}

SearchBar.propTypes = {
    search: PropTypes.string,
    setSEarch: PropTypes.func
}

export default SearchBar