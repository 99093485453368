import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import Freshchat from "react-freshchat"
import { GlobalSettingsContext } from '../globalSettings/globalSettingsContext'


const FreshChatReact = ({ recipeInfo }) => {
    const { globalSettings: { device, user } } = useContext(GlobalSettingsContext)
    const [freshchatWidget, setFreshChatWidget] = useState(null)
    return (
        <Box sx={{ position: "fixed", bottom: "0%", left: "0%" }} >
            <Freshchat
                token="b7399f5b-f303-412e-89bf-1f98dcef1df5"
                onInit={widget => {
                    /* Use `widget` instead of `window.fcWidget` */
                    setFreshChatWidget(widget)
                    widget.user.setProperties({
                        email: user,
                        first_name: user,
                        last_name: device,
                        phone: 48795347,
                        sessionID: "some random shit",
                        recipeName: recipeInfo?.metadata?.name
                    })
                }}
            />
        </Box>
    )
}

FreshChatReact.propTypes = {
    recipeInfo: PropTypes.object.isRequired
}

export default FreshChatReact