import { Typography, Grid } from '@mui/material'
import React from 'react'

const OfflinePage = () => {
  return (
    <Grid container direction="column" justifyContent={"center"} alignItems={"center"} width={"100%"}>
      <Grid item xs={8}>
        <img src="/img/offlineImage.png" alt="offline " />
      </Grid>
      <Grid item xs={1}><Typography variant="h3">Whoops!</Typography></Grid>
      <Grid item ><Typography variant="body2">Looks like your network is down.</Typography></Grid>
      <Grid item ><Typography variant="body2">Connect to wifi and try again.</Typography></Grid>
    </Grid>
  )
}

export default OfflinePage