import { CircularProgress, Container, Box } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { startUpSoundUrl, generalTapUrl } from '../audioUrls/audioUrl'
import SecureArea from '../components/authentication/SecureArea'
import HardwareBar from '../components/hardwareBar/HardwareBar'
import Categories from '../components/recipeList/Categories'
import Navbar from '../components/recipeList/Navbar'
import ToolBars from '../components/recipeList/ToolBars'
import RecipeList from '../components/recipeSelect/RecipeList'
import useCookClubAudio from '../utils/audio/cookClubAudio'
import { FilterProvider } from '../utils/homepage/FilterContext'
import "../css/freshChatButton.css"
import useRecipeListFromGQL from '../utils/RecipeBackEnd/loadRecipeListGQL'
import { GlobalSettingsContext } from '../utils/globalSettings/globalSettingsContext';



const RecipeListing = ({ ingredientsOnly = false }) => {
    // const recipeCollection = process.env.REACT_APP_VERCEL_DEPLOY_MODE === "PREVIEW" ? "recipeStaging" : collectionName
    const { globalSettings } = useContext(GlobalSettingsContext)
    const { recipeList, loading } = useRecipeListFromGQL() // 2.0
    const { recipeList: recipeListExperimental } = useRecipeListFromGQL(false)
    const [displayRecipeList, setDisplayRecipeList] = useState(recipeList)
    const [background, setBackground] = useState(true)
    const [startUpSound] = useCookClubAudio(startUpSoundUrl)
    const [generalTapSound] = useCookClubAudio(generalTapUrl)

    const onFilterChange = (filteredRecipe) => {
        setDisplayRecipeList(filteredRecipe)
    }

    useEffect(() => {
        const exp2ActiveRecipes = recipeList.filter(({ metadata }) => metadata?.active)
        setDisplayRecipeList(exp2ActiveRecipes)
    }, [recipeList])

    useEffect(() => {
        if (globalSettings.userName && globalSettings.userName !== localStorage.getItem('username')) {
            localStorage.setItem('username', globalSettings.userName)
            window.location.reload()
        }
    }, [globalSettings.userName])

    useEffect(() => {
        if (!loading) {
            startUpSound()
        }
    }, [loading])

    if (loading) <CircularProgress />

    // Categories and Toolbars will not work on reactivation
    return (
        <SecureArea signInMessage={ingredientsOnly ? "One place to get all ingredients for the recipes you like." : undefined} landscapeWarning={ingredientsOnly ? false : undefined}>
            <Box style={{ display: "flex", flexDirection: "row" }}>
                <HardwareBar homescreen />
                <Container style={{ height: background ? "max-content" : "100vh", width: "91vw", marginRight: 0, padding: 0, backgroundColor: "#E8E8E8" }} onClick={() => generalTapSound()}>
                    <FilterProvider>
                        <Navbar onFilterChange={onFilterChange} />
                        {false && <>
                            <Categories filterRecipeList={onFilterChange} />
                            <ToolBars filterRecipeList={onFilterChange} />
                        </>}
                        <RecipeList
                            experimentalRecipes2={recipeListExperimental}
                            loading={loading}
                            experience2Recipe={displayRecipeList}
                            setBackground={setBackground}
                            displayType={ingredientsOnly ? "INGREDIENTS_ONLY" : "LINK_TO_RECIPE"}
                        />
                        {/* <TabBars onFilterChange={onFilterChange} /> */}
                    </FilterProvider>
                </Container>
            </Box>
        </SecureArea>
    )
}

RecipeListing.propTypes = {
    ingredientsOnly: PropTypes.bool
}

export default RecipeListing
