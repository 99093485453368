import { Box, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const StepsDescription = ({ title, details, button = null, status, cap=false }) => {
    const color = (cap && ["in-progress", "completed"].includes(status)) || status === "completed" ? "#B5D1C5" : "#ffffff"

    return (<Box
        style={{
            backgroundColor: color,
            borderRadius: 10,
            padding: 10,
            display: "flex",
            boxSizing: "border-box",
            flexDirection: "column",
            gap: 10
        }}

    >
        {title ? <Box display="flex" sx={{ backgroundColor: color }} alignItems="center">
            <Typography variant="h5">{title}</Typography>
        </Box> : null}
        <Box sx={{ backgroundColor: color }}>
            {details}
            {button}
        </Box>
    </Box>
    )
}

StepsDescription.propTypes = {
    description: PropTypes.object,
    title: PropTypes.string
}

export default StepsDescription