import React from "react"
import { Button } from "@mui/material"

const AddBtn = ({ onClick = () => { } }) => {
    return (
        <Button onClick={onClick}>
            Add
        </Button>
    )
}

export default AddBtn